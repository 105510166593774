<template>
    <div>
        <md-button @click="startShopifyAppInstallation" class="md-primary">Install app</md-button>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'MainAppsShopifyConnectButton',

    data() {
        return {
            myShopifyDomain: null,
        };
    },

    methods: {
        ...mapActions('modalStore', ['openModal']),

        startShopifyAppInstallation() {
            const redirectUrl = `${process.env.VUE_APP_SHOPIFY_FUNCTIONS_URL}/api/shopify`;
            const clientId = process.env.VUE_APP_SHOPIFY_CLIENT_ID;
            const scopes = [
                'read_customer_events',
                'read_customers',
                'read_discounts',
                'read_draft_orders',
                'read_fulfillments',
                'read_gdpr_data_request',
                'read_inventory',
                'read_legal_policies',
                'read_locations',
                'read_marketing_events',
                'read_merchant_managed_fulfillment_orders',
                'read_metaobject_definitions',
                'read_metaobjects',
                'read_online_store_navigation',
                'read_online_store_pages',
                'read_order_edits',
                'read_orders',
                'read_payment_customizations',
                'read_payment_terms',
                'read_price_rules',
                'read_product_feeds',
                'read_product_listings',
                'read_products',
                'read_purchase_options',
                'read_returns',
                'read_shipping',
                'read_locales',
                'read_content',
                'read_third_party_fulfillment_orders',
                'read_custom_fulfillment_services',
                'read_customer_merge',
                'read_delivery_customizations',
                'read_fulfillment_constraint_rules',
                'read_themes',
                'write_themes',
                'write_customers',
                'write_fulfillments',
                'write_order_edits',
                'write_orders',
                'write_draft_orders',
            ];
            const scopesQuery = scopes.join('%2C');
            const installShopifyAppUrl = `https://admin.shopify.com/oauth/authorize?client_id=${clientId}&scope=${scopesQuery}&redirect_uri=${redirectUrl}`;

            window.location.href = installShopifyAppUrl;
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
    mounted() {},
};
</script>

<style scoped>
/* Add any additional styles here */
</style>
