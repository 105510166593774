<template>
    <div>
        <AuthLayout>
            <Register />
        </AuthLayout>
    </div>
</template>
<script>
import AuthLayout from '../../layouts/LoginOrSignupLayout.vue';
import Register from '../../components/Auth/Register/Register.vue';

export default {
    name: 'register',
    components: { AuthLayout, Register },
    data() {
        return {
            firstname: null,
            email: null,
            password: null,
        };
    },
};
</script>
