<template>
    <div
        class="sidebar"
        data-color="white"
        :data-image="backgroundImage"
        :data-background-color="backgroundColor"
        :style="sidebarStyle"
    >
        <div class="logo">
            <a href="https://www.lixsa.ai/" class="simple-text logo-mini" target="_blank">
                <div class="logo-img">
                    <!--          <img :src="logo" />-->
                    <img src="../../assets/icons/LixsaLogo-new.svg" alt="Lixsa logo" />
                </div>
            </a>
            <a href="https://www.lixsa.ai/" class="simple-text logo-normal" target="_blank">
                <template v-if="$route.meta.rtlActive">{{ rtlTitle }}</template>
                <template class="md-body-2" v-else>{{ title }}</template>
            </a>
            <div class="navbar-minimize">
                <md-button id="minimizeSidebar" class="md-round md-just-icon md-transparent" @click="minimizeSidebar">
                    <i class="material-symbols-outlined text_align-center visible-on-sidebar-regular"
                        >keyboard_tab_rtl</i
                    >
                    <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">keyboard_tab</i>
                </md-button>
            </div>
        </div>
        <div class="sidebar-wrapper" ref="sidebarScrollArea">
            <slot name="userMenu"></slot>
            <md-list class="nav">
                <slot name="links">
                    <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
                        <sidebar-item
                            v-for="(subLink, index) in link.children"
                            :key="subLink.name + index"
                            :link="subLink"
                        >
                        </sidebar-item>
                    </sidebar-item>
                </slot>
            </md-list>

            <!-- Bottom Fixed item -->
            <md-list class="nav sidebar-fixed-item">
                <slot name="fixedBottom">
                    <sidebar-item :key="link.name + index" :link="link"></sidebar-item>
                </slot>
            </md-list>
        </div>
    </div>
</template>
<script>
export default {
    name: 'sidebar',
    props: {
        title: {
            type: String,
            default: 'Lixsa',
        },
        rtlTitle: {
            type: String,
            default: 'توقيت الإبداعية',
        },
        activeColor: {
            type: String,
            default: 'default',
            validator: (value) => {
                let acceptedValues = ['', 'purple', 'azure', 'green', 'orange', 'danger', 'rose', 'default'];
                return acceptedValues.indexOf(value) !== -1;
            },
        },
        backgroundImage: {
            type: String,
            default: null,
        },
        backgroundColor: {
            type: String,
            default: 'black',
            validator: (value) => {
                let acceptedValues = ['', 'black', 'white', 'red'];
                return acceptedValues.indexOf(value) !== -1;
            },
        },
        logo: {
            type: String,
            default: '../../assets/icons/LixaLogo-black.png',
        },
        sidebarLinks: {
            type: Array,
            default: () => [],
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
    },
    provide() {
        return {
            autoClose: this.autoClose,
        };
    },
    methods: {
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
    },
    computed: {
        sidebarStyle() {
            return {
                backgroundImage: `url(${this.backgroundImage})`,
            };
        },
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    },
    mounted() {
        this.minimizeSidebar();
    },
};
</script>
<style lang="scss" scoped>
@media (min-width: 992px) {
    .navbar-search-form-mobile,
    .nav-mobile-menu {
        display: none;
    }
}

.sidebar {
    // position: relative; /* Ensures proper positioning context */

    .sidebar-fixed-item {
        position: absolute;
        bottom: 30px;
        width: 100%;
        // padding: 10px; /* Adjust padding as needed */
        // background: #fff; /* Optional: for visibility */
        // border-top: 1px solid #eee; /* Optional: for a separator */
    }
}
</style>
