const transformDate = (timestamp) => {
  /**
   * TODO: adecuar lo funcion para que muestre la fecha o los dias de la semana
   * o si fue ayer
   */
  const date = new Date(timestamp * 1000);

  // Obtener las horas (en formato de 24 horas)
  const hours = date.getHours();

  // Obtener los minutos
  const minutes = date.getMinutes();

  // Convertir las horas a formato de 12 horas
  const formattedHours = hours % 12 || 12;

  // Determinar si es AM o PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Formatear los minutos para que tengan dos dígitos (por ejemplo, 05 en lugar de 5)
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // Crear el string en formato "hh:mm AM/PM"
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

  // Imprimir el resultado
  return formattedTime;
};

const transformDateLong = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const weekday = date.toLocaleString("default", { weekday: "short" });
  const formattedDate = `${weekday} ${month} ${day} ${year}`;

  return formattedDate;
};

const formatDateTime = (unixTimestamp) => {
  const date = new Date(unixTimestamp * 1000); // Convertimos el timestamp a milisegundos
  const currentDate = new Date();

  // Si la fecha es del mismo día
  if (date.toDateString() === currentDate.toDateString()) {
    return `today: ${formatTime(date)}`;
  }

  // Si la fecha es de un día pasado de la semana en curso
  if (
    date >
    new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 7
    )
  ) {
    const dayOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return `${dayOfWeek[date.getDay()]}: ${formatTime(date)}`;
  }

  // Si la fecha es de una fecha posterior
  return `${formatDate(date)}: ${formatTime(date)}`;
};

const formatDate = (date) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

const formatTime = (date) => {
  const options = { hour: "numeric", minute: "numeric", hour12: true };
  return date.toLocaleTimeString(undefined, options);
};

const capitalizeString = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str2 = arr.join(" ");

  return str2;
};

const getCurrencySymbol = (currencyCode) => {
  const currencySymbols = {
    USD: "$",
    EUR: "€",
    GBP: "£",
    JPY: "¥",
    // Agrega más pares de moneda y símbolo si es necesario
  };

  const symbol = currencySymbols[currencyCode];
  return symbol || currencyCode;
};

const formatNumberWithDecimals = (number) => {
  return number.toLocaleString("es", {
    minimumFractionDigits: 2, // Mínimo número de decimales
    maximumFractionDigits: 2, // Máximo número de decimales
  });
};

const getBadgeClass = (status) => {
  switch (status) {
    case "pending":
      return "badge-pending";
    case "refunded":
      return "badge-refunded";
    case "paid":
      return "badge-paid";
    case "voided":
      return "badge-voided";
    case "unshipped":
      return "badge-unshipped";
    case "fulfilled":
      return "badge-fulfilled";

    default:
      return "";
  }
};

export {
  transformDate,
  transformDateLong,
  formatDateTime,
  capitalizeString,
  getCurrencySymbol,
  formatNumberWithDecimals,
  getBadgeClass,
};
