<script>
export default {
    name: 'TheImageLoader',
    emits: ['images-added'],
    data() {
        return {
            images: [], // Will hold the File objects
            imagePreviews: [], // Will hold the data URLs for previews
        };
    },
    watch: {
        images(newValue) {
            this.$emit('images-added', newValue);
        },
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },

        handleImages(event) {
            // Use <input type="file"> to select images and then read and add them to the images array
            const files = Array.from(event.target.files);
            files.forEach((file) => {
                if (!file.type.startsWith('image/')) {
                    return;
                } // Skip non-image files

                const reader = new FileReader();
                reader.onload = (e) => {
                    this.images.push(file); // Add the file object to the images array
                    this.imagePreviews.push(e.target.result); // Add the data URL to the imagePreviews array
                };
                reader.readAsDataURL(file);
            });
        },

        deleteImage(index) {
            this.images.splice(index, 1); // Remove the file object from the images array
            this.imagePreviews.splice(index, 1); // Remove the data URL from the imagePreviews array
        },
    },
};
</script>

<template>
    <div class="image-loader">
        <div class="image-loader__upload" @click="triggerFileInput">
            <input
                type="file"
                ref="fileInput"
                @change="handleImages"
                class="image-loader__input"
                accept="image/*"
                multiple
            />
            <div class="image-loader__upload-icon">
                <md-icon>add</md-icon>
            </div>
        </div>
        <div class="image-loader__preview-container">
            <div
                v-for="(imagePreview, index) in imagePreviews"
                :key="index"
                class="image-loader__preview"
                :style="{ backgroundImage: 'url(' + imagePreview + ')' }"
            >
                <div class="image-loader__delete-icon" @click="deleteImage(index)">
                    <md-icon>cancel</md-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.image-loader {
    display: flex;
    overflow-x: auto;
}

.image-loader__upload {
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    min-width: 100px;
    min-height: 100px;

    border: 2px dashed #ccc;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
}

.image-loader__upload-icon {
    font-size: 24px; /* Adjust as needed */
}

.image-loader__preview-container {
    display: flex;
}

.image-loader__preview {
    width: 100px; /* Adjust as needed */
    height: 100px; /* Adjust as needed */
    border-radius: 12px;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-right: 10px;
}

.image-loader__delete-icon {
    position: absolute;
    top: -4px;
    right: -4px;
    padding: 5px;
    cursor: pointer;
}

.image-loader__input {
    display: none;
}
</style>
