// countryCodes.js
export const countryCodes = {
    '+34': 'Spain',
    '+1': 'United States',
    '+44': 'United Kingdom',
    '+91': 'India',
    '+81': 'Japan',
    '+61': 'Australia',
    '+55': 'Brazil',
    '+86': 'China',
    '+7': 'Russia',
    '+54': 'Argentina',
    '+591': 'Bolivia',
    '+56': 'Chile',
    '+57': 'Colombia',
    '+593': 'Ecuador',
    '+592': 'Guyana',
    '+595': 'Paraguay',
    '+51': 'Peru',
    '+597': 'Suriname',
    '+598': 'Uruguay',
    '+58': 'Venezuela',
    '+43': 'Austria',
    '+32': 'Belgium',
    '+359': 'Bulgaria',
    '+357': 'Cyprus',
    '+420': 'Czech Republic',
    '+45': 'Denmark',
    '+372': 'Estonia',
    '+358': 'Finland',
    '+33': 'France',
    '+49': 'Germany',
    '+30': 'Greece',
    '+36': 'Hungary',
    '+353': 'Ireland',
    '+39': 'Italy',
    '+371': 'Latvia',
    '+370': 'Lithuania',
    '+352': 'Luxembourg',
    '+356': 'Malta',
    '+31': 'Netherlands',
    '+48': 'Poland',
    '+351': 'Portugal',
    '+40': 'Romania',
    '+421': 'Slovakia',
    '+386': 'Slovenia',
    '+46': 'Sweden',
    '+65': 'Singapore',
    '+972': 'Israel',
};
