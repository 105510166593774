<template>
    <md-card class="shopify-sandbox-cta">
        <md-card-content>
            <div class="md-title"><strong>Try Lixsa in your Sandbox</strong></div>
            <p class="md-body-1">
                Test Lixsa's Shopify integration in a safe chat environment. Use our sandbox to verify if Lixsa has
                successfully obtained all the information from your Shopify store.
            </p>
            <md-button class="md-raised md-primary" @click="openSandbox">Open Sandbox</md-button>
        </md-card-content>
    </md-card>
</template>

<script>
export default {
    name: 'MainAppsShopifySandboxCta',
    methods: {
        openSandbox() {
            this.$router.push('/training/chat');
        },
    },
};
</script>

<style scoped>
.shopify-sandbox-cta {
    background-color: #f5f5f5;
    margin-top: 20px;
}
</style>
