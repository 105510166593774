<script>
import TheLinkExpired from '@/components/TheLinkExpired.vue';

export default {
    name: 'LinkExpired',
    components: {
        TheLinkExpired,
    },
};
</script>

<template>
    <div>
        <TheLinkExpired></TheLinkExpired>
    </div>
</template>

<style scoped lang="scss"></style>
