<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-close-on-esc="false" :md-click-outside-to-close="false">
            <md-dialog-title class="md-headline">
                Edit Training Scenario
                <span>
                    <md-icon>help_outline</md-icon>
                    <md-tooltip md-direction="bottom">
                        <p class="md-body-1">AI Training Scenario</p>
                        <p class="md-caption">
                            Help improve Lixsa accuracy by detailing where it goes wrong and what the right response
                            should be.
                        </p>
                        <p class="md-caption">
                            - <strong>Context:</strong> Provide the scenario or background relevant to the Lixsa's
                            response.
                        </p>
                        <p class="md-caption">
                            - <strong>Wrong Answer:</strong> Enter the incorrect or inappropriate response given by
                            Lixsa.
                        </p>
                        <p class="md-caption">
                            - <strong>Correct Answer:</strong> Provide the accurate or more suitable answer for the
                            given context.
                        </p>
                    </md-tooltip>
                </span>
            </md-dialog-title>
            <div class="modal-content">
                <p class="md-subheading">Context</p>
                <md-field>
                    <md-textarea type="text" v-model="localScenario.context" required></md-textarea>
                </md-field>

                <p class="md-subheading">Wrong Answer</p>
                <md-field>
                    <md-textarea type="text" v-model="localScenario.wrong_answer" required></md-textarea>
                </md-field>

                <p class="md-subheading">Correct Answer</p>
                <md-field>
                    <md-textarea type="text" v-model="localScenario.good_answer" required></md-textarea>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="editScenario" :disabled="!canSave">
                    <span v-if="!isLoading">Update</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-button class="md-just-icon md-info md-simple" @click="showDialog = true">
            <md-icon>edit</md-icon>
        </md-button>
    </div>
</template>

<script>
import fineTuningService from '@/services/fineTuning/fineTuningService.js';

export default {
    name: 'EditScenarioWizard',
    props: {
        scenario: {
            type: Object,
            default: () => ({}),
        },
        // context: {
        //   type: String,
        //   default: '',
        // },
        // correctAnswer: {
        //   type: String,
        //   default: '',
        // },
        // wrongAnswer: {
        //   type: String,
        //   default: '',
        // },
    },
    watch: {
        scenario: {
            deep: true,
            handler(newValue) {
                this.localScenario = JSON.parse(JSON.stringify(newValue));
            },
        },

        // context: {
        //   deep: true,
        //   handler(newValue) {
        //     this.localContext = newValue;
        //   }
        // },
        // correctAnswer: {
        //   deep: true,
        //   handler(newValue) {
        //     this.localCorrectAnswer = newValue;
        //   }
        // },
        // wrongAnswer: {
        //   deep: true,
        //   handler(newValue) {
        //     this.localWrongAnswer = newValue;
        //   }
        // }
    },
    data: () => ({
        localScenario: {},

        // localContext: '',
        // localCorrectAnswer: '',
        // localWrongAnswer: '',

        isLoading: false,
        showDialog: false,
    }),
    computed: {
        isEdited() {
            return JSON.stringify(this.localScenario) !== JSON.stringify(this.scenario);
        },
        canSave() {
            // Check if either file is not null or url is not empty AND name is not empty
            return this.isEdited && this.localScenario.context && this.localScenario.good_answer;
        },
    },
    methods: {
        handleClose() {
            this.localScenario = JSON.parse(JSON.stringify(this.scenario));
            this.isLoading = false;
            this.showDialog = false;
        },

        async editScenario() {
            this.isLoading = true;
            try {
                const editData = {
                    fine_tuning_id: this.localScenario.uuid,
                    account_id: this.localScenario.account_id,
                    context: this.localScenario.context,
                    wrong_answer: this.localScenario.wrong_answer,
                    good_answer: this.localScenario.good_answer,
                };
                const response = await fineTuningService.updateFineTuningScenario(this.localScenario.uuid, editData);

                this.isLoading = false;
                this.handleClose();
                this.notifyParent();
                this.showSuccessToast('Scenario updated');
            } catch (error) {
                console.error('Error updating scenario: ', error);
                this.showErrorToast('Error updating scenario');
            } finally {
                this.isLoading = false;
            }
        },

        notifyParent() {
            this.$emit('dialogClosed');
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
    created() {
        this.localScenario = JSON.parse(JSON.stringify(this.scenario));
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-dialog .md-dialog-container {
    max-width: 900px;
}

.custom-button {
    color: $white-color !important;

    .custom-button-icon {
        color: $white-color !important;
    }
}

.modal-content {
    background: #fff;
    padding: 0px 30px 60px 30px;
    text-align: center;
    /* max-width: 500px; */
    margin: 0 auto;
    width: 800px;

    @media (max-width: 959px) {
        width: auto;
        padding: 0px;
        margin: 0px 15px;
    }
}

.modal-content p {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}

.md-tooltip {
    border-radius: 12px;
    text-align: left;
}
</style>
