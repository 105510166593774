<template>
    <div>
        <div v-if="step === 1" class="form-step">
            <form @submit.prevent="submitStep1">
                <!-- Fields for Step 1 -->
                <md-field>
                    <label>Shop domain</label>
                    <md-input v-model="shopForm.shop_domain"></md-input>
                </md-field>
                <md-field>
                    <label>Access token</label>
                    <md-input v-model="shopForm.shop_access_token"></md-input>
                </md-field>
                <md-button type="submit" class="md-raised md-primary">Continue</md-button>
            </form>
        </div>

        <div v-if="step === 2" class="form-step">
            <form @submit.prevent="submitStep2">
                <!-- Fields for Step 2 -->
                <md-field>
                    <label>Field 2.1</label>
                    <md-input v-model="form.field2"></md-input>
                </md-field>
                <md-button type="submit" class="md-raised md-primary">Continue</md-button>
            </form>
        </div>

        <div v-if="step === 3" class="form-step">
            <form @submit.prevent="submitStep3">
                <!-- Fields for Step 3 -->
                <md-field>
                    <label>Field 3.1</label>
                    <md-input v-model="form.field3"></md-input>
                </md-field>
                <md-button type="submit" class="md-raised md-primary">Submit</md-button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'OnboardingForm',
    data() {
        return {
            step: 1,
            shopForm: {
                shop_domain: null,
                shop_access_token: null,
            },
            form: {
                field1: '',
                field2: '',
                field3: '', // add a new field for the third step
            },
        };
    },
    methods: {
        ...mapActions('user', ['actionUpdateEcommerceSettings']),
        async submitStep1() {
            try {
                const responseStatus = await this.actionUpdateEcommerceSettings(this.shopForm);
                if (responseStatus === 200 || responseStatus === 201) {
                    //this.loading = false;
                    this.step += 1;
                }
            } catch (error) {
                // Handle errors such as showing error messages to the user
                console.error('Error in submitting step 1', error);
            }
        },
        async submitStep2() {
            try {
                // Submit data for Step 2
                // await this.$axios.post('/api/submit-step2', { field2: this.form.field2 });

                // Go to next step upon successful submission
                this.step += 1;
            } catch (error) {
                console.error('Error in submitting step 2', error);
            }
        },
        async submitStep3() {
            try {
                // Submit data for Step 3
                // await this.$axios.post('/api/submit-step3', { field3: this.form.field3 });
                // Here you might want to redirect the user or show a confirmation message
            } catch (error) {
                console.error('Error in submitting step 3', error);
            }
        },
    },
};
</script>

<style>
.form-step {
    width: 40vw;
}
</style>
