<template>
    <div class="vac-room-header vac-app-border-b">
        <slot name="room-header">
            <div class="vac-room-wrapper">
                <transition name="vac-slide-up">
                    <div v-if="messageSelectionEnabled" class="vac-room-selection">
                        <div v-for="action in messageSelectionActions" :id="action.name" :key="action.name">
                            <div class="vac-selection-button" @click="messageSelectionActionHandler(action)">
                                {{ action.title }}
                                <span class="vac-selection-button-count">
                                    {{ selectedMessagesTotal }}
                                </span>
                            </div>
                        </div>
                        <div class="vac-selection-cancel vac-item-clickable" @click="$emit('cancel-message-selection')">
                            {{ textMessages.CANCEL_SELECT_MESSAGE }}
                        </div>
                    </div>
                </transition>
                <template v-if="!messageSelectionEnabled && messageSelectionAnimationEnded">
                    <div
                        v-if="!singleRoom"
                        class="vac-svg-button vac-toggle-button"
                        :class="{
                            'vac-rotate-icon-init': !isMobile,
                            'vac-rotate-icon': !showRoomsList && !isMobile,
                        }"
                        @click="$emit('toggle-rooms-list')"
                    >
                        <slot name="toggle-icon">
                            <svg-icon name="toggle" />
                        </slot>
                    </div>
                    <div
                        class="vac-info-wrapper"
                        :class="{ 'vac-item-clickable': enableInfoCustomer }"
                        @click="$emit('customer-info')"
                    >
                        <slot name="room-header-avatar">
                            <div
                                v-if="room.avatar"
                                class="vac-avatar"
                                :style="{ 'background-image': `url('${room.avatar}')` }"
                            />
                        </slot>
                        <slot name="room-header-info">
                            <div class="vac-text-ellipsis">
                                <div class="vac-room-name vac-text-ellipsis">
                                    {{ room.roomName }}
                                </div>
                                <div v-if="typingUsers" class="vac-room-info vac-text-ellipsis">
                                    {{ typingUsers }}
                                </div>
                                <div v-else class="vac-room-info vac-text-ellipsis">
                                    {{ userStatus }}
                                </div>
                            </div>
                        </slot>
                    </div>
                    <div v-if="!singleRoom">
                        <md-button @click="copyLink" class="md-icon-button">
                            <svg-icon name="link" />
                            <md-tooltip md-direction="left">Copy Link</md-tooltip>
                        </md-button>
                    </div>
                    <slot v-if="room.roomId" name="room-options">
                        <md-menu v-if="menuActions.length" :mdCloseOnClick="true" md-size="medium" md-align-trigger>
                            <div md-menu-trigger class="vac-svg-button vac-room-options">
                                <slot name="menu-icon">
                                    <svg-icon name="menu" />
                                </slot>
                            </div>
                            <md-menu-content>
                                <ul class="menu-items">
                                    <li v-for="action in menuActions" :key="action.name" class="md-body-1">
                                        <svg-icon :name="action.icon" />
                                        {{ action.title }}
                                    </li>
                                </ul>
                            </md-menu-content>
                        </md-menu>
                    </slot>
                </template>
            </div>
        </slot>
    </div>
</template>

<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

import vClickOutside from '@/utils/on-click-outside';
import typingText from '@/utils/typing-text';

export default {
    name: 'RoomHeader',
    components: {
        SvgIcon,
    },

    directives: {
        clickOutside: vClickOutside,
    },

    props: {
        currentUserId: { type: [String, Number], required: true },
        textMessages: { type: Object, required: true },
        singleRoom: { type: Boolean, required: true },
        showRoomsList: { type: Boolean, required: true },
        isMobile: { type: Boolean, required: true },
        enableInfoCustomer: { type: Boolean, required: true },
        menuActions: { type: Array, required: true },
        room: { type: Object, required: true },
        messageSelectionEnabled: { type: Boolean, required: true },
        messageSelectionActions: { type: Array, required: true },
        selectedMessagesTotal: { type: Number, required: true },
    },

    emits: [
        'toggle-rooms-list',
        'menu-action-handler',
        'cancel-message-selection',
        'message-selection-action-handler',
        'customer-info',
        'copy-room-link',
    ],

    data() {
        return {
            menuOpened: false,
            messageSelectionAnimationEnded: true,
        };
    },

    computed: {
        typingUsers() {
            return typingText(this.room, this.currentUserId, this.textMessages);
        },
        userStatus() {
            if (!this.room.users || this.room.users.length !== 2) return;

            const user = this.room.users.find((u) => u._id !== this.currentUserId);

            if (!user?.status) return;

            let text = '';

            if (user.status.state === 'online') {
                text = this.textMessages.IS_ONLINE;
            } else if (user.status.lastChanged) {
                text = this.textMessages.LAST_SEEN + user.status.lastChanged;
            }

            return text;
        },
    },

    watch: {
        messageSelectionEnabled(val) {
            if (val) {
                this.messageSelectionAnimationEnded = false;
            } else {
                setTimeout(() => {
                    this.messageSelectionAnimationEnded = true;
                }, 300);
            }
        },
    },

    methods: {
        menuActionHandler(action) {
            this.closeMenu();
            this.$emit('menu-action-handler', action);
        },
        closeMenu() {
            this.menuOpened = false;
        },
        messageSelectionActionHandler(action) {
            this.$emit('message-selection-action-handler', action);
        },
        copyLink() {
            this.$emit('copy-room-link', this.room);
        },
    },
};
</script>
<style lang="scss" scoped>
.menu-items {
    list-style: none;
    padding: 0;
}

.menu-items li {
    display: flex;
    align-items: center;
    padding: 5px 0;
    padding-left: 20px;
    border-radius: 12px;
    cursor: pointer;

    svg {
        height: 15px;
        width: 15px;
        margin-right: 3px;
    }
}

.menu-items li:hover {
    background-color: #f5f5f5;
}
</style>
