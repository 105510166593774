<template>
    <div class="vbm">
        <div>
            <md-dialog
                class="vbm__dialog"
                :md-close-on-esc="false"
                :md-click-outside-to-close="false"
                :md-active.sync="showDialog"
            >
                <div v-if="this.currentStep === 1" ref="confettiContainer" class="vbm__confetti"></div>
                <md-dialog-content>
                    <div class="vbm--flex-container">
                        <!--          <div v-if="!isFirstOrLastStep" class="vbm__main-image"></div>-->
                        <div>
                            <div class="vbm__close-header">
                                <md-button @click="handleClose" class="md-icon-button"
                                    ><md-icon>close</md-icon></md-button
                                >
                            </div>

                            <!-- <div v-if="currentStep === 1" class="vbm__welcome">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FLogo_Lixsa%20_nbg_fade.png?alt=media&token=bd925812-1bb4-47e2-9a95-f862505b46f1"
                                    alt="lixsa logo"
                                />
                                <div class="md-display-1">
                                    <strong> Welcome to Lixsa </strong>
                                </div>
                                <div class="md-subheading">
                                    We're excited to have you on board. To get started,
                                    <strong>please select the type of business you're involved with </strong>. This will
                                    help us tailor our platform to meet your specific needs.
                                </div>
                                <div class="md-subheading">Looking forward to supporting your success!</div>
                                <md-button @click="goToNextStep" class="md-primary">Choose your business</md-button>
                            </div>
                         -->

                            <div v-if="currentStep === 1" class="vbm__choose-business">
                                <div class="md-display-1"><strong>Welcome to Lixsa!</strong></div>
                                <div class="md-body-2">
                                    Choose your business app. This enables us to customize our platform to suit your
                                    unique requirements.
                                </div>
                                <p class="md-title">E-commerce</p>
                                <div class="md-layout">
                                    <div
                                        @click="selectCard('shopify')"
                                        class="md-layout-item md-size-50 md-small-size-100 md-large-size-100 vbm--no-padding-left vbm--no-padding-right"
                                    >
                                        <verticalCard
                                            class="vbm--no-horizontal-margin vbm__vertical-card"
                                            :class="{
                                                'vbm__vertical-card--selected': selectedCard === 'shopify',
                                            }"
                                            miniature="shopify_image"
                                            title="Shopify"
                                            description="Shopify is an e-commerce platform enabling businesses to create online stores and sell products."
                                        />
                                    </div>
                                    <div
                                        class="md-layout-item md-size-50 md-small-size-100 md-large-size-100 vbm--no-padding-left vbm--no-padding-right"
                                    >
                                        <verticalCard
                                            class="vbm--no-horizontal-margin vbm__vertical-card vbm__vertical-card--disabled"
                                            :class="{
                                                'vbm__vertical-card--selected': selectedCard === 'woocomerce',
                                            }"
                                            miniature="woocomerce_image"
                                            title="Woocomerce"
                                            description="WooCommerce is a customizable, open-source eCommerce platform built on WordPress."
                                            :comingSoon="true"
                                        />
                                    </div>
                                </div>
                                <p class="md-title">Others</p>
                                <div class="md-layout">
                                    <div
                                        @click="selectCard('others')"
                                        class="md-layout-item md-size-50 md-small-size-100 md-large-size-100 vbm--no-padding-left vbm--no-padding-right"
                                    >
                                        <verticalCard
                                            class="vbm--no-horizontal-margin vbm__vertical-card"
                                            :class="{
                                                'vbm__vertical-card--selected': selectedCard === 'others',
                                            }"
                                            miniature="skill7"
                                            title="Other"
                                            description="My business operates in a sector other than e-commerce, focusing on a distinct industry."
                                        />
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="currentStep === 2" class="vbm__fill-form">
                                <div class="md-display-1">Let's do a quick setup</div>
                                <div class="md-body-2">
                                    To finish setting up your Shopify store, we need you to give us the domain of your
                                    store. Lixsa will take care of the rest. 😉
                                </div>

                                <md-field class="vbm__form-field">
                                    <label for="name">Your Shopify Domain</label>
                                    <span class="md-prefix md-body-1">{{ prefix }}</span>
                                    <md-input v-model="shopifyDomain"></md-input>
                                    <span class="md-suffix md-body-1">{{ suffix }}</span>
                                </md-field>
                            </div>
                            <div v-else-if="currentStep === 3" class="vbm__loading-data">
                                <div class="md-display-1">Learning about your business</div>
                                <div class="md-body-2">Please wait, this can take a few minutes... 😇</div>
                                <div class="vbm__ecommerce-training-card">
                                    <md-progress-spinner
                                        v-if="!ECOMMERCE_POLICIES_UPDATED"
                                        :md-diameter="16"
                                        :md-stroke="3"
                                        md-mode="indeterminate"
                                    ></md-progress-spinner>
                                    <md-icon v-else class="vbm__check-icon">check_circle_outline</md-icon>
                                    <div class="md-subheading">Reading the policies.</div>
                                </div>
                                <div class="vbm__ecommerce-training-card">
                                    <md-progress-spinner
                                        v-if="!ECOMMERCE_CATALOG_UPDATED"
                                        :md-diameter="15"
                                        :md-stroke="3"
                                        md-mode="indeterminate"
                                    ></md-progress-spinner>
                                    <md-icon v-else class="vbm__check-icon">check_circle_outline</md-icon>
                                    <div class="md-subheading">Memorizing the product catalog.</div>
                                </div>
                            </div>

                            <div v-else-if="currentStep === 4" class="vbm__finish">
                                <img
                                    src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Fparty_Face.png?alt=media&token=c7f3aa54-0937-4634-bd1c-67aaf2ad5598"
                                    alt="party_Face"
                                />
                                <p class="md-display-1">All Done!</p>
                                <p class="md-subheading">
                                    We have configured your business correctly. We recommend that you
                                    <router-link to="/training"> go through training </router-link>
                                    to make sure Lixsa has access to your business information. We also recommend that
                                    you finish adding all your business data in
                                    <router-link to="/settings/account"> the settings section. </router-link>
                                </p>
                                <p class="md-subheading">... and welcome to Lixsa!</p>
                                <md-button @click="handleClose" class="md-primary">Let's Start</md-button>
                            </div>
                        </div>
                    </div>
                </md-dialog-content>
                <md-dialog-actions>
                    <div v-if="!isFirstOrLastStep" class="vbm__footer">
                        <md-button v-if="this.currentStep > 2 && this.currentStep !== 3" @click="goToPreviousStep"
                            >previous</md-button
                        >
                        <!-- <md-icon>more_horiz</md-icon> -->
                        <md-button
                            v-if="currentStep !== 2 && currentStep !== 3"
                            :disabled="selectedCard === ''"
                            @click="goToNextStep"
                            class="md-primary"
                            >Next</md-button
                        >
                        <md-button
                            v-else-if="currentStep === 3"
                            :disabled="!businessSynced"
                            @click="goToNextStep"
                            class="md-primary"
                            >Finish</md-button
                        >
                        <md-button
                            v-else-if="!isLoading"
                            :disabled="!canSaveQuickSetupForm"
                            @click="updateEcommerceSettings"
                            class="md-primary"
                            >Save</md-button
                        >
                        <md-button v-else>
                            <md-progress-spinner
                                :md-diameter="20"
                                :md-stroke="3"
                                md-mode="indeterminate"
                            ></md-progress-spinner>
                        </md-button>
                    </div>
                </md-dialog-actions>
            </md-dialog>
        </div>
    </div>
</template>
<script>
import verticalCard from '@/components/Dashboard/verticalBusinessModal/verticalCard/verticalCard.vue';
import accountService from '@/services/account/accountService.js';
import { mapActions } from 'vuex';

export default {
    name: 'verticalBusinessModal',
    components: {
        verticalCard,
    },
    data() {
        return {
            showDialog: true,
            isSelectingBusinessType: false,
            currentStep: 1,
            selectedCard: '',
            isLoading: false,
            ECOMMERCE_POLICIES_UPDATED: false,
            ECOMMERCE_CATALOG_UPDATED: false,
            shopifyDomain: '',
            prefix: 'https://',
            suffix: '.myshopify.com',
        };
    },
    computed: {
        canSaveQuickSetupForm() {
            return this.shopifyDomain;
        },
        isFirstOrLastStep() {
            return this.currentStep === 4;
        },
        businessSynced() {
            return this.ECOMMERCE_CATALOG_UPDATED && this.ECOMMERCE_POLICIES_UPDATED;
        },
    },
    watch: {
        async currentStep(newVal) {
            if (newVal === 2) {
                await this.subscribeToChannel();
            }
        },
    },
    methods: {
        ...mapActions('user', ['actionGetAccount', 'actionUpdateAccount']),
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),

        async handleClose() {
            this.$confetti.stop();
            this.showDialog = false;
            if (this.currentStep > 2) {
                await this.actionGetAccount();
            }
        },

        selectCard(option) {
            this.selectedCard = option;
        },

        goToSelectBusinessType() {
            this.isSelectingBusinessType = true;
        },

        async updateEcommerceSettings() {
            const redirectUrl = `${process.env.VUE_APP_SHOPIFY_FUNCTIONS_URL}/api/shopify`;
            const clientId = process.env.VUE_APP_SHOPIFY_CLIENT_ID;
            const scopes = [
                'read_customer_events',
                'read_customers',
                'read_discounts',
                'read_draft_orders',
                'read_fulfillments',
                'read_gdpr_data_request',
                'read_inventory',
                'read_legal_policies',
                'read_locations',
                'read_marketing_events',
                'read_merchant_managed_fulfillment_orders',
                'read_metaobject_definitions',
                'read_metaobjects',
                'read_online_store_navigation',
                'read_online_store_pages',
                'read_order_edits',
                'read_orders',
                'read_payment_customizations',
                'read_payment_terms',
                'read_price_rules',
                'read_product_feeds',
                'read_product_listings',
                'read_products',
                'read_purchase_options',
                'read_returns',
                'read_shipping',
                'read_locales',
                'read_content',
                'read_third_party_fulfillment_orders',
                'read_custom_fulfillment_services',
                'read_customer_merge',
                'read_delivery_customizations',
                'read_fulfillment_constraint_rules',
                'read_themes',
                'write_themes',
                'write_customers',
                'write_fulfillments',
                'write_order_edits',
                'write_orders',
                'write_draft_orders',
            ];
            const scopesQuery = scopes.join('%2C');
            const installShopifyAppUrl = `https://admin.shopify.com/oauth/authorize?client_id=${clientId}&scope=${scopesQuery}&redirect_uri=${redirectUrl}`;

            window.location.href = installShopifyAppUrl;
        },

        async updateAccountBusinessType() {
            const formData = {
                business_type: 'OTHER',
            };
            try {
                await this.actionUpdateAccount(formData);
                this.goToFinalStep();
            } catch (error) {
                console.error('Error updating business type OTHER: ', error);
            }
        },

        goToNextStep() {
            if (this.currentStep < 4) {
                if (this.selectedCard === 'others') {
                    this.updateAccountBusinessType();
                } else if (this.selectedCard === 'shopify') {
                    this.updateEcommerceSettings();
                } else {
                    this.currentStep++;
                }
            }
        },

        goToPreviousStep() {
            if (this.currentStep > 1) {
                if (this.currentStep === 2) {
                    this.unsubscribeFromChannel();
                }
                this.currentStep--;
            }
        },

        goToFinalStep() {
            this.currentStep = 4;
        },

        handleMessageOutput(message) {
            try {
                const parsedData = JSON.parse(message.data);
                if (message && parsedData.current_step) {
                    if (parsedData.current_step === 'ECOMMERCE_POLICIES_UPDATED') {
                        this.ECOMMERCE_POLICIES_UPDATED = true;
                    } else if (parsedData.current_step === 'ECOMMERCE_CATALOG_UPDATED') {
                        this.ECOMMERCE_CATALOG_UPDATED = true;
                    }
                } else {
                    console.log('no hay mensaje');
                }
            } catch (error) {
                console.error('Error processing messagge event:', error);
            }
        },

        subscribeToChannel() {
            const accountId = this.$store.getters['user/user'].account_id;
            const channelPath = `/account/${accountId}`;
            this.subscribe({
                topic: channelPath,
                callback: this.handleMessageOutput,
            });
        },

        unsubscribeFromChannel() {
            const accountId = this.$store.getters['user/user'].account_id;
            const channelPath = `/account/${accountId}`;
            this.unsubscribe({
                topic: channelPath,
                callback: this.handleMessageOutput,
            });
        },
    },
    async mounted() {
        if (this.currentStep === 1) {
            let canvas = document.createElement('canvas');
            canvas.id = 'canvas-confetti';
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            this.$refs.confettiContainer.appendChild(canvas);

            this.$confetti.start({
                canvasId: 'canvas-confetti',
                particles: [
                    {
                        type: 'rect',
                    },
                ],
            });

            try {
                setTimeout(() => {
                    this.$confetti.stop();
                    if (this.$refs.confettiContainer) {
                        this.$refs.confettiContainer.style.pointerEvents = 'none';
                    }
                }, 2000);

                setTimeout(() => {
                    if (this.$refs.confettiContainer) {
                        this.$refs.confettiContainer.removeChild(this.$refs.confettiContainer.firstChild);
                    }
                }, 5000);
            } catch (error) {
                console.error('Confetti already removed');
            }
        } else if (this.currentStep === 2 || this.currentStep === 3) {
            await this.subscribeToChannel();
        }
    },

    beforeUnmount() {
        this.$confetti.stop();
        if (this.currentStep === 2 || this.currentStep === 3) {
            this.unsubscribeFromChannel();
        }
    },
};
</script>
