<template>
    <md-card class="md-card-signup">
        <slot name="title"></slot>

        <md-card-content>
            <div class="md-layout">
                <slot name="content-left"></slot>
                <slot name="content-right"></slot>
            </div>
        </md-card-content>

        <md-card-actions>
            <slot name="footer"></slot>
        </md-card-actions>
    </md-card>
</template>

<script>
export default {
    name: 'signup-card',
};
</script>

<style lang="css">
.md-card-signup {
    border-radius: 6px;
    -webkit-box-shadow:
        0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0 6px 30px 5px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(0, 0, 0, 0.2);
    box-shadow:
        0 16px 24px 2px rgba(0, 0, 0, 0.14),
        0 6px 30px 5px rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(0, 0, 0, 0.2);
    margin-bottom: 100px;
    padding: 40px 0;
    margin-top: 15vh;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
}
</style>
