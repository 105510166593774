<template>
    <div>
        <AuthLayout>
            <TheInvite></TheInvite>
        </AuthLayout>
    </div>
</template>

<script>
import AuthLayout from '@/layouts/LoginOrSignupLayout.vue';
import TheInvite from '@/components/Auth/TheInvite.vue';

export default {
    name: 'Invite',
    components: { AuthLayout, TheInvite },
};
</script>

<style scoped lang="scss"></style>
