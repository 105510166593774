import { render, staticRenderFns } from "./MessageMetadataInspector.vue?vue&type=template&id=337e707b&scoped=true"
import script from "./MessageMetadataInspector.vue?vue&type=script&lang=js"
export * from "./MessageMetadataInspector.vue?vue&type=script&lang=js"
import style0 from "./MessageMetadataInspector.vue?vue&type=style&index=0&id=337e707b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "337e707b",
  null
  
)

export default component.exports