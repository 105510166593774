<script>
export default {
    name: 'SkillDetailsAboutCard',
    props: {
        skill: {
            type: Object,
            required: true,
        },
    },
};
</script>

<template>
    <md-card>
        <md-card-content class="skills-details-about-card">
            <div class="md-subheading">
                <strong> About {{ skill.name }} </strong>
            </div>
            <div>
                <div class="md-body-1">
                    The "Orders Details" skill is a crucial enhancement for Lixsa, enabling it to access and interact
                    with comprehensive customer orders information. This capability is designed to facilitate more
                    personalized and efficient customer service interactions by allowing Lixsa to retrieve order
                    histories, track current order statuses, and provide updates directly to customers inquiring about
                    their purchases.
                </div>
                <div class="md-body-1">
                    When activated, this skill allows Lixsa to pull specific data from the organization's order
                    management system, including order IDs, product details, quantities purchased, prices, and estimated
                    delivery dates. This information empowers the AI agent to answer customer queries accurately, such
                    as "What’s the status of my order?" or "Can you provide details about my recent purchases?"
                </div>
                <div class="md-body-1">
                    Furthermore, the "Orders Details" skill includes robust privacy protocols to ensure that all data
                    access is secure and compliant with relevant data protection regulations. Only essential information
                    is fetched, and all interactions are logged for auditing purposes.
                </div>
                <div class="md-body-1">
                    This skill not only enhances customer satisfaction by providing timely and relevant information but
                    also optimizes the workflow of human support agents. By automating routine inquiries regarding order
                    details, Lixsa can free up human agents to handle more complex or sensitive issues, thereby
                    improving overall operational efficiency.
                </div>
            </div>
        </md-card-content>
    </md-card>
</template>

<style scoped lang="scss">
.skills-details-about-card {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
</style>
