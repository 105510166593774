<template>
    <div class="onboarding-container">
        <!-- Top Bar -->
        <md-toolbar class="md-transparent custom-toolbar">
            <div class="md-toolbar-row custom-toolbar-row">
                <div class="md-toolbar-section-end">
                    <md-menu :mdCloseOnClick="true" md-size="huge" :md-offset-x="-230" :md-offset-y="5">
                        <md-list md-menu-trigger>
                            <md-avatar class="md-avatar-icon">
                                <md-ripple v-if="$store.state.user.userProfilePicture"
                                    ><img
                                        :src="$store.state.user.userProfilePicture"
                                        alt="Profile Picture"
                                        referrerpolicy="no-referrer"
                                /></md-ripple>
                                <md-ripple v-else>{{ firstLetterOfUsername }}</md-ripple>
                            </md-avatar>
                        </md-list>
                        <md-menu-content>
                            <div class="author-card">
                                <div class="author-card-info">
                                    <div class="user-info-section">
                                        <md-avatar class="md-avatar-icon custom-avatar">
                                            <img
                                                v-if="$store.state.user.userProfilePicture"
                                                :src="$store.state.user.userProfilePicture"
                                                alt="Profile Picture"
                                                referrerpolicy="no-referrer"
                                            />
                                            <span v-else>{{ firstLetterOfUsername }}</span>
                                        </md-avatar>
                                        <div>
                                            <div class="md-body-2">{{ this.$store.state.user.user.username }}</div>
                                            <div class="md-caption">{{ this.$store.state.user.user.email }}</div>
                                        </div>
                                    </div>
                                    <md-divider></md-divider>
                                    <ul class="menu-items">
                                        <li @click="logout" class="md-body-1">
                                            <md-icon class="icon">logout</md-icon>Log out
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>
        </md-toolbar>

        <!-- Main Content -->
        <div class="custom-title">
            <div>
                <p class="md-display-1 custom-title-text">Welcome to Lixsa</p>
                <div class="md-caption custom-subtitle">Let lixsa take care of your onboarding process</div>
            </div>
        </div>
        <div class="onboarding-content md-layout">
            <div class="md-layout-item md-size-30 md-xsmall-size-100 left-column">
                <OnboardingInfoPanel></OnboardingInfoPanel>
            </div>
            <div class="md-layout-item md-size-70 md-xsmall-size-100 right-column">
                <div class="custom-chat-panel">
                    <OnboardingChat></OnboardingChat>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OnboardingInfoPanel from './OnboardingInfoPanel.vue';
import OnboardingForm from './OnboardingForm.vue';
import OnboardingChat from './OnboardingChat.vue';

export default {
    name: 'Onboard',
    components: {
        OnboardingInfoPanel,
        OnboardingForm,
        OnboardingChat,
    },
    data() {
        return {};
    },
    computed: {
        firstLetterOfUsername() {
            if (this.$store.state.user.user.username && this.$store.state.user.user.username.length > 0) {
                return this.$store.state.user.user.username.charAt(0).toUpperCase();
            }
            return 'A';
        },
    },
    methods: {
        logout() {
            this.$store
                .dispatch('user/logout')
                .then(() => {
                    this.$router.push({ name: 'Login', query: { logout: 'true' } });
                })
                .catch((error) => {
                    this.error = error.message;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.onboarding-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: white;
}

.onboarding-content {
    margin-bottom: 100px;
    flex-grow: 1;
    display: flex;

    .custom-chat-panel {
        max-width: 1000px;
        width: 1000px;

        @media (max-width: 1919px) {
            width: 1000px;
            min-width: 100px;
        }
    }
}

.left-column {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1279px) {
        display: none;
    }
}

.right-column {
    display: flex;
    align-items: center;
    justify-content: center;
}

.company-logo {
    height: 40px;
    width: auto;
    margin-right: 10px;
}

.company-name {
    font-size: 20px;
    line-height: 40px;
    color: #000;
}

.author-card {
    padding: 8px 16px;
    display: flex;
    align-items: center;

    .author-card-info {
        display: flex;
        flex-flow: column;
        flex: 1;
    }
}

.md-divider {
    margin-top: 5px;
    margin-bottom: 5px;
}

.menu-items {
    list-style: none;
    padding: 0;
}

.menu-items li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
}

.menu-items li:hover {
    background-color: #f5f5f5;
}

.icon {
    margin: 0px 5px 0px 5px;
    font-size: 16px !important;
}

.user-info-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.custom-avatar {
    margin: 0px 15px 0px 0px;
}

.custom-title {
    @media (min-width: 959px) {
        display: none;
    }

    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    .custom-title-text {
        text-align: center;

        margin-top: 0px;
        margin-bottom: 0px;
    }

    .custom-subtitle {
        text-align: center;
    }
}

.custom-toolbar {
    padding-bottom: 0px;

    .custom-toolbar-row {
        margin-bottom: 0px;
    }
}
</style>
