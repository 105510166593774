<script>
import stripeService from '@/services/stripe/stripeService';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'LoginSocialButtonGoogle',
    emits: ['on-error'],
    props: ['queryParams'],
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
    methods: {
        ...mapActions('user', ['actionGetUser', 'actionGetAccount', 'actionSignUpWithPopup', 'logout']),
        ...mapActions('membership', ['getAccountMembership', 'subscribeToMembershipUpdates']),
        ...mapActions('agentSettings', ['updateAgentSetting']),

        async loginWithGoogle() {
            this.error = '';
            this.loading = true;

            try {
                const signupResponse = await this.actionSignUpWithPopup();

                if (!signupResponse) {
                    this.error = 'Authentication failed';
                    return;
                }

                await this.actionGetUser();
                await this.updateAgentSetting();
                const response = await this.actionGetAccount();
                if (response.status === 200 || response.status === 201) {
                    await this.getAccountMembership();
                    if (!this.isShopifyBillingClient) {
                        await stripeService.fetchCustomer();
                    }
                    this.subscribeToMembershipUpdates();
                    this.$router.push({ name: 'Dashboard', query: this.queryParams });
                }
            } catch (err) {
                // this.error = err.response.data.error.message || 'An error occurred.';
                this.$emit('on-error', err);
                this.logout();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<template>
    <md-button class="md-outline md-gray md-block" v-on:click="loginWithGoogle">
        <div class="login-social-button-google__icon"></div>
        <span> Sign in with Google </span>
    </md-button>
</template>

<style scoped lang="scss">
.login-social-button-google__icon {
    height: 17px;
    width: 17px;
    background-image: url('@/assets/icons/google-logo.svg');
    margin-right: 0.7rem;
}
</style>
