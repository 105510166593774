<script>
export default {
    name: 'MySkillsItemCard',
    props: {
        skill: {
            type: Object,
            required: true,
        },
    },
    methods: {
        goToSkillDetails(skillId) {
            this.$router.push(`/skills/details/${skillId}`);
        },
    },
};
</script>

<template>
    <div class="my-skill-item-card">
        <div class="my-skill-item-card__content">
            <div class="my-skill-item-card__image">
                <img v-if="skill.image && skill.image !== ''" :src="skill.image" alt="Skill Image" />
                <img
                    v-else
                    src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Ffoto%20perfil%20usuario.png?alt=media&token=5470816a-d159-4958-aae2-5837c747339c"
                    alt="Lixsa default image"
                />
            </div>
            <div class="my-skill-item-card__skill-information">
                <div class="my-skill-item-card__name-and-tags">
                    <span class="md-body-2">
                        {{ skill.name }}
                    </span>
                    <span>
                        <md-chip class="my-skill-item-card__active-chip">
                            <md-icon class="my-skill-item-card__active-tag-icon">circle</md-icon>
                            <div>Active</div>
                        </md-chip>
                    </span>
                </div>
                <div class="md-caption">
                    {{ skill.description }}
                </div>
            </div>
        </div>
        <div class="my-skill-item-card__manage-button">
            <md-button @click="goToSkillDetails(skill.uuid)" class="md-sm md-primary">Manage</md-button>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/md/_variables.scss';

.my-skill-item-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px #dddddd solid;
    padding: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
    background-color: #fafafa;
    border-radius: 12px;

    @media (max-width: 959px) {
        flex-direction: column;
    }
}

.my-skill-item-card__content {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 1rem;
}

.my-skill-item-card__image {
    flex: 0 0 50px;
    border-radius: 12px;
    overflow: hidden;

    img {
        width: 50px;
        height: auto;
        /* Keeps the aspect ratio */
    }
}

.my-skill-item-card__skill-information {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-x: hidden;

    .my-skill-item-card__name-and-tags {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
}

.my-skill-item-card__active-chip {
    display: flex;
    align-items: center;

    .my-skill-item-card__active-tag-icon {
        color: #5cb85c !important;
        font-size: 12px !important;
        width: 12px !important;
        height: 12px !important;
    }
}

.my-skill-item-card__manage-button {
    @media (max-width: 959px) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        border-top: 1px #dddddd solid;
        margin-top: 1rem;
        padding-top: 1rem;
    }
}
</style>
