<script>
import { mapGetters } from 'vuex';
import ShopInfo from './DetailCards/MainAppsShopifyShopInfo.vue';
import SandboxCta from './DetailCards/MainAppsShopifySandboxCta.vue';
import ConnectWhatsAppCta from './DetailCards/MainAppsShopifyConnectWhatsAppCta.vue';
import AboutCard from './MainAppsShopifyAboutCard.vue';
import RecommendedSkills from './DetailCards/MainAppsShopifyRecommendedSkills.vue';
import SyncStatus from './DetailCards/MainAppsShopifySyncStatus.vue';

export default {
    name: 'MainAppsShopifyConnectionDetails',
    components: {
        ShopInfo,
        SandboxCta,
        ConnectWhatsAppCta,
        AboutCard,
        RecommendedSkills,
        SyncStatus,
    },
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),
        showWhatsAppCta() {
            return !this.getSkillByInternalName('whatsapp');
        },
    },
};
</script>

<template>
    <div class="shopify-connection-details">
        <div class="shopify-connection-details__content-wrapper">
            <div class="shopify-connection-details__left-column">
                <About-card class="shopify-connection-details__about-card" />
                <sandbox-cta class="shopify-connection-details__cta-card" />
                <connect-whats-app-cta v-if="showWhatsAppCta" class="shopify-connection-details__cta-card" />
            </div>
            <div class="shopify-connection-details__right-column">
                <shop-info class="shopify-connection-details__shop-info" />
                <sync-status class="shopify-connection-details__sync-status" />
            </div>
        </div>
        <div class="md-title">Recommended Skills for this App</div>
        <recommended-skills class="shopify-connection-details__recommended-skills" />
    </div>
</template>

<style lang="scss" scoped>
.md-card {
    margin: 0;
}

.shopify-connection-details {
    // max-width: 1200px;
    margin: 0 auto;
    padding: 0;

    &__content-wrapper {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 1rem;
        margin-bottom: 2rem;

        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    }

    &__left-column,
    &__right-column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: stretch;
    }

    &__info-and-cta {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    // &__about-card,
    // &__shop-info,
    // &__cta-card,
    // &__sync-status {
    //     // height: 100%;
    // }

    &__recommended-skills {
        margin-top: 1rem;
        margin-bottom: 2rem;
    }
}
</style>
