<script>
import PricingSection from './PricingCards/SubscriptionPricingSection.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'subscriptionFreePlan',
    components: {
        PricingSection,
    },
    data() {
        return {
            pricingTableId: process.env.VUE_APP_STRIPE_PRICING_TABLE,
            publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
        };
    },
    props: {
        customerSessionClientSecret: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
};
</script>

<template>
    <div class="md-layout-item md-size-100 subscription-free-plan">
        <!--        <p class="md-subheading">Your subscription</p>-->
        <div class="md-display-2">
            You are currently on a <span class="subscription-free-plan__text--primary">Free plan.</span>
        </div>
        <p class="md-body-1">
            Subscribe to a Paid plan in order to enable lixsa in your chats.
            <strong>Each plan includes a 7-day free trial.</strong>
        </p>

        <md-card>
            <md-card-content>
                <div>
                    <PricingSection v-if="isShopifyBillingClient" />
                    <stripe-pricing-table
                        v-else
                        :pricing-table-id="pricingTableId"
                        :publishable-key="publishableKey"
                        :customer-session-client-secret="customerSessionClientSecret"
                    >
                    </stripe-pricing-table>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.subscription-free-plan {
    margin-top: 2rem;
}

.subscription-free-plan__text--primary {
    color: #4a25e1;
}

.md-card {
    margin-bottom: 5px;
}

//.subscription-free-plan__custom-title {
//    //text-align: center;
//    //color: black !important;
//    //padding: 0 30px;
//    //margin-bottom: 30px;
//}
</style>
