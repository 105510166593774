import { mapActions } from 'vuex';

export default {
    data() {
        return {
            stompClient: null,
            isConnected: false,
            isReconnect: false,
            subsConversations: null,
            subsMessages: null,
        };
    },
    methods: {
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),

        handleRoomMessagesEvent(roomMessageEvent) {
            const message = this.reorderMessage(roomMessageEvent);
            this.processMessages(message);
        },

        async subscribeToRoomMessages(conversationId) {
            try {
                await this.subscribe({
                    topic: `/account/${this.$store.getters['user/user'].account_id}/conversation/${conversationId}`,
                    callback: this.handleRoomMessagesEvent,
                });
            } catch (error) {
                console.error('Failed to subscribe to room messages topic:', error);
            }
        },

        unsubscribeToRoomMessages(conversationId) {
            const topic = `/account/${this.$store.getters['user/user'].account_id}/conversation/${conversationId}`;

            this.unsubscribe({
                topic,
                callback: this.handleRoomMessagesEvent,
            });
        },

        async subscribeToRoomsList() {
            try {
                await this.subscribe({
                    topic: `/account/${this.$store.getters['user/user'].account_id}/conversation`,
                    callback: this.handleRoomsListEvent,
                });
            } catch (error) {
                console.error('Failed to subscribe to rooms list topic:', error);
            }
        },

        unsubscribeToRoomsList() {
            const topic = `/account/${this.$store.getters['user/user'].account_id}/conversation`;

            this.unsubscribe({
                topic,
                callback: this.handleRoomsListEvent,
            });
        },

        disconnectSocket() {
            if (this.stompClient && this.isConnected) {
                this.stompClient.deactivate();
                this.isConnected = false;
            }
        },
    },
};
