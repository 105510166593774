<script>
import { Tabs } from '@/components';
import SkillsDiscoveryTab from '@/pages/Dashboard/Skills/SkillsDiscoveryTab.vue';
import SkillsMySkillsTab from '@/pages/Dashboard/Skills/SkillsMySkillsTab.vue';

export default {
    name: 'SkillsPage',
    components: {
        SkillsMySkillsTab,
        SkillsDiscoveryTab,
        Tabs,
    },
};
</script>

<template>
    <div class="custom-main-panel">
        <div class="md-layout custom-content-panel">
            <div class="md-layout-item md-size-100">
                <tabs :tab-name="['Discovery', 'My Skills']" color-button="simple" class="skills-overview__custom-tabs">
                    <template v-slot:tab-pane-1>
                        <SkillsDiscoveryTab />
                    </template>
                    <template v-slot:tab-pane-2>
                        <SkillsMySkillsTab />
                    </template>
                </tabs>
            </div>
        </div>
    </div>
</template>

<style scoped>
.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 1278px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: 959px) {
        padding-left: 0;
        padding-right: 0;
    }
}
</style>
