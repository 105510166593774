<template>
    <div v-if="selectedAsset">
        <div class="details-container">
            <div class="details-customer-information">
                <div class="card-details-entry">
                    <span class="md-body-2">Contact information</span>
                </div>

                <div class="card-details-entry">
                    <div class="card-customer-information-row">
                        <div class="card-details-row">
                            <svg-icon class="card-details-row-icon card-icon-height" name="person" />
                            <div class="card-details-row-info">{{ currentUserName }}</div>
                        </div>
                    </div>

                    <div class="card-customer-information-row" v-if="currentUserEmail">
                        <div class="card-details-row">
                            <svg-icon class="card-details-row-icon card-icon-height" name="email" />
                            <div class="card-details-row-info">{{ currentUserEmail }}</div>
                        </div>
                        <span class="md-caption copied-caption" v-if="copiedFields.email">Email copied</span>
                        <div
                            class="card-details-row-icon card-icon-height copy-icon"
                            v-html="svgIcon"
                            @click="copyCodeToClipboard(currentUserEmail, 'email')"
                        ></div>
                    </div>

                    <div class="card-customer-information-row" v-if="currentUserPhone">
                        <div class="card-details-row">
                            <svg-icon class="card-details-row-icon card-icon-height" name="phone" />
                            <div class="card-details-row-info">{{ currentUserPhone }}</div>
                        </div>
                        <span class="md-caption copied-caption" v-if="copiedFields.phone">Phone copied</span>
                        <div
                            class="card-details-row-icon card-icon-height copy-icon"
                            v-html="svgIcon"
                            @click="copyCodeToClipboard(currentUserPhone, 'phone')"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

export default {
    name: 'CustomerAssetsContactInformation',
    components: {
        SvgIcon,
    },
    data() {
        return {
            svgIcon: '',
            copiedFields: {
                email: false,
                phone: false,
            },
        };
    },
    props: {
        selectedAsset: { type: [Object, Array] },
        currentUserName: { type: String, required: false, default: undefined },
        currentUserEmail: { type: String, required: false, default: undefined },
        currentUserPhone: { type: String, required: false, default: undefined },
    },
    mounted() {
        this.svgIcon = this.getCopyIcon();
    },
    methods: {
        getCopyIcon() {
            return `
<svg xmlns="http://www.w3.org/2000/svg" height="17px" viewBox="0 -960 960 960" width="24px"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg>`;
        },
        copyCodeToClipboard(text, field) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    this.copiedFields[field] = true;
                    setTimeout(() => {
                        this.copiedFields[field] = false;
                    }, 1000);
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                });
        },
    },
    created() {
        this.urlOrderStatus = process.env.VUE_APP_URL_STATUS_ORDER;
    },
};
</script>
