import axios from 'axios';
import store from '@/store';

const baseURL = process.env.VUE_APP_API_URL;
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const notificationsService = {
    getUnreadNotificationsCount(ignoredEvents) {
        return apiRequest(
            'get',
            `accounts/${store.state.user.user.account_id}/notifications/unread/count?user_id=${store.state.user.userId}&types=ACCOUNT,CONVERSATION,TASK${ignoredEvents.length > 0 ? `&ignored_events=${ignoredEvents}` : ''}`,
        );
    },

    getPagedNotificationsByType(type, ignoredEvents, page, pageSize) {
        return apiRequest(
            'get',
            `accounts/${store.state.user.user.account_id}/notifications/?types=${type}${ignoredEvents.length > 0 ? `&ignored_events=${ignoredEvents}` : ''}&page=${page}&size=${pageSize}`,
        );
    },

    markNotificationAsRead(notificationId) {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/notifications/${notificationId}/read?user_id=${store.state.user.userId}`,
        );
    },

    markAllNotificationsAsRead() {
        return apiRequest(
            'post',
            `accounts/${store.state.user.user.account_id}/notifications/read?user_id=${store.state.user.userId}`,
        );
    },
};

export default notificationsService;
