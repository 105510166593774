import axios from 'axios';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const communicationChannelsService = {
    async fetchCommunicationChannels(page = 0, size = 20) {
        try {
            return store.dispatch('user/apiCall', async (token) => {
                return await axios.get(
                    `${process.env.VUE_APP_API_URL}/accounts/${store.state.user.user.account_id}/communication-channels`,
                    {
                        params: {
                            page,
                            size,
                        },
                        headers: getAuthHeader(token),
                    },
                );
            });
        } catch (error) {
            throw error;
        }
    },

    async deleteCommunicationChannel(CommunicationChannelId) {
        try {
            return store.dispatch('user/apiCall', async (token) => {
                return await axios.delete(
                    `${process.env.VUE_APP_API_URL}/accounts/${store.state.user.user.account_id}/communication-channels/${CommunicationChannelId}`,
                    {
                        headers: getAuthHeader(token),
                    },
                );
            });
        } catch (error) {
            throw error;
        }
    },

    async createCommunicationChannel(businessKnowledgeData) {
        try {
            const requestData = {
                uuid: businessKnowledgeData.uuid ? businessKnowledgeData.uuid : uuidv4(),
                account_id: store.state.user.user.account_id,
                access_token: businessKnowledgeData.access_token,
                verify_token: businessKnowledgeData.verify_token,
                phone_number_id: businessKnowledgeData.phone_number_id,
                business_account_id: businessKnowledgeData.business_account_id,
                channel_type: businessKnowledgeData.channel_type,
            };
            return store.dispatch('user/apiCall', async (token) => {
                return await axios.post(
                    `${process.env.VUE_APP_API_URL}/accounts/${store.state.user.user.account_id}/communication-channels`,
                    requestData,
                    {
                        headers: getAuthHeader(token),
                    },
                );
            });
        } catch (error) {
            throw error;
        }
    },

    async editCommunicationChannel(CommunicationChannelId, updateData) {
        try {
            return store.dispatch('user/apiCall', async (token) => {
                return await axios.post(
                    `${process.env.VUE_APP_API_URL}/accounts/${store.state.user.user.account_id}/communication-channels/${CommunicationChannelId}`,
                    updateData,
                    {
                        headers: getAuthHeader(token),
                    },
                );
            });
        } catch (error) {
            throw error;
        }
    },
};

export default communicationChannelsService;
