<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="showEditDialog">
            <md-dialog-title><strong>Edit Account</strong></md-dialog-title>
            <div class="modal-content">
                <md-field>
                    <label>Access Token</label>
                    <md-input type="text" v-model="localAccount.access_token"></md-input>
                </md-field>

                <md-field>
                    <label>Verify Token</label>
                    <md-input type="text" v-model="localAccount.verify_token"></md-input>
                </md-field>

                <md-field>
                    <label>Phone Number ID</label>
                    <md-input type="text" v-model="localAccount.phone_number_id"></md-input>
                </md-field>

                <md-field>
                    <label>Business Account ID</label>
                    <md-input type="text" v-model="localAccount.business_account_id"></md-input>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="handleEdit" :disabled="!canSave">
                    <span v-if="!isLoading">Edit</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import communicationChannelsService from '@/services/account/communicationChannelsService.js';

export default {
    name: 'ConnectAccount',
    props: {
        showEditDialog: {
            type: Boolean,
            default: false,
        },
        account: {
            type: Object,
            default: () => ({}),
        },
    },
    watch: {
        // Create a local copy avoiding double binding with parent's object.
        account: {
            deep: true,
            handler(newValue) {
                this.localAccount = JSON.parse(JSON.stringify(newValue));
            },
        },
    },
    data: () => ({
        isLoading: false,
        localAccount: {},
    }),
    computed: {
        isEdited() {
            return JSON.stringify(this.localAccount) !== JSON.stringify(this.account);
        },
        canSave() {
            // Check if all fields are filled in and if local copy is different from the original data
            return (
                this.isEdited &&
                this.localAccount.access_token &&
                this.localAccount.verify_token &&
                this.localAccount.phone_number_id &&
                this.localAccount.business_account_id
            );
        },
    },
    created() {
        this.localAccount = JSON.parse(JSON.stringify(this.account));
    },
    methods: {
        handleClose() {
            this.$emit('update:localAccount', {});
            this.$emit('update:showEditDialog', false);
            this.isLoading = false;
        },
        async handleEdit() {
            this.isLoading = true;

            try {
                const response = await communicationChannelsService.editCommunicationChannel(
                    this.localAccount.uuid,
                    this.localAccount,
                );
                if (response.status === 200) {
                    this.isLoading = false;
                    this.notifyParent();
                    this.handleClose();
                    this.showSuccessToast('Communication channel Edited');
                }
            } catch (error) {
                console.error('Error connecting account: ' + error);
                this.isLoading = false;
                this.handleClose();
                this.showErrorToast('Error editing Communication channel');
            }
        },
        notifyParent() {
            this.$emit('accountEdited');
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style lang="css" scoped>
.md-dialog .md-dialog-container {
    max-width: 768px;
}

.modal-content {
    background: #fff;
    padding: 30px;
    padding-top: 0px;
    text-align: center;
    margin: 0 auto;
    width: 500px;
}

.modal-content h4 {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}
</style>
