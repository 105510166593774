// package mp3;

import III_psy_xmin from "./III_psy_xmin.js";

function III_psy_ratio() {
  this.thm = new III_psy_xmin();
  this.en = new III_psy_xmin();
}

export default III_psy_ratio;
