<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="showDialog">
            <md-dialog-title><strong>Connect Account</strong></md-dialog-title>
            <div class="modal-content">
                <md-field>
                    <label>Access Token</label>
                    <md-input type="text" v-model="access_token"></md-input>
                </md-field>

                <md-field>
                    <label>Verify Token</label>
                    <md-input type="text" v-model="verify_token"></md-input>
                </md-field>

                <md-field>
                    <label>Phone Number ID</label>
                    <md-input type="text" v-model="phone_number_id"></md-input>
                </md-field>

                <md-field>
                    <label>Business Account ID</label>
                    <md-input type="text" v-model="business_account_id"></md-input>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="handleConnect" :disabled="!canSave">
                    <span v-if="!isLoading">Connect</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-button class="md-primary md-block" :md-ripple="false" @click="showDialog = true">
            Connect account
        </md-button>
    </div>
</template>

<script>
import communicationChannelsService from '@/services/account/communicationChannelsService.js';

export default {
    name: 'channelsConnectModal',
    emits: ['account-connected'],
    data: () => ({
        showDialog: false,
        access_token: '',
        verify_token: '',
        phone_number_id: '',
        business_account_id: '',
        channel_type: 'WHATSAPP',
        isLoading: false,
    }),
    computed: {
        canSave() {
            return this.access_token && this.verify_token && this.phone_number_id && this.business_account_id;
        },
    },
    methods: {
        handleClose() {
            this.access_token = '';
            this.verify_token = '';
            this.phone_number_id = '';
            this.business_account_id = '';
            this.showDialog = false;
            this.isLoading = false;
        },
        async handleConnect() {
            this.isLoading = true;

            try {
                const reqData = {
                    access_token: this.access_token,
                    verify_token: this.verify_token,
                    phone_number_id: this.phone_number_id,
                    business_account_id: this.business_account_id,
                    channel_type: this.channel_type,
                };
                const response = await communicationChannelsService.createCommunicationChannel(reqData);
                if (response.status === 200) {
                    this.isLoading = false;
                    this.notifyParent();
                    this.handleClose();
                    this.showSuccessToast('Communication channel connected');
                }
            } catch (error) {
                console.error('Error connecting Communication channel: ' + error);
                this.isLoading = false;
                this.handleClose();
                this.showErrorToast('Error connecting Communication channel');
            }
        },
        notifyParent() {
            this.$emit('account-connected');
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style lang="css" scoped>
.md-dialog .md-dialog-container {
    max-width: 768px;
}

.modal-content {
    background: #fff;
    padding: 30px;
    padding-top: 0px;
    text-align: center;
    margin: 0 auto;
    width: 500px;
}

.modal-content h4 {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}
</style>
