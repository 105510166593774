<script>
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import stripeService from '@/services/stripe/stripeService';
import RegisterSocialButtonGoogle from '@/components/Auth/Register/RegisterSocialButtonGoogle.vue';
import RegisterSocialButtonFacebook from '@/components/Auth/Register/RegisterSocialButtonFacebook.vue';
import RegisterForm from '@/components/Auth/Register/RegisterForm.vue';
import AuthErrorPanel from '@/components/Auth/AuthErrorPanel.vue';

export default {
    name: 'Register',
    components: {
        AuthErrorPanel,
        RegisterForm,
        RegisterSocialButtonFacebook,
        RegisterSocialButtonGoogle,
    },
    data() {
        return {
            loading: false,
            error: '',
            queryParams: {},
        };
    },
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
    methods: {
        ...mapActions('user', [
            'actionLogin',
            'actionGetUser',
            'actionSignup',
            'actionCreateAccount',
            'refreshToken',
            'actionGetAccount',
            'actionSignUpWithPopup',
            'actionSignUpWithFacebook',
            'logout',
        ]),
        ...mapActions('membership', ['getAccountMembership', 'subscribeToMembershipUpdates']),
        ...mapActions('agentSettings', ['updateAgentSetting']),

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        handleIdentityToolkitError(error) {
            let identityToolkitErrorMessage = '';
            switch (error.message) {
                case 'EMAIL_EXISTS':
                    identityToolkitErrorMessage = 'This account already exists';
                    break;
            }
            return identityToolkitErrorMessage;
        },

        handleLixsaAuthServerError(error) {
            let lixsaAuthServerErrorMessage = '';

            switch (error.response.status) {
                case 500:
                    lixsaAuthServerErrorMessage = 'Server error. Please try again later';
                    break;
                default:
                    lixsaAuthServerErrorMessage = 'An error occurred. Please try again later';
                    break;
            }

            return lixsaAuthServerErrorMessage;
        },

        handleError(error) {
            // In case the error comes from Google Identity toolkit
            if (error.response.data.error) {
                this.error = this.handleIdentityToolkitError(error.response.data.error);
                return;
            }

            // In case the error comes from Lixsa Server
            this.error = this.handleLixsaAuthServerError(error);
        },

        async register(inputs) {
            const firstName = inputs[0].value;
            const email = inputs[1].value;
            const password = inputs[2].value;
            const myUuid = uuidv4();
            this.error = '';

            this.loading = true;
            try {
                const userData = await this.actionSignup({ email, password });

                if (!userData) {
                    this.error = 'Authentication failed';
                    this.loading = false;
                    return;
                }

                let sourcePlatform = 'STRIPE';
                if (this.queryParams.source) {
                    sourcePlatform =
                        this.queryParams.source.toLowerCase() === 'shopify'
                            ? 'SHOPIFY_BILLING'
                            : this.queryParams.source;
                }

                const accData = {
                    uuid: myUuid,
                    email: userData.email,
                    user_id: userData.localId,
                    contact_name: firstName,
                    source_platform: sourcePlatform,
                };

                await this.actionCreateAccount(accData);
                await this.refreshToken();

                await this.actionGetUser();
                await this.updateAgentSetting();
                const response = await this.actionGetAccount();
                if (response.status === 200 || response.status === 201) {
                    await this.getAccountMembership();
                    if (!this.isShopifyBillingClient) {
                        await stripeService.fetchCustomer();
                    }
                    this.subscribeToMembershipUpdates();
                    this.$router.push({ name: 'Dashboard', query: this.queryParams });

                    // this.$router.push({ name: "Onboard" });
                    this.showSuccessToast('Sign up complete!');
                }
            } catch (error) {
                this.handleError(error);
                this.logout();
            } finally {
                this.loading = false;
            }
        },

        handleQueryParams() {
            const query = this.$route.query;
            if (Object.keys(query).length > 0 && query.source === 'Shopify') {
                this.queryParams = JSON.parse(JSON.stringify(query));
            }
        },

        clearError() {
            this.error = '';
        },
    },

    created() {
        this.handleQueryParams();
    },
};
</script>

<template>
    <div class="md-layout text-center">
        <div class="custom-signup-panel md-layout md-layout-item md-size-70 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
                <div class="md-display-1 register__text--left register__title">
                    <strong> Sign up </strong>
                </div>
            </div>

            <div v-if="error !== ''" class="md-layout-item md-size-100">
                <AuthErrorPanel :error-message="error" @close-panel="clearError"></AuthErrorPanel>
            </div>

            <div class="md-layout-item md-size-100 register__social-section">
                <RegisterSocialButtonGoogle
                    :query-params="queryParams"
                    @onError="
                        (args) => {
                            handleError(args);
                        }
                    "
                />
                <RegisterSocialButtonFacebook
                    :query-params="queryParams"
                    @onError="
                        (args) => {
                            handleError(args);
                        }
                    "
                />
            </div>

            <div class="md-layout-item md-size-100 register__text--left">
                <p class="md-body-1">Or sign up with your email:</p>
            </div>

            <RegisterForm :loading="loading" @perform-register="(args) => register(args)" />
        </div>
        <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <p class="md-body-1 register__text--left">
                By creating your account, you agree to the
                <a href="https://www.lixsa.ai/terms"> Terms of Service </a> and
                <a href="https://www.lixsa.ai/privacy">Privacy Policy</a>.
            </p>
        </div>

        <div class="md-layout-item md-size-70 md-xsmall-size-100">
            <div class="md-body-1 register__text--left">
                Already have and account?
                <router-link to="/">Click here to sign in</router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.custom-signup-panel {
    padding-left: 0;
    padding-right: 0;

    .custom-signup-form {
        margin-top: 15px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

.register__title {
    margin-bottom: 2rem;
}

.register__text--left {
    text-align: start;
}

.register__social-section {
    display: flex;
    justify-content: start;
    gap: 1rem;

    //margin-top: 2rem;
    margin-bottom: 1rem;
}
</style>
