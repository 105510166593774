<template>
    <md-card class="whatsapp-connect-card">
        <md-card-content>
            <div class="md-title"><strong>Connect Lixsa with WhatsApp</strong></div>
            <p class="md-body-1">
                Enhance your Lixsa experience by connecting it with WhatsApp. This integration allows for seamless
                communication and improved customer engagement.
            </p>
            <md-button class="md-primary md-raised" @click="goToWhatsAppSkill"> Connect to WhatsApp </md-button>
        </md-card-content>
    </md-card>
</template>

<script>
export default {
    name: 'SandboxConnectToWhatsappCta',
    methods: {
        goToWhatsAppSkill() {
            this.$router.push('/skills/details/8c0f230d-cca0-4335-a359-d3fd4ab284cc');
        },
    },
};
</script>

<style scoped>
.whatsapp-connect-card {
    background-color: #f5f5f5;
    margin: 20px auto;
}
</style>
