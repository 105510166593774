<template>
    <li @click="notificationPanelOpen()" class="sidebar-notifications-button">
        <a
            href="#"
            class="bell-button"
            :aria-expanded="!collapsed"
            data-toggle="collapse"
            @click.prevent="collapseMenu"
        >
            <div class="notification-menu-container">
                <div class="notification-bell-container">
                    <i v-if="link.icon !== ''" class="material-symbols-outlined notification-bell-icon">{{
                        link.icon
                    }}</i>
                    <i v-else :class="link.icon_fa"></i>

                    <span class="count-badge" v-if="unreadCountText !== ''">{{ unreadCountText }}</span>
                </div>
                <p>
                    {{ link.name }}
                </p>
            </div>
        </a>
    </li>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
    name: 'sidebar-notifications-item',
    emits: ['notificationPanelOpen'],
    components: {
        CollapseTransition,
    },
    props: {
        menu: {
            type: Boolean,
            default: false,
        },
        link: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    disableCollapse: Boolean,
                    children: [],
                };
            },
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        unreadCount: {
            type: Number,
            default: 0,
        },
    },
    provide() {
        return {
            addLink: this.addChild,
            removeLink: this.removeChild,
        };
    },
    inject: {
        addLink: { default: null },
        removeLink: { default: null },
        autoClose: {
            default: true,
        },
    },
    data() {
        return {
            children: [],
            collapsed: true,
        };
    },
    computed: {
        baseComponent() {
            return this.isMenu || this.link.isRoute ? 'li' : 'router-link';
        },
        linkPrefix() {
            if (this.link.name) {
                let words = this.link.name.split(' ');
                return words.map((word) => word.substring(0, 1)).join('');
            }
            return false;
        },
        isMenu() {
            return this.children.length > 0 || this.menu === true;
        },
        unreadCountText() {
            return this.unreadCount > 99 ? '99+' : this.unreadCount.toString();
        },
    },
    methods: {
        addChild(item) {
            const index = this.$slots.default.indexOf(item.$vnode);
            this.children.splice(index, 0, item);
        },
        removeChild(item) {
            const tabs = this.children;
            const index = tabs.indexOf(item);
            tabs.splice(index, 1);
        },
        elementType(link, isParent = true) {
            if (link.isRoute === false) {
                return isParent ? 'li' : 'a';
            } else {
                return 'router-link';
            }
        },
        linkAbbreviation(name) {
            const matches = name.match(/\b(\w)/g);
            return matches.join('');
        },
        linkClick() {
            if (!this.addLink && !this.link.disableCollapse) {
                this.$sidebar.collapseAllMenus();
            }

            if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
                this.$sidebar.displaySidebar(false);
            }
        },
        collapseMenu() {
            if (this.collapsed && !this.link.disableCollapse) {
                this.$sidebar.addSidebarLink(this);
                this.$sidebar.collapseAllMenus();
            }

            this.collapsed = !this.collapsed;
        },
        collapseSubMenu(link) {
            link.collapsed = !link.collapsed;
        },
        notificationPanelOpen() {
            this.$emit('notificationPanelOpen');
        },
    },
    async mounted() {
        if (this.addLink) {
            this.addLink(this);
        }
        if (this.link.collapsed !== undefined) {
            this.collapsed = this.link.collapsed;
        }
        if (this.isActive && this.isMenu) {
            this.collapsed = false;
        }
    },
    destroyed() {
        this.$sidebar.removeSidebarLink(this);
        if (this.$el && this.$el.parentNode) {
            this.$el.parentNode.removeChild(this.$el);
        }
        if (this.removeLink) {
            this.removeLink(this);
        }
    },
};
</script>
<style lang="scss" scoped>
.sidebar-notifications-button {
    padding: 5px 0px;
}

.bell-button {
    padding: 10px !important;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.disabled-link {
    cursor: default;
}

.sidebar-menu-item {
    cursor: pointer;
}

.notification-menu-container {
    display: flex;
    flex-direction: row;
}

.notification-bell-container {
    display: flex;
    align-items: flex-start;
    margin-right: 8px;
}

.notification-bell-icon {
    font-size: 20px !important;
}

.count-badge {
    background-color: #4a25e1;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin-left: -16px;
    margin-top: -5px;
    font-size: 8px;
    font-weight: 700;
    padding: 8px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
