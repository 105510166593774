import axios from 'axios';

const storageService = {
    async uploadFileToGoogleCloud(URL, file, contentType) {
        try {
            const headers = {
                'Content-Type': contentType,
            };

            const uploadResponse = await axios.put(URL, file, {
                headers: headers,
            });

            if (uploadResponse.status !== 200) {
                throw new Error(`HTTP error! Status: ${uploadResponse.status}`);
            }

            return uploadResponse;
        } catch (error) {
            throw error;
        }
    },

    async fetchImageDetails(url) {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const imageBlob = response.data;

            const size = imageBlob.size;
            const type = imageBlob.type.split('/')[1];

            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);

            return new Promise((resolve, reject) => {
                reader.onloadend = () => resolve({ size, type, preview: reader.result });
                reader.onerror = reject;
            });
            // return { size, type, preview: reader.result };
        } catch (error) {
            console.error('Error fetching the image:', error);
        }
    },

    async fetchAudioDetails(url) {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const audioBlob = response.data;

            const size = audioBlob.size;
            const type = audioBlob.type;

            let duration = 0;
            duration = await this.getAudioDuration(audioBlob);

            return { size, type, duration };
        } catch (error) {
            console.error('Error fetching the audio:', error);
        }
    },

    getAudioDuration(blob) {
        return new Promise((resolve, reject) => {
            const audio = new Audio();
            audio.onloadedmetadata = () => {
                resolve(audio.duration);
            };
            audio.onerror = () => {
                reject(new Error('Failed to load audio metadata'));
            };
            audio.src = URL.createObjectURL(blob);
        });
    },

    async fetchDocumentDetails(url) {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const documentBlob = response.data;

            const size = documentBlob.size;
            const type = documentBlob.type;

            return { size, type };
        } catch (error) {
            console.error('Error fetching the document:', error);
        }
    },
};

export default storageService;
