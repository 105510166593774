<template>
    <CustomerAssetsDetailsEcommerceOrder
        v-if="selectedAsset.assetType === 'Orders'"
        :selected-asset="selectedAsset"
        :current-user-id="currentUserId"
        :current-user-name="currentUserName"
        :currentUserEmail="currentUserEmail"
        :currentUserPhone="currentUserPhone"
        :show-details-order="showDetailsOrder"
        @toggle-details-order="toggleShowDetailsOrder"
        @toggle-info-customer="toggleShowInfoCustomer"
    ></CustomerAssetsDetailsEcommerceOrder>

    <CustomerAssetsDetailsEcommerceDraftOrder
        v-else-if="selectedAsset.assetType === 'DraftOrders'"
        :selected-asset="selectedAsset"
        :current-user-id="currentUserId"
        :current-user-name="currentUserName"
        :current-user-email="currentUserEmail"
        :show-details-order="showDetailsOrder"
        @toggle-details-order="toggleShowDetailsOrder"
        @toggle-info-customer="toggleShowInfoCustomer"
    ></CustomerAssetsDetailsEcommerceDraftOrder>

    <CustomerAssetsDetailsHostelryBooking
        v-else-if="selectedAsset.assetType === 'Bookings'"
        :selected-asset="selectedAsset"
        :current-user-id="currentUserId"
        :current-user-name="currentUserName"
        :current-user-email="currentUserEmail"
        :show-details-order="showDetailsOrder"
        @toggle-details-order="toggleShowDetailsOrder"
        @toggle-info-customer="toggleShowInfoCustomer"
    ></CustomerAssetsDetailsHostelryBooking>
</template>
<script>
import CustomerAssetsDetailsEcommerceOrder from './CustomerAssetsDetailsEcommerceOrder/CustomerAssetsDetailsEcommerceOrder.vue';
import CustomerAssetsDetailsEcommerceDraftOrder from './CustomerAssetsDetailsEcommerceDraftOrder/CustomerAssetsDetailsEcommerceDraftOrder.vue';
import CustomerAssetsDetailsHostelryBooking from './CustomerAssetsDetailsHostelryBooking/CustomerAssetsDetailsHostelryBooking.vue';
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsDetails',
    components: {
        CustomerAssetsDetailsEcommerceOrder,
        CustomerAssetsDetailsEcommerceDraftOrder,
        CustomerAssetsDetailsHostelryBooking,
        SvgIcon,
    },
    data() {
        return {
            urlOrderStatus: null,
        };
    },
    props: {
        selectedAsset: { type: [Object, Array] },
        currentUserId: { type: [String, Number], required: true },
        currentUserName: { type: String, required: true },
        currentUserEmail: { type: String, required: false, default: undefined },
        currentUserPhone: { type: String, required: false, default: undefined },
        showDetailsOrder: { type: [Boolean], required: true },
        businessType: { type: [String], required: true },
    },
    methods: {
        getCurrencySymbol,
        formatNumberWithDecimals,
        getBadgeClass,
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order');
        },
        toggleShowInfoCustomer() {
            this.showDetailsOrder = false;
            this.$emit('toggle-details-order');
            this.$emit('toggle-info-customer');
        },
    },
    created() {
        this.urlOrderStatus = process.env.VUE_APP_URL_STATUS_ORDER;
        this.showDetailsOrder = false;
    },
};
</script>
