function VBRQuantize() {
  var qupvt;
  var tak;

  this.setModules = function (_qupvt, _tk) {
    qupvt = _qupvt;
    tak = _tk;
  };
  // TODO
}

export default VBRQuantize;
