import axios from 'axios';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

const baseURL = `${process.env.VUE_APP_API_URL}`;

const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;
    return devMode ? { 'X-API-KEY': `${token}` } : { Authorization: `Bearer ${token}` };
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return await store.dispatch('user/apiCall', async (token) => {
            const config = {
                method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params,
                data,
            };
            return await axios(config);
        });
    } catch (error) {
        console.error('API request failed', error);
        throw error;
    }
};

const businessKnowledgeService = {
    fetchBusinessKnowledges(page = 0, size = 20) {
        return apiRequest('get', 'business-knowledges', null, { page, size });
    },

    createBusinessKnowledge(businessKnowledgeData) {
        const requestData = {
            uuid: businessKnowledgeData.uuid || uuidv4(),
            account_id: store.state.user.user.account_id,
            resource_url: businessKnowledgeData.resource_url,
            name: businessKnowledgeData.name,
            type: businessKnowledgeData.type,
        };
        return apiRequest('post', 'business-knowledges', requestData);
    },

    fetchBusinessKnowledge(businessKnowledgeId) {
        return apiRequest('get', `business-knowledges/${businessKnowledgeId}`);
    },

    editBusinessKnowledge(businessKnowledgeId, updateData) {
        return apiRequest('post', `business-knowledges/${businessKnowledgeId}`, updateData);
    },

    deleteBusinessKnowledge(businessKnowledgeId) {
        return apiRequest('delete', `business-knowledges/${businessKnowledgeId}`);
    },

    syncBusinessKnowledge(businessKnowledgeId) {
        return apiRequest('post', `business-knowledges/${businessKnowledgeId}/sync`);
    },

    getLastUpdatedTimestamp() {
        return apiRequest('get', 'business-knowledges/last-updated');
    },

    getBusinessKnowledgesCount() {
        return apiRequest('get', 'business-knowledges/count');
    },

    async getStorageURL(contentType) {
        const uuid = uuidv4();
        const fileName = `${uuid}`;
        const response = await apiRequest('get', 'business-knowledges/media/upload-url', null, {
            contentType,
            fileName,
        });
        const storageURL = response.data;
        return { storageURL, uuid };
    },

    async uploadFileToGoogleCloud(URL, file, contentType) {
        try {
            const headers = { 'Content-Type': contentType };
            const uploadResponse = await axios.put(URL, file, { headers });

            if (uploadResponse.status !== 200) {
                throw new Error(`HTTP error! Status: ${uploadResponse.status}`);
            }

            return uploadResponse;
        } catch (error) {
            console.error('File upload failed', error);
            throw error;
        }
    },
};

export default businessKnowledgeService;
