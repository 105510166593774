export default {
    uuid: '',
    account_id: '',
    external_billing_id: '',
    customer_id: '',
    payment_provider: '',
    pack_id: '',
    membership_plan: 'FREE',
    membership_conversations: 0,
    pack_conversations: 0,
    total_conversations: 0,
    auto_recharge: true,
    payment_status: true,
    payment_error_message: '',
    current_subscription_start_date: 0,
    subscription_end_date: 0,
    cancel_at_period_end: true,
    subscription_cancel_at: 0,
    membership_duration_type: 'MONTH',
    date_created: 0,
    date_updated: 0,
    trial_end_date: 0,
};
