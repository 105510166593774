<template>
    <div
        class="sidebar-notification-item"
        :class="
            notification.status
                ? `notification-item-status-border-${notification.status}`
                : 'notification-item-status-border-none'
        "
        @click="markNotificationAsRead(notification)"
    >
        <div class="notification-content">
            <span class="notification-message">{{ notification.content }}</span>
            <span class="notification-timestamp">{{ formattedTimestamp }}</span>
        </div>
        <div v-if="!isReadByUser" class="read-dot"></div>
    </div>
</template>

<script>
import notificationsService from '../../../services/notifications/notificationsService';

export default {
    name: 'SidebarNotificationItem',
    emits: ['notificationRead'],
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        formattedTimestamp() {
            return new Date(this.notification.date_created * 1000).toLocaleString();
        },
        isReadByUser() {
            return this.notification.read_by.includes(this.$store.state.user.userId);
        },
    },
    methods: {
        async markNotificationAsRead(notification) {
            if (
                (notification.type === 'ConversationWindowCreated' ||
                    notification.type === 'ConversationAgentChanged') &&
                notification.trigger_id
            ) {
                const targetRoute = `/#/conversations?search=${notification.trigger_id}`;
                const currentQuery = this.$route.query;
                const targetQuery = { search: notification.trigger_id };

                if (JSON.stringify(currentQuery) !== JSON.stringify(targetQuery)) {
                    window.location.href = targetRoute;
                }
            }

            if (!this.isReadByUser) {
                await notificationsService.markNotificationAsRead(notification.uuid);

                this.isReadByUser = true;
                this.$emit('notificationRead', notification);
            }
        },
    },
};
</script>

<style scoped>
.sidebar-notification-item {
    display: flex;
    align-items: center;
    padding: 10px 18px 10px 20px;
    border-bottom: 1px solid #e0e0e0;
}
.sidebar-notification-item:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}
.notification-content {
    flex-grow: 1;
}
.notification-message {
    display: block;
    font-weight: bold;
}
.notification-timestamp {
    display: block;
    font-size: 0.8em;
    color: #888;
}
.read-dot {
    width: 10px;
    height: 10px;
    background-color: #4a25e1;
    border-radius: 50%;
    margin-left: 10px;
}

.notification-item-status-border-success {
    border-left: 15px solid #4caf50;
}

.notification-item-status-border-warning {
    border-left: 15px solid #ff9800;
}

.notification-item-status-border-error {
    border-left: 15px solid #f44336;
}

.notification-item-status-border-info {
    border-left: 15px solid #2196f3;
}

.notification-item-status-border-none {
    border-left: 15px solid rgba(0, 0, 0, 0);
}
</style>
