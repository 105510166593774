<template>
    <div
        class="md-card-tabs"
        :class="[{ 'flex-column': flexColumn }, { 'nav-pills-icons': navPillsIcons }, { 'md-card-plain': plain }]"
    >
        <div class="md-card-content">
            <!-- Desktop Tabs -->
            <md-list class="background--transparent nav-tabs" v-if="!isMobile">
                <md-list-item
                    v-for="(item, index) in tabName"
                    @click="switchPanel(tabName[index])"
                    :key="item"
                    :md-ripple="false"
                    :class="[
                        { active: isActivePanel(tabName[index]) },
                        { [getColorButton(colorButton)]: isActivePanel(tabName[index]) },
                    ]"
                >
                    {{ tabName[index] }}
                    <md-icon v-if="navPillsIcons">{{ tabIcon[index] }}</md-icon>
                </md-list-item>
            </md-list>

            <!-- Mobile Selector -->
            <md-field v-if="isMobile">
                <md-select v-model="activePanel" name="movie" id="movie">
                    <md-option v-for="item in tabName" :value="item" :key="item">{{ item }}</md-option>
                </md-select>
            </md-field>

            <!-- Tab Content -->
            <transition name="fade" mode="out-in">
                <div class="tab-content">
                    <template v-for="(item, index) in tabName">
                        <template v-if="isActivePanel(tabName[index])">
                            <div :class="getTabContent(index + 1)" :key="item">
                                <slot :name="getTabContent(index + 1)"> This is the default text! </slot>
                            </div>
                        </template>
                    </template>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        flexColumn: Boolean,
        navPillsIcons: Boolean,
        plain: Boolean,
        tabName: Array,
        tabIcon: Array,
        colorButton: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            activePanel: this.tabName[0],
            screenWidth: window.innerWidth,
        };
    },
    computed: {
        isMobile() {
            return this.screenWidth < 959;
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.syncTabWithRoute();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    watch: {
        $route() {
            this.syncTabWithRoute();
        },
        activePanel(newVal) {
            this.updateRoute(newVal);
        },
    },
    methods: {
        switchPanel(panel) {
            this.activePanel = panel;
        },
        isActivePanel(panel) {
            return this.activePanel === panel;
        },
        getColorButton: function (colorButton) {
            return 'md-' + colorButton + '';
        },
        getTabContent: function (index) {
            return 'tab-pane-' + index + '';
        },
        handleResize() {
            this.screenWidth = window.innerWidth;
        },
        syncTabWithRoute() {
            const tabFromRoute = this.tabName.find((tab) =>
                this.$route.path.includes(
                    tab
                        .toLowerCase()
                        .replace(/\s+/g, '-') // Replace spaces with "-"
                        .replace(/&/g, 'n'), // Replace '&' with "-n-" for Q&A section
                ),
            );
            if (tabFromRoute) {
                this.activePanel = tabFromRoute;
            }
        },
        updateRoute(panel) {
            const panelRoute = panel
                .toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with "-"
                .replace(/&/g, 'n'); // Replace '&' with "-n-" for Q&A section
            const basePath = this.$route.path.split('/')[1]; // Dynamically gets the base path
            const newPath = `/${basePath}/${panelRoute}`;

            if (this.$route.path !== newPath) {
                this.$router.push(newPath);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.background--transparent {
    background-color: transparent !important;
}

.md-card-content {
    padding-top: 0;

    @media (max-width: 959px) {
        padding-left: 0;
        padding-right: 0;
    }
}
@media (max-width: 959px) {
    /* Mobile-specific styles */
}
</style>
