<script>
export default {
    name: 'dateRangeButton',
    emits: ['predefined-range', 'custom-range'],
    data() {
        return {
            currentRange: 'Yesterday',
            menuItems: ['Today', 'Yesterday', 'This week', 'Last week', 'This month', 'Last month', 'Custom range'],
            rangeEndDate: null,
            rangeStartDate: null,
        };
    },
    computed: {
        isCustomRange() {
            return this.currentRange === 'Custom range';
        },

        canApplyDateRange() {
            return this.rangeStartDate !== null && this.rangeEndDate !== null;
        },
    },
    watch: {
        currentRange(newValue) {
            if (this.isCustomRange) {
                this.rangeEndDate = new Date();
                this.rangeStartDate = new Date();
                let modifiedString = newValue.replace(' ', '_');
                modifiedString = modifiedString.charAt(0).toLowerCase() + modifiedString.slice(1);
                this.$emit('predefined-range', modifiedString);
            } else {
                this.rangeEndDate = null;
                this.rangeStartDate = null;

                // Remove space and capital letter.
                let modifiedString = newValue.replace(' ', '_');
                modifiedString = modifiedString.charAt(0).toLowerCase() + modifiedString.slice(1);
                this.$emit('predefined-range', modifiedString);
            }
        },
    },
    methods: {
        setCurrentRange(currentRange) {
            this.currentRange = currentRange;
        },

        applyCustomRange() {
            // Remove space and capital letter.
            let modifiedString = this.currentRange.replace(' ', '_');
            modifiedString = modifiedString.charAt(0).toLowerCase() + modifiedString.slice(1);
            this.$emit(
                'custom-range',
                modifiedString,
                this.transformDateToString(this.rangeStartDate),
                this.transformDateToString(this.rangeEndDate),
            );
        },

        transformDateToString(localDate) {
            // Get the timezone offset in minutes and convert it to milliseconds
            let offset = localDate.getTimezoneOffset() * 60000;

            // Create a new Date object adjusted for the timezone offset
            let utcDate = new Date(localDate.getTime() - offset);

            // Convert to ISO string which will be in the format you want
            return utcDate.toISOString();
        },
    },
};
</script>

<template>
    <div class="drb__date-range-button">
        <md-menu md-size="auto" md-align-trigger>
            <md-button md-mini md-menu-trigger>
                <md-icon>date_range</md-icon>
                <span>{{ currentRange }}</span>
            </md-button>

            <md-menu-content>
                <md-menu-item v-for="item in menuItems" @click="setCurrentRange(item)">{{ item }}</md-menu-item>
            </md-menu-content>
        </md-menu>
        <div v-if="isCustomRange" class="drb__range-pickers">
            <md-datepicker md-immediately class="drb__custom-date-picker" v-model="rangeStartDate"></md-datepicker>
            -
            <md-datepicker md-immediately class="drb__custom-date-picker" v-model="rangeEndDate"></md-datepicker>
            <md-button @click="applyCustomRange" :disabled="!canApplyDateRange" class="md-sm md-primary"
                >Apply</md-button
            >
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/md/_variables.scss';

.drb__date-range-button {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;
    @media (max-width: 959px) {
        display: block;
    }
}
.drb__range-pickers {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    .drb__custom-date-picker {
        max-width: 150px;
    }
}
</style>
