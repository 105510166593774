<script>
import BillingSectionStripe from './BillingSection/BillingSectionStripe.vue';
import BillingSectionShopify from './BillingSection/BillingSectionShopify/BillingSectionShopify.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'SubscriptionPaidPlan',
    components: {
        BillingSectionStripe,
        BillingSectionShopify,
    },
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
};
</script>

<template>
    <div class="md-layout-item md-size-100">
        <BillingSectionShopify v-if="isShopifyBillingClient" />
        <BillingSectionStripe v-else />
    </div>
</template>

<style scoped lang="scss"></style>
