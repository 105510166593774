<template>
    <CustomerAssetsSummaryEcommerceOrder
        v-if="selectedAsset.assetType === 'Orders'"
        :selected-order="selectedAsset"
    ></CustomerAssetsSummaryEcommerceOrder>

    <CustomerAssetsSummaryEcommerceDraftOrder
        v-else-if="selectedAsset.assetType === 'DraftOrders'"
        :selectedAsset="selectedAsset"
    ></CustomerAssetsSummaryEcommerceDraftOrder>

    <CustomerAssetsSummaryHostelryBooking
        v-else-if="selectedAsset.assetType === 'Bookings'"
        :selectedAsset="selectedAsset"
    ></CustomerAssetsSummaryHostelryBooking>
</template>
<script>
import CustomerAssetsSummaryEcommerceOrder from './CustomerAssetsSummaryEcommerceOrder/CustomerAssetsSummaryEcommerceOrder.vue';
import CustomerAssetsSummaryEcommerceDraftOrder from './CustomerAssetsSummaryEcommerceDraftOrder/CustomerAssetsSummaryEcommerceDraftOrder.vue';
import CustomerAssetsSummaryHostelryBooking from './CustomerAssetsSummaryHostelryBooking/CustomerAssetsSummaryHostelryBooking.vue';

export default {
    name: 'CustomerAssetsSummary',
    components: {
        CustomerAssetsSummaryEcommerceOrder,
        CustomerAssetsSummaryEcommerceDraftOrder,
        CustomerAssetsSummaryHostelryBooking,
    },
    props: {
        selectedAsset: { type: Object },
    },
};
</script>
