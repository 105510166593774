<template>
    <div>
        <md-dialog :md-active.sync="showDialog">
            <md-dialog-title><strong>Upload a document</strong></md-dialog-title>
            <div class="modal-content">
                <div
                    class="dropzone"
                    :class="{ over: isDraggingOver, disabled: url != '' }"
                    @dragover.prevent="dragOver"
                    @dragenter.prevent="dragEnter"
                    @dragleave.prevent="dragLeave"
                    @drop.prevent="handleDrop"
                >
                    <input
                        type="file"
                        ref="fileInput"
                        @change="handleFiles"
                        class="hidden"
                        accept="application/pdf, text/csv"
                    />
                    <div v-if="!file">
                        <p>Drag & Drop your file or <span @click="triggerFileInput">Browse</span></p>
                        <i class="custom-icon fas fa-file-csv fa-4x"></i>
                        <i class="custom-icon fas fa-file-pdf fa-4x"></i>
                    </div>
                    <div v-else>
                        <p>{{ file.name }}</p>
                        <i v-if="file.type === 'text/csv'" class="custom-icon fas fa-file-csv fa-4x"></i>
                        <i v-if="file.type === 'application/pdf'" class="custom-icon fas fa-file-pdf fa-4x"></i>
                    </div>
                </div>

                <div class="or-separator">
                    <div class="separator-line"></div>
                    OR
                    <div class="separator-line"></div>
                </div>

                <h4><strong>Import from URL</strong></h4>
                <md-field>
                    <label>Url</label>
                    <md-input :disabled="file != null" type="url" v-model="url"></md-input>
                </md-field>

                <h4><strong>Name (Required)</strong></h4>
                <md-field>
                    <label>Name</label>
                    <md-input type="text" v-model="name" required></md-input>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="handleUpload" :disabled="!canSave">
                    <span v-if="!isLoading">Save</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-button class="md-primary custom-button" @click="showDialog = true">
            <div>
                <md-icon class="custom-button-icon">add</md-icon>
                <span>Add Knowledge</span>
            </div>
        </md-button>
    </div>
</template>

<script>
import businessKnowledgeService from '../../services/account/businessKnowledgesService';
import Swal from 'sweetalert2';

export default {
    name: 'UploadWizard',
    data: () => ({
        showDialog: false,
        file: null,
        url: '',
        name: '',

        // Style controlers
        isDraggingOver: false,
        isLoading: false,
    }),
    computed: {
        canSave() {
            // Check if either file is not null or url is not empty AND name is not empty
            return (this.file || this.url) && this.name;
        },
    },
    methods: {
        dragOver() {
            this.isDraggingOver = true;
        },
        dragEnter() {
            this.isDraggingOver = true;
        },
        dragLeave() {
            this.isDraggingOver = false;
        },
        handleDrop(event) {
            this.isDraggingOver = false;
            const files = event.dataTransfer.files;
            this.addFile(files[0]); // Only take the first file
        },
        handleFiles(event) {
            this.addFile(event.target.files[0]); // Only take the first file
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        addFile(file) {
            this.file = file;
        },

        handleClose() {
            this.url = this.name = '';
            this.file = null;
            this.showDialog = false;
            this.isLoading = false;
        },
        handleUpload() {
            this.isLoading = true;
            if (this.file != null) this.uploadFile();
            else if (this.url != '') this.uploadFromUrl();
        },
        async uploadFile() {
            try {
                const { storageURL, uuid } = await businessKnowledgeService.getStorageURL(this.file.type);

                const uploadFileToGoogleCloudResponse = await businessKnowledgeService.uploadFileToGoogleCloud(
                    storageURL,
                    this.file,
                    this.file.type,
                );

                if (uploadFileToGoogleCloudResponse.status === 200) {
                    let type = '';
                    switch (this.file.type) {
                        case 'application/pdf':
                            type = 'PDF';
                            break;
                        case 'text/csv':
                            type = 'CSV';
                            break;
                        default:
                            break;
                    }

                    const businessKnowledgeData = {
                        uuid: uuid,
                        resource_url: storageURL.split('?')[0],
                        name: this.name,
                        type: type,
                    };

                    const createBusinessKnowledgeResponse =
                        await businessKnowledgeService.createBusinessKnowledge(businessKnowledgeData);

                    if (createBusinessKnowledgeResponse.status === 201) {
                        this.isLoading = false;
                        this.notifyParent();
                        this.handleClose();
                        Swal.fire({
                            title: 'Knowledge Created',
                            text: 'Lixsa is learning, this process will take a few minutes...',
                            icon: 'success',
                            confirmButtonColor: '#4caf50',
                            confirmButtonText: 'Okay',
                            buttonsStyling: true,
                        });
                    }
                }
            } catch (error) {
                console.error('Error uploading from file: ', error);
                this.isLoading = false;
                this.handleClose();
                Swal.fire({
                    title: 'Error uploading document',
                    text: 'There was an error processing the document, try again later.',
                    icon: 'error',
                    confirmButtonColor: '#4caf50',
                    confirmButtonText: 'Okay',
                    buttonsStyling: true,
                });
            }
        },
        async uploadFromUrl() {
            try {
                const request = {
                    resource_url: this.url,
                    name: this.name,
                    type: 'WEB',
                };
                const response = await businessKnowledgeService.createBusinessKnowledge(request);
                if (response.status === 201) {
                    this.isLoading = false;
                    this.notifyParent();
                    this.handleClose();
                    Swal.fire({
                        title: 'Knowledge Created',
                        text: 'Lixsa is learning, this process will take a few minutes...',
                        icon: 'success',
                        confirmButtonColor: '#4caf50',
                        confirmButtonText: 'Okay',
                        buttonsStyling: true,
                    });
                }
            } catch (error) {
                console.error('Error uploading from url: ', error);
            }
        },

        notifyParent() {
            this.$emit('fileUploaded');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';
.md-dialog .md-dialog-container {
    max-width: 768px;
}

.custom-button {
    color: $white-color !important;

    .custom-button-icon {
        color: $white-color !important;
    }
}

.modal-content {
    background: #fff;
    padding: 30px;
    text-align: center;
    /* max-width: 500px; */
    margin: 0 auto;
}

.modal-content h4 {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}

.or-separator {
    text-align: center;
    margin: 20px 0;
}

.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s;
    margin-bottom: 30px;
    width: 50vw;
    height: 25vh;
    border-radius: 12px;
}

.dropzone.over {
    border-color: #2196f3;
}

.dropzone.disabled {
    background-color: #f0f0f0;
    pointer-events: none;
    cursor: not-allowed;
    color: #ccc;
}

.dropzone.disabled span {
    color: #ccc;
    cursor: not-allowed;
    text-decoration: none !important;
}

.hidden {
    display: none;
}

.dropzone span {
    color: #2196f3;
    cursor: pointer;
    text-decoration: underline;
}

.custom-icon {
    margin: 10px;
    color: #ccc;
}

.separator-line {
    width: 22vw;
    height: 0;
    border: thin solid #f9f9f9;
    margin: 3px;
    display: inline-block;
}
</style>
