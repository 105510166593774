// const basicState = { component: null, props: {} };
const basicState = { component: null, props: {}, onEvent: null };

const state = () => ({
    modalState: basicState,
});

const mutations = {
    SET_MODAL_STATE(state, newState) {
        state.modalState = {
            component: newState.component,
            props: newState.props || {},
            onEvent: newState.onEvent || null,
        };
    },

    CLEAN_MODAL_STATE(state) {
        state.modalState = basicState;
    },
};

const actions = {
    openModal({ commit }, payload) {
        // Get props, component, and onEvent from payload passed to function
        const { component, props, onEvent } = payload;

        // Get the body element
        const body = document.body;

        // If its there, prevent scroll from happening
        if (body) body.style.overflow = 'hidden';

        // Assign them to our state
        commit('SET_MODAL_STATE', { component, props, onEvent });
    },

    closeModal({ commit }) {
        // Reset the state
        commit('CLEAN_MODAL_STATE');

        // Get the body element
        const body = document.body;

        // If its there, reset overflow style
        if (body) body.style.overflow = 'auto';
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
