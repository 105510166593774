<template>
    <vue-advanced-chat
        class="custom-chat"
        :height="screenHeight"
        :single-room="true"
        :current-user-id="currentUserId"
        :current-user-name="currentUserName"
        :current-user-email="currentUserEmail"
        :rooms="JSON.stringify(rooms)"
        :rooms-loaded="true"
        :messages="JSON.stringify(messages)"
        :messages-loaded="messagesLoaded"
        :stateAI="stateAI"
        :showAIToggle="false"
        :enable-info-customer="enableInfoCustomer"
        :show-files="false"
        :show-audio="false"
        :show-new-messages-divider="false"
        @fetch-messages="fetchMessages()"
        @update-send-new-menssage="updateSendNewMenssage"
        @send-message="sendMessage($event)"
    />
</template>

<script>
import chatService from '../../services/chat/chatService';
import { v4 as uuidv4 } from 'uuid';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

export default {
    name: 'OnboardingChat',
    data() {
        return {
            name: 'OnboardingChat',
            currentRoomId: '',
            currentUserId: this.$store.state.user.user.uuid, // usuario propietario del chat
            currentIdCustomer: '', // usuario propietario del chat
            currentUserName: '', // usuario en la conversacion
            currentUserEmail: '',
            rooms: [],
            messages: [],
            messagesLoaded: false,
            currentChannelId: '',
            currentChannelType: '',
            lastLoadedMessage: null,
            previousLastLoadedMessage: null,
            listeners: [],

            // Lixsa
            LIXSA_ROOM_NAME: 'Lixsa',
            LIXSA_USER_ID: '4321',
            AVATAR_URL:
                'https://img.freepik.com/premium-photo/beautiful-customer-service-illustration-with-smile-generative-ai_533741-1154.jpg',

            // Socket
            stompClient: null,
            isConnected: false,
            isReconnect: false,
            messageOutputs: null,
            subsConversations: null,
            subsMessages: null,
            stateAI: false,
        };
    },
    computed: {
        screenHeight() {
            // return this.isDevice ? window.innerHeight + "px" : "calc(100vh - 80px)";
            return this.isDevice ? window.innerHeight + 'px' : '700px';
        },
    },
    methods: {
        /* AUXILIAR CHAT METHODS */

        formatTimestamp(date_created) {
            const date = new Date(date_created * 1000);
            return date.toLocaleTimeString('es-ES', { hour: 'numeric', minute: 'numeric', hour12: false });
        },

        buildMessageData(account_id, content) {
            const CHANNEL_TYPE = 'ONBOARDING';
            const CHANNEL_MESSAGE_TYPE = 'TEXT';
            const ROLE_TYPE = 'HUMAN';
            return {
                account: account_id,
                uuid: uuidv4(),
                conversation: account_id,
                channel_id: uuidv4(),
                channel_message_type: CHANNEL_MESSAGE_TYPE,
                caption: '',
                channel_type: CHANNEL_TYPE,
                content,
                role_type: ROLE_TYPE,
            };
        },
        buildRoomData(user) {
            const { account_id: roomId, uuid: userId, username } = user;

            return {
                roomId,
                roomName: this.LIXSA_ROOM_NAME,
                avatar: this.AVATAR_URL,
                users: [
                    { _id: userId, username },
                    {
                        _id: this.LIXSA_USER_ID,
                        username: this.LIXSA_ROOM_NAME,
                        status: {
                            state: 'online',
                        },
                    },
                ],
                typingUsers: [''],
            };
        },
        isStompClientConnected() {
            return this.stompClient && this.stompClient.connected;
        },

        async unsubscribeFromMessages() {
            if (this.subsMessages) {
                await this.subsMessages.unsubscribe();
            }
        },

        handleMessageOutput(messageOutput) {
            const message = this.reorderMessage(JSON.parse(messageOutput.body));
            this.processMessages(message);
        },

        handleSubscriptionError(error) {
            console.error('Error during channel subscription:', error);
        },

        /* MAIN CHAT METHODS */

        addHumanOrLixsaMessages(message) {
            const HUMAN_ROLE_TYPE = 'HUMAN';

            const { uuid: _id, content, date_created, role_type } = message;
            const user = this.$store.state.user.user;

            const timestamp = this.formatTimestamp(date_created);

            const isHuman = role_type === HUMAN_ROLE_TYPE;
            const senderId = isHuman ? user.uuid : this.LIXSA_USER_ID;
            const username = isHuman ? user.username : this.LIXSA_ROOM_NAME;

            const distributed = true; // Add double check to all user messages.

            this.rooms[0].typingUsers[0] = isHuman ? this.LIXSA_USER_ID : user.uuid; // Add "Lixsa is typing".

            return [{ _id, content, senderId, username, timestamp, distributed }];
        },

        async sendMessage(message) {
            const account_id = this.$store.state.user.user.account_id;
            const { content } = message;

            const messageData = this.buildMessageData(account_id, content);

            try {
                const response = await chatService.sendMessage(messageData);
            } catch (error) {
                console.error('Error sending message: ', error);
            }
        },

        createRoom() {
            const user = this.$store.state.user.user;

            const room = this.buildRoomData(user);

            this.rooms.push(room);
        },

        async fetchMessages() {
            try {
                const { data: messagesData } = await chatService.fetchOnboardMessages();

                if (messagesData.length === 0) {
                    var userLanguage = navigator.language || navigator.userLanguage;
                    const firstMessage = {
                        content: `Hi Lixsa! My name is ${this.$store.state.user.user.username} and I want to start the onboarding process. From now on I want you to answer always in ${userLanguage}.`,
                        files: null,
                        replyMessage: null,
                        roomId: this.currentRoomId,
                    };
                    this.sendMessage(firstMessage);
                }

                this.checkoutMessages(messagesData);
            } catch (error) {
                console.error('Error fetching onboard messages:', error);
            } finally {
                this.messagesLoaded = true;
            }
        },

        checkoutMessages(data) {
            if (!this.rooms.length) {
                console.error('No rooms available to checkout messages.');
                return;
            }

            this.currentRoomId = this.rooms[0].roomId;
            const account_id = this.$store.getters['user/user'].account_id;

            if (this.isStompClientConnected()) {
                this.subscribeToChannel(this.currentRoomId, account_id);
            } else {
                this.connectAndSubscribeToChannel(this.currentRoomId);
            }

            this.processMessages(data);
        },

        reorderMessage(messageInfo) {
            if (!messageInfo) return null;

            let parsedData;
            try {
                parsedData = JSON.parse(messageInfo.data);
            } catch (error) {
                console.error('Error parsing message data:', error);
                return null;
            }

            if (!parsedData) return null;

            const reorderedData = [
                {
                    account: parsedData.account,
                    content: parsedData.content,
                    conversation: parsedData.conversation,
                    customer_id: messageInfo.customer_id,
                    date_created: parsedData.date_created,
                    date_deleted: null,
                    date_updated: null,
                    role_type: parsedData.role_type,
                    summary: null,
                    uuid: messageInfo.id,
                },
            ];

            return reorderedData;
        },
        processMessages(data) {
            if (!data || !Array.isArray(data)) return;

            data.forEach((message) => {
                const newMessages = this.addHumanOrLixsaMessages(message);
                this.messages = this.messages.length ? [...this.messages, ...newMessages] : newMessages;
            });
        },

        /* SOCKET CONNECTION */

        async connect(token) {
            return new Promise((resolve, reject) => {
                this.stompClient = new Client({
                    webSocketFactory: () => {
                        return new SockJS(`${process.env.VUE_APP_API_URL}/ws`, {
                            transportOptions: {
                                'xhr-streaming': {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                    },
                                },
                            },
                        });
                    },
                    reconnectDelay: 1000,
                });
                this.stompClient.onConnect = (frame) => {
                    this.isConnected = true;

                    if (this.isReconnect) {
                        this.subscribeToChannel(this.currentRoomId, this.$store.getters['user/user'].account_id);
                    } else {
                        this.isReconnect = true;
                    }
                    resolve();
                };
                this.stompClient.activate();
            });
        },

        connectAndSubscribeToChannel(roomId) {
            const accountId = this.$store.getters['user/user'].account_id;

            this.$store
                .dispatch('user/apiCall', async (token) => await this.connect(token))
                .catch((error) => {
                    console.error('Error connecting the socket:', error);
                });

            if (this.isStompClientConnected()) {
                this.subscribeToChannel(roomId, accountId);
            }
        },

        async subscribeToChannel(roomId, accountId) {
            try {
                if (!this.isStompClientConnected) {
                    console.error('Socket connection not established.');
                    return;
                }

                await this.unsubscribeFromMessages();

                const channelPath = `/account/${accountId}/conversation/${roomId}`;

                this.subsMessages = await this.stompClient.subscribe(
                    channelPath,
                    this.handleMessageOutput,
                    this.handleSubscriptionError,
                );
            } catch (error) {
                console.error('Error subscribing to the channel', error);
            }
        },

        async unsubscribeToChannel() {
            try {
                if (!this.isStompClientConnected || !this.subsMessages) {
                    return;
                }

                await this.unsubscribeFromMessages();
                this.subsMessages = null;
            } catch (error) {
                console.error('Error unsubscribing from the channel:', error);
            }
        },

        disconnectSocket() {
            if (this.stompClient && this.isConnected) {
                this.stompClient.deactivate();
                this.isConnected = false;
            }
        },
    },

    async mounted() {
        try {
            this.createRoom();
            await this.$store.dispatch('user/apiCall', async (token) => {
                return await this.connect(token);
            });
        } catch (error) {
            console.error('Error establishing socket connection:', error);
        }
    },

    beforeDestroy() {
        this.unsubscribeToChannel(); // Unsubscribe from the channel
        this.disconnectSocket(); // Disconnect the socket
        this.isReconnect = false;
    },
};
</script>

<style lang="scss" scoped>
.custom-chat {
    border-radius: 12px;
    overflow: hidden;
    box-shadow:
        0 3px 3px -2px rgba(0, 0, 0, 0.2),
        0 3px 4px 0 rgba(0, 0, 0, 0.14),
        0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
