<template>
    <div v-if="selectedAsset">
        <div class="details-bar" @click="toggleShowInfoCustomer">
            <svg-icon name="arrowBackAlt" />
            <span class="details-title">Back to customer details</span>
        </div>

        <a :href="shopifyURL" target="_blank" class="details-link">
            <span class="md-subheading"
                ><strong>Order {{ selectedAsset.name }}</strong></span
            >
            <svg-icon name="open-in-new" class="details-icon-measures" />
            <md-tooltip md-direction="right">See in shopify</md-tooltip>
        </a>

        <md-divider class="section-divider"></md-divider>

        <CustomerAssetsContactInformation
            :selectedAsset="selectedAsset"
            :currentUserName="currentUserName"
            :currentUserEmail="currentUserEmail"
            :currentUserPhone="currentUserPhone"
        ></CustomerAssetsContactInformation>

        <md-divider class="section-divider"></md-divider>

        <customer-assets-summary :selected-asset="selectedAsset"></customer-assets-summary>

        <md-divider class="section-divider"></md-divider>

        <div class="details-container">
            <div class="card-details-body">
                <div v-if="selectedAsset.created_at" class="card-details-entry">
                    <div class="md-body-2">Created at</div>

                    <div class="details-badges">
                        <span class="details-created-at">{{ friendlyCreatedAt }}</span>
                    </div>
                </div>
                <div class="md-body-2">Status</div>
                <div class="details-badges">
                    <span :class="['order-badges', `badge-${selectedAsset.financial_status.toLowerCase()}`]">
                        {{ selectedAsset.financial_status.toLocaleUpperCase() }}
                    </span>
                    <span :class="['order-badges', `badge-${selectedAsset.fulfillment_status.toLowerCase()}`]">
                        {{ selectedAsset.fulfillment_status.toLocaleUpperCase() }}
                    </span>
                </div>

                <div class="details-badges" v-if="selectedAsset.note">
                    <div class="md-body-2">Notes</div>
                    <div class="card-notes-box">
                        <div class="card-notes">
                            <div class="notes-info">
                                <span>{{ selectedAsset.note }}</span>
                            </div>
                        </div>
                        <div class="order-currency"></div>
                    </div>
                </div>

                <div class="details-badges" v-if="selectedAsset.order_status_url">
                    <a :href="selectedAsset.order_status_url" target="_blank" class="details-order-status details-link">
                        See order status details
                        <svg-icon name="open-in-new" class="details-icon-measures" />
                        <md-tooltip md-direction="right">See order status details</md-tooltip>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomerAssetsContactInformation from '@/lib/CustomerDetails/CustomerCardBody/CustomerAssets/CustomerAssetsDetails/CustomerAssetsContactInformation/CustomerAssetsContactInformation.vue';
import CustomerAssetsSummary from '@/lib/CustomerDetails/CustomerCardBody/CustomerAssets/CustomerAssetsSummary/CustomerAssetsSummary.vue';
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsDetailsEcommerceOrder',
    components: {
        CustomerAssetsContactInformation,
        CustomerAssetsSummary,
        SvgIcon,
    },
    data() {
        return {
            urlOrderStatus: null,
            svgIcon: '',
            copiedFields: {
                email: false,
                phone: false,
            },
        };
    },
    props: {
        selectedAsset: { type: [Object, Array] },
        currentUserId: { type: [String, Number], required: true },
        currentUserName: { type: String, required: true },
        currentUserEmail: { type: String, required: true },
        currentUserPhone: { type: String, required: true },
        showDetailsOrder: { type: [Boolean], required: true },
    },
    mounted() {
        this.svgIcon = this.getCopyIcon();
    },
    methods: {
        getCurrencySymbol,
        formatNumberWithDecimals,
        getBadgeClass,
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order');
        },
        toggleShowInfoCustomer() {
            this.$emit('toggle-info-customer');
        },
        getCopyIcon() {
            return `
<svg xmlns="http://www.w3.org/2000/svg" height="17px" viewBox="0 -960 960 960" width="24px"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg>`;
        },
        copyCodeToClipboard(text, field) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    this.copiedFields[field] = true;
                    setTimeout(() => {
                        this.copiedFields[field] = false;
                    }, 1000);
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                });
        },
    },
    computed: {
        shopName() {
            return this.$store.getters['user/getShopName'];
        },
        shopifyURL() {
            return `https://admin.shopify.com/store/${this.shopName}/orders/${this.selectedAsset.external_asset_id}`;
        },
        friendlyCreatedAt() {
            const date = new Date(this.selectedAsset.created_at * 1000);
            const options = {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false, // Use 24-hour format
            };
            const formatter = new Intl.DateTimeFormat('default', options);
            return formatter.format(date);
        },
    },
    created() {
        this.urlOrderStatus = process.env.VUE_APP_URL_STATUS_ORDER;
    },
};
</script>
