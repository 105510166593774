<template>
    <div class="notes-container">
        <div class="notes-container-content">
            <div class="notes-header">
                <span class="notes-title">Notes</span>
            </div>
            <textarea
                rows="1"
                class="notes-input"
                @input="handleNoteChange"
                @blur="sendNoteUpdate"
                v-model="room.note"
                placeholder="Write a note for this customer"
            ></textarea>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';

import conversationsService from '../../../../services/conversations/conversationsService';

export default {
    name: 'CustomerNotes',
    components: {
        SvgIcon,
    },
    props: {
        room: { type: [Object], required: true },
    },
    methods: {
        adjustTextareaHeight(event) {
            const textarea = event.target;
            textarea.style.height = 'auto'; // Reset height to recalculate
            textarea.style.height = textarea.scrollHeight + 'px'; // Set new height
        },
        handleNoteChange(event) {
            this.adjustTextareaHeight(event);
        },
        sendNoteUpdate() {
            console.log(this.room);

            conversationsService.setConversationNotes(this.room.roomId, this.room.note);
        },
    },
};
</script>
