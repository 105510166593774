<script>
import QnAComponent from '@/components/Training/QnA/QnA.vue';

export default {
    name: 'QnATab',
    components: { QnAComponent },
};
</script>

<template>
    <QnAComponent />
</template>

<style scoped lang="scss"></style>
