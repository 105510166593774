<template>
    <md-card>
        <md-card-content>
            <div class="custom-md-card-content">
                <div class="custom-image">
                    <img :src="require(`@/assets/img/${miniature}.png`)" :alt="miniature" />
                </div>

                <div style="flex-grow: 1">
                    <div class="md-subheading">{{ title }}</div>
                    <div class="md-caption">{{ description }}</div>
                </div>
            </div>

            <div class="custom-md-card-actions">
                <!-- <md-chip class="md-primary">{{category}}</md-chip> -->
                <div class="custom-switch">
                    <!-- <span class="md-body-1">{{price}}</span> -->
                    <div>
                        <md-switch disabled v-model="switchEnabled" class="md-primary"></md-switch>
                        <md-tooltip v-if="switchEnabled">You cannot disable this skill</md-tooltip>
                        <md-tooltip v-else>You cannot enable this skill</md-tooltip>
                    </div>
                </div>
            </div>
        </md-card-content>
    </md-card>
</template>
<script>
export default {
    name: 'SkillCard',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        miniature: {
            type: String,
            default: 'skill1',
        },
        title: {
            type: String,
            default: 'Awesome Skill',
        },
        description: {
            type: String,
            default: 'Long description about what Lixsa is able to do with this awesome skill.',
        },
        price: {
            type: String,
            default: 'Free',
        },
        category: {
            type: String,
            default: 'Business',
        },
    },
    data() {
        return {
            isActive: this.active,
            miniatureURL: this.miniature,
        };
    },
    computed: {
        switchEnabled() {
            let isEnabled = true;
            if (
                this.title === 'Sales' &&
                this.$store.state.user.account.uuid !== 'e1930f4f-2133-41f8-a752-9d5926248451'
            ) {
                isEnabled = false;
            }
            return isEnabled;
        },
    },
};
</script>
<style lang="scss" scoped>
.custom-md-card-content {
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 20px;
}

.custom-image {
    flex: 0 0 70px;
    border-radius: 12px;
    overflow: hidden;

    img {
        width: 70px;
        height: auto;
        /* Keeps the aspect ratio */
    }
}

.custom-md-card-actions {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    padding: 5px 0px 0px 0px;

    .custom-switch {
        display: flex;
        align-items: center;
        gap: 10px;

        .md-switch {
            margin: 0px;
        }
    }
}

// .custom-md-card-header {
//   margin-top: 20px !important;
// }

// .custom-md-card-media {
//   img {
//     width: auto;
//     height: 100%;
//   }
// }
</style>
