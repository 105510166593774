<template>
    <div>
        <p class="md-display-1 custom-title">Welcome to Lixsa</p>
        <div class="md-caption custom-subtitle">Let lixsa take care of your onboarding process</div>
        <md-steppers :md-active-step.sync="active" md-vertical md-linear>
            <md-step
                id="first"
                md-label="Choose ecommerce platform"
                :md-editable="false"
                :md-done.sync="first"
                md-description="Select your preferred ecommerce platform"
            >
            </md-step>
            <md-step
                id="second"
                md-label="Configure account with shop data"
                :md-editable="false"
                :md-done.sync="second"
                md-description="Input relevant data to set up your shop account"
            >
            </md-step>
            <md-step
                id="third"
                md-label="Train Lixsa with shop policies"
                :md-editable="false"
                :md-done.sync="third"
                md-description="Teach Lixsa your policies"
            >
            </md-step>
            <md-step
                id="fourth"
                md-label="Upload your shop catalog"
                :md-editable="false"
                :md-done.sync="fourth"
                md-description="Teach Lixsa your catalog"
            >
            </md-step>
            <md-step
                id="fifth"
                md-label="Choose chat platform"
                :md-editable="false"
                :md-done.sync="fifth"
                md-description="Select a platform to handle customer chat interactions"
            >
            </md-step>
            <md-step
                id="sixth"
                md-label="Configure chat connection"
                :md-editable="false"
                :md-done.sync="sixth"
                md-description="Set up the link between Lixsa and your chat platform"
            >
            </md-step>
            <md-step
                id="seventh"
                md-label="Validate connection"
                :md-editable="false"
                :md-done.sync="seventh"
                md-description="Ensure the chat connection is stable and functional"
            >
            </md-step>
        </md-steppers>
        <!-- <div class="button-container">
      <md-button @click="showDialog = true" class="help-button">Help</md-button>
    </div> -->
        <md-dialog :md-active.sync="showDialog" :md-close-on-esc="false" :md-click-outside-to-close="false">
            <div class="modal-content">
                <md-icon class="md-size-2x">check_circle_outline</md-icon>
                <p class="md-title"><strong>Welcome Aboard</strong></p>
                <p class="md-body-1">
                    Congratulations! You have successfully completed the onboarding process. Lixsa has helped you set up
                    your account and you're ready to get started.
                </p>
                <md-button class="md-primary modal-button" @click="goToDashboard"
                    ><strong class="md-body-1">Go to dashboard</strong></md-button
                >
            </div>
        </md-dialog>
    </div>
</template>

<script>
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';
import { mapActions } from 'vuex';

export default {
    name: 'OnboardingInfoPanel',
    data: () => ({
        active: 'first',
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
        sixth: false,
        seventh: false,

        ecommerceSteps: {
            SHOPIFY_SELECTED: false,
            SHOPIFY_DOMAIN_UPDATED: false,
            SHOPIFY_ACCESS_TOKEN_UPDATED: false,
            ECOMMERCE_RESOURCES_UPDATED: false,
            ECOMMERCE_POLICIES_UPDATED: false,
            ECOMMERCE_CATALOG_UPDATED: false,
        },
        chatSteps: {
            WHATSAPP_SELECTED: false,
            WHATSAPP_BUSINESS_ID_UPDATED: false,
            WHATSAPP_PHONENUMBER_ID_UPDATED: false,
            WHATSAPP_LONG_TERM_ACCESS_TOKEN_UPDATED: false,
            COMPLETED: false,
        },

        // Socket
        stompClient: null,
        isConnected: false,
        isReconnect: false,
        messageOutputs: null,
        subsConversations: null,
        subsMessages: null,
        stateAI: false,

        //Message queue
        queue: [],
        isProcessing: false,

        // Final modal
        showDialog: false,
    }),
    computed: {
        shopUpdated() {
            return this.ecommerceSteps.SHOPIFY_DOMAIN_UPDATED && this.ecommerceSteps.SHOPIFY_ACCESS_TOKEN_UPDATED
                ? true
                : false;
        },
        ecommmerceUpdated() {
            return this.ecommerceSteps.ECOMMERCE_RESOURCES_UPDATED && this.ecommerceSteps.ECOMMERCE_POLICIES_UPDATED
                ? true
                : false;
        },
        whatsappUpdated() {
            return this.chatSteps.WHATSAPP_BUSINESS_ID_UPDATED &&
                this.chatSteps.WHATSAPP_PHONENUMBER_ID_UPDATED &&
                this.chatSteps.WHATSAPP_LONG_TERM_ACCESS_TOKEN_UPDATED
                ? true
                : false;
        },
    },
    methods: {
        ...mapActions('user', [
            'actionGetAccount', // Action from the 'user' module
        ]),
        async updateAccount() {
            await this.actionGetAccount();
        },
        goToDashboard() {
            this.$router.push({ name: 'Dashboard' });
        },
        showSuccessToast(current_step) {
            let msg = '';
            switch (current_step) {
                case 'SHOPIFY_SELECTED':
                    msg = 'Shopify platform has been selected successfully!';
                    break;
                case 'SHOPIFY_DOMAIN_UPDATED':
                    msg = 'Shopify domain has been updated with ease!';
                    break;
                case 'SHOPIFY_ACCESS_TOKEN_UPDATED':
                    msg = 'Access token for Shopify has been updated securely!';
                    break;
                case 'ECOMMERCE_RESOURCES_UPDATED':
                    msg = 'E-commerce resources have been updated successfully!';
                    break;
                case 'ECOMMERCE_POLICIES_UPDATED':
                    msg = 'E-commerce policies have been updated successfully!';
                    break;
                case 'ECOMMERCE_CATALOG_UPDATED':
                    msg = 'E-commerce catalog have been updated successfully!';
                    break;
                case 'WHATSAPP_SELECTED':
                    msg = 'WhatsApp platform has been selected successfully!';
                    break;
                case 'WHATSAPP_BUSINESS_ID_UPDATED':
                    msg = 'WhatsApp Business ID has been updated successfully!';
                    break;
                case 'WHATSAPP_PHONENUMBER_ID_UPDATED':
                    msg = 'WhatsApp phone number ID has been updated!';
                    break;
                case 'WHATSAPP_LONG_TERM_ACCESS_TOKEN_UPDATED':
                    msg = 'Long-term access token for WhatsApp has been secured!';
                    break;
                case 'COMPLETED':
                    msg = 'WhatsApp liveness test has been passed successfully!';
                    break;
                default:
                    msg = 'An operation was performed successfully!';
                    break;
            }
            this.$toasted.success(msg, {
                position: 'bottom-left',
                icon: 'check_circle',
                closeOnSwipe: true,
                duration: 6000,
            });
        },
        setDone(currentStep, nextStep) {
            this[currentStep] = true;

            if (nextStep) {
                this.active = nextStep;
            }
        },
        addToQueue(step) {
            this.queue.push(step);
            this.processQueue();
        },
        async processQueue() {
            if (this.isProcessing || this.queue.length === 0) return;

            this.isProcessing = true;
            const currentStep = this.queue.shift();

            await new Promise((res) => setTimeout(res, 1500));
            this.processStepEvent(currentStep);

            this.isProcessing = false;
            this.processQueue();
        },
        async processStepEvent(current_step) {
            if (current_step === undefined) return;
            if (
                this.active === 'first' ||
                this.active === 'second' ||
                this.active === 'third' ||
                this.active === 'fourth'
            ) {
                if (this.ecommerceSteps[current_step] === false) {
                    this.ecommerceSteps[current_step] = true;
                    this.updateAccount();
                    this.showSuccessToast(current_step);
                }
            } else if (this.active === 'fifth' || this.active === 'sixth' || this.active === 'seventh') {
                if (this.chatSteps[current_step] === false) {
                    this.chatSteps[current_step] = true;
                    this.updateAccount();
                    this.showSuccessToast(current_step);
                }
            }
            this.checkIfStepIsDone();
        },
        async checkIfStepIsDone() {
            switch (this.active) {
                case 'first':
                    if (this.ecommerceSteps['SHOPIFY_SELECTED']) {
                        this.setDone('first', 'second');
                    }
                    break;

                case 'second':
                    if (this.shopUpdated) {
                        this.setDone('second', 'third');
                    }
                    break;

                case 'third':
                    if (this.ecommmerceUpdated) {
                        this.setDone('third', 'fourth');
                    }
                    break;

                case 'fourth':
                    if (this.ecommerceSteps['ECOMMERCE_CATALOG_UPDATED']) {
                        this.setDone('fourth', 'fifth');
                    }
                    break;

                case 'fifth':
                    if (this.chatSteps['WHATSAPP_SELECTED'] || this.chatSteps['WHATSAPP_BUSINESS_ID_UPDATED']) {
                        this.setDone('fifth', 'sixth');
                    }
                    break;

                case 'sixth':
                    if (this.whatsappUpdated) {
                        this.setDone('sixth', 'seventh');
                    }
                    break;

                case 'seventh':
                    if (this.chatSteps['COMPLETED']) {
                        this.showDialog = true;
                        this.setDone('seventh', '');
                    }
                    break;

                default:
                    break;
            }
        },
        async checkCurrentStep() {
            const { onboarding_current_state: currentState } = this.$store.state.user.account;
            if (currentState === 'ECOMMERCE_CATALOG_UPDATED') {
                this.first = true;
                this.second = true;
                this.third = true;
                this.fourth = true;
                this.active = 'fifth';
            }
        },

        /* SOCKET CONNECTION */

        isStompClientConnected() {
            return this.stompClient && this.stompClient.connected;
        },
        async unsubscribeFromMessages() {
            if (this.subsMessages) {
                await this.subsMessages.unsubscribe();
            }
        },
        handleMessageOutput(messageOutput) {
            if (messageOutput) {
                const message = JSON.parse(messageOutput.body);
                console.log(`Evento: ${message.current_step}`);
                this.addToQueue(message.current_step);
            } else {
                console.log('no hay mensaje');
            }
        },
        handleSubscriptionError(error) {
            console.error('Error during channel subscription:', error);
        },
        async connect(token) {
            return new Promise((resolve, reject) => {
                this.stompClient = new Client({
                    webSocketFactory: () => {
                        return new SockJS(`${process.env.VUE_APP_API_URL}/ws`, {
                            transportOptions: {
                                'xhr-streaming': {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                    },
                                },
                            },
                        });
                    },
                    reconnectDelay: 1000,
                });
                this.stompClient.onConnect = (frame) => {
                    this.isConnected = true;

                    if (this.isReconnect) {
                        this.subscribeToChannel(this.$store.getters['user/user'].account_id);
                    } else {
                        this.isReconnect = true;
                    }
                    resolve();
                };
                this.stompClient.activate();
            });
        },
        connectAndSubscribeToChannel() {
            this.$store
                .dispatch('user/apiCall', async (token) => await this.connect(token))
                .catch((error) => {
                    console.error('Error connecting the socket:', error);
                });

            if (this.isStompClientConnected()) {
                this.subscribeToChannel();
            }
        },
        async subscribeToChannel() {
            try {
                const accountId = this.$store.getters['user/user'].account_id;

                if (!this.isStompClientConnected) {
                    console.error('Socket connection not established.');
                    return;
                }

                await this.unsubscribeFromMessages();

                const channelPath = `/account/${accountId}`;

                this.subsMessages = await this.stompClient.subscribe(
                    channelPath,
                    this.handleMessageOutput,
                    this.handleSubscriptionError,
                );
            } catch (error) {
                console.error('Error subscribing to the channel', error);
            }
        },

        async unsubscribeToChannel() {
            try {
                if (!this.isStompClientConnected || !this.subsMessages) {
                    return;
                }

                await this.unsubscribeFromMessages();
                this.subsMessages = null;
            } catch (error) {
                console.error('Error unsubscribing from the channel:', error);
            }
        },

        disconnectSocket() {
            if (this.stompClient && this.isConnected) {
                this.stompClient.deactivate();
                this.isConnected = false;
            }
        },
    },

    async mounted() {
        await this.$store.dispatch('user/apiCall', async (token) => {
            return await this.connect(token);
        });
        if (this.isStompClientConnected()) {
            this.subscribeToChannel();
        }
        this.checkCurrentStep();
    },

    beforeDestroy() {
        this.unsubscribeToChannel(); // Unsubscribe from the channel
        this.disconnectSocket(); // Disconnect the socket
        this.isReconnect = false;
    },
};
</script>

<style lang="scss" scoped>
.md-steppers {
    .md-stepper:after {
        margin-left: 14px;
    }
}

.substep {
    margin-left: 12px;
}

md-icon {
    transition: opacity 0.5s;
}

.modal-content {
    background: #fff;
    padding: 30px;
    text-align: center;
    max-width: 400px;
}

.md-dialog-container .md-theme-default {
    border-radius: 2% !important;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}

.custom-title {
    text-align: center;
    margin-bottom: 2px;
}

.custom-subtitle {
    text-align: center;
    margin-bottom: 30px;
}
</style>
