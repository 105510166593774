<script>
function setSidebarShowEventListener() {
    document.querySelector('.sidebar').addEventListener('transitionend', function (event) {
        if (event.propertyName === 'width') {
            // Check if the sidebar is fully expanded
            if (getComputedStyle(event.target).width === '260px') {
                document.querySelector('.sidebar-subs-panel__call-to-action').style.display = 'flex';

                const sidebarPanel = document.querySelector('.sidebar-notifications-panel');
                if (sidebarPanel) {
                    sidebarPanel.style.left = '270px';
                }
            }
        }
    });
}

function setSidebarHideEventListener() {
    document.querySelector('.sidebar').addEventListener('mouseleave', function () {
        if (this.closest('.sidebar-mini')) {
            document.querySelector('.sidebar-subs-panel__call-to-action').style.display = 'none';

            const sidebarPanel = document.querySelector('.sidebar-notifications-panel');
            if (sidebarPanel) {
                sidebarPanel.style.left = '90px';
            }
        }
    });
}

export default {
    name: 'SidebarFreePlanPanel',
    methods: {
        goToPage(route) {
            if (this.$route.path !== route) {
                this.$router.push(route);
            }
        },
    },

    mounted() {
        setSidebarShowEventListener();
        setSidebarHideEventListener();
    },
};
</script>

<template>
    <div class="sidebar-subs-panel">
        <p>FREE</p>
        <div class="sidebar-subs-panel__call-to-action">
            <div class="md-caption">You are currently in a free plan</div>
            <div class="md-caption">Upgrade to unlock all the features</div>
            <md-button
                @click="goToPage('/settings/billing')"
                class="md-primary md-sm md-block sidebar-subs-panel__call-to-action__button"
                >Upgrade</md-button
            >
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
