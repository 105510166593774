<script>
import QnAItemCard from '@/components/Training/QnA/QnAItemCard.vue';
import Swal from 'sweetalert2';
import qnaService from '@/services/qna/qnaService';
import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';

export default {
    name: 'QnAComponent',
    components: { QnAItemCard },
    data() {
        return {
            remoteQnaList: [], // Unchanged QnA list (for comparisons).
            qnaList: [], // Dynamic QnA list.
            searchedQnaList: [], // Dynamic Qna list but used when searching.
            expandedForms: [],
            searchQuery: '',

            isLoading: false,
        };
    },
    watch: {
        remoteQnaList: {
            handler(newVal) {
                if (JSON.stringify(this.qnaList) === JSON.stringify(newVal)) return;

                this.qnaList = [...this.remoteQnaList];
            },
        },

        searchQuery: {
            handler(newVal) {
                if (newVal === '') {
                    this.searchedQnaList = [];
                } else {
                    this.fetchSearchQnaItems(newVal);
                }
            },
        },
    },
    computed: {
        // Controls the current displayed list.
        displayedQnaList() {
            if (this.searchQuery === '') return this.qnaList;

            return this.searchedQnaList;
        },
    },
    methods: {
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),

        async fetchSearchQnaItems(searchTerm) {
            if (this.searchQuery === '') return;

            try {
                const response = await qnaService.searchQna(searchTerm);

                if (response.data.content.length > 0) {
                    this.searchedQnaList = [...response.data.content];
                }
            } catch (e) {
                console.error('Error fetching Qna items: ', e);
            }
        },

        async fetchQnaItems() {
            this.isLoading = true;
            try {
                const response = await qnaService.getQnas();
                if (response.data.content.length > 0) {
                    this.remoteQnaList = [...response.data.content];
                }
            } catch (e) {
                console.error('Error fetching Qna items: ', e);
            } finally {
                this.isLoading = false;
            }
        },

        async fetchCreateQnaItem(qnaItem) {
            try {
                const createData = {
                    uuid: qnaItem.uuid,
                    question: qnaItem.question,
                    answer: qnaItem.answer,
                };
                await qnaService.createQna(createData);
                this.showSuccessToast('Q&A Item created');
            } catch (e) {
                console.error('Error creating Qna item: ', e);
            } finally {
                await this.fetchQnaItems();
            }
        },

        async fetchUpdateQnaItem(qnaItem) {
            try {
                const updateData = {
                    question: qnaItem.question,
                    answer: qnaItem.answer,
                };
                await qnaService.updateQna(qnaItem.uuid, updateData);
                this.showSuccessToast('Q&A Item updated');
            } catch (e) {
                console.error('Error updating Qna item: ', e);
            }
        },

        async fetchDeleteQnaItem(qnaItem) {
            try {
                await qnaService.removeQna(qnaItem.uuid);
                this.showSuccessToast('Q&A Item removed');
            } catch (e) {
                console.error('Error deleting Qna item: ', e);
            }
        },

        isExpanded(index) {
            return this.expandedForms.includes(index);
        },

        toggleForm(index) {
            if (this.isExpanded(index)) {
                this.expandedForms = this.expandedForms.filter((item) => item !== index);
            } else {
                this.expandedForms.push(index);
            }
        },

        handleItemUpdate(qnaItem) {
            const existingRemoteQnaIndex = this.remoteQnaList.findIndex((item) => item.uuid === qnaItem.uuid);
            const existingLocalQnaIndex = this.qnaList.findIndex((item) => item.uuid === qnaItem.uuid);
            this.$set(this.qnaList, existingLocalQnaIndex, qnaItem);

            if (this.searchQuery !== '') {
                const existingSearchedQnaIndex = this.searchedQnaList.findIndex((item) => item.uuid === qnaItem.uuid);
                this.$set(this.searchedQnaList, existingSearchedQnaIndex, qnaItem);
            }

            if (existingRemoteQnaIndex !== -1) {
                this.fetchUpdateQnaItem(qnaItem);
            } else {
                this.fetchCreateQnaItem(qnaItem);
            }
        },

        handleItemDelete(index, qnaItem) {
            Swal.fire({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                showCancelButton: true,
                confirmButtonColor: '#4A25E1',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteItem(index, qnaItem);
                }
            });
        },

        deleteItem(index, qnaItem) {
            if (this.isExpanded(index)) {
                this.expandedForms = this.expandedForms.filter((item) => item !== index);
            }
            this.qnaList = this.qnaList.filter((item) => item.uuid !== qnaItem.uuid);

            if (this.searchQuery !== '') {
                this.searchedQnaList = this.searchedQnaList.filter((item) => item.uuid !== qnaItem.uuid);
            }

            const existingRemoteQnaIndex = this.remoteQnaList.findIndex((item) => item.uuid === qnaItem.uuid);
            if (existingRemoteQnaIndex !== -1) {
                this.fetchDeleteQnaItem(qnaItem);
            }
        },

        addNewItemDraft() {
            if (this.searchQuery !== '') this.searchQuery = '';

            const newItem = {
                uuid: uuidv4(),
                question: 'What would you like to ask?',
                answer: '',
            };

            this.qnaList.unshift(newItem);
            this.expandedForms = [0];
        },

        /* WEBSOCKETS */

        async subscribeToTopic() {
            try {
                await this.subscribe({
                    topic: `/account/${this.$store.state.user.user.account_id}/question-answer`,
                    callback: this.handleQnAEvent,
                });
            } catch (error) {
                console.error('Failed to subscribe to QnA topic:', error);
            }
        },

        handleQnAEvent(message) {
            if (!message || !message.data) return;

            try {
                const parsedData = JSON.parse(message.data);
                if (!parsedData) return;

                switch (message.name) {
                    case 'QuestionAnswerStartTraining':
                    case 'QuestionAnswerFinishedTraining':
                        this.updateQnaItemFromWebSocketEvent(parsedData);
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.error('Error processing QnA event:', error);
            }
        },

        updateQnaItemFromWebSocketEvent(updatedQnaItem) {
            const existingLocalQnaIndex = this.qnaList.findIndex((item) => item.uuid === updatedQnaItem.uuid);
            if (existingLocalQnaIndex !== -1) {
                this.$set(this.qnaList, existingLocalQnaIndex, updatedQnaItem);
            }

            if (this.searchQuery !== '') {
                const existingSearchedQnaIndex = this.searchedQnaList.findIndex(
                    (item) => item.uuid === updatedQnaItem.uuid,
                );

                if (existingLocalQnaIndex !== -1) {
                    this.$set(this.searchedQnaList, existingSearchedQnaIndex, updatedQnaItem);
                }
            }
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },

    async mounted() {
        await this.fetchQnaItems();
        this.subscribeToTopic();
    },

    beforeDestroy() {
        this.unsubscribe({
            topic: `/account/${this.$store.state.user.user.account_id}/question-answer`,
            callback: this.handleQnAEvent,
        });
    },
};
</script>

<template>
    <div>
        <div v-if="isLoading">
            <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
        </div>
        <div v-else>
            <div class="qna__action-bar">
                <md-field v-if="qnaList.length > 0" class="qna__search-input">
                    <md-input type="search" clearable placeholder="Search" v-model="searchQuery"> </md-input>
                </md-field>
                <div v-if="displayedQnaList.length > 0">
                    <md-button @click="addNewItemDraft" class="md-primary md-sm qna__add-button">
                        <md-icon>add</md-icon>
                        Add
                    </md-button>
                </div>
            </div>
            <md-card v-if="displayedQnaList.length > 0" class="qna__card">
                <md-card-content>
                    <QnAItemCard
                        v-for="(qnaItem, index) in displayedQnaList"
                        :qna-item="qnaItem"
                        :is-expanded="isExpanded(index)"
                        @toggle-form="toggleForm(index)"
                        @item-changed="(qnaItemUpdated) => handleItemUpdate(qnaItemUpdated)"
                        @item-deleted="(qnaItemDeleted) => handleItemDelete(index, qnaItemDeleted)"
                    />
                </md-card-content>
            </md-card>
            <div v-else>
                <md-empty-state
                    md-icon="help_outline"
                    md-label="No Questions Found"
                    md-description="Add Q&A items and improve the quality of Lixsa's answers"
                >
                    <md-button @click="addNewItemDraft" class="md-primary">Add Q&A</md-button>
                </md-empty-state>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.qna__action-bar {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 1rem;
    //margin-bottom: 1rem;

    .qna__search-input {
        width: 200px;
    }
}

.qna__card {
    margin-top: 5px;
}

.qna__add-button {
    .md-icon {
        margin-left: -10px;
    }
}
</style>
