<script>
import DiscoverySkill from '@/components/Skills/DiscoverySkill.vue';

export default {
    name: 'SkillsDiscoveryTab',
    components: { DiscoverySkill },
};
</script>

<template>
    <DiscoverySkill />
</template>
