<script>
export default {
    name: 'AuthErrorPanel',
    emits: ['close-panel'],
    props: ['errorMessage'],
    methods: {
        closePanel() {
            this.$emit('close-panel');
        },
    },
};
</script>

<template>
    <div class="auth-error-panel">
        <div class="md-body-2">{{ errorMessage }}</div>
        <md-button @click="closePanel" class="md-simple md-just-icon md-gray">
            <i class="material-symbols-outlined"> close </i>
        </md-button>
    </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/md/_variables.scss';
.auth-error-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 2rem;
    padding: 0 10px;

    border: 1px solid $brand-danger;
    border-radius: 12px;
    background-color: rgba(245, 67, 54, 0.2);
}
</style>
