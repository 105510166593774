<template>
    <div class="card-details-customer">
        <div class="card-details-location">
            <div class="card-details-row">
                <div class="card-details-orders" @click="toggleShowDetailsOrder">
                    <div class="card-details-row-icono">
                        <svg-icon class="card-icon-height" name="description" />
                    </div>
                    <div class="card-details-row-number">
                        <div v-if="order.name" class="order-title">{{ order.name }}</div>
                        <div class="card-details-badges">
                            <span
                                v-if="order.financial_status"
                                :class="['order-badges', `badge-${order.financial_status.toLowerCase()}`]"
                            >
                                {{ order.financial_status.toLocaleUpperCase() }}
                            </span>
                            <span
                                v-if="order.fulfillment_status"
                                :class="['order-badges', `badge-${order.fulfillment_status.toLowerCase()}`]"
                            >
                                {{ order.fulfillment_status.toLocaleUpperCase() }}
                            </span>
                        </div>
                    </div>
                    <div v-if="order.total_price" class="order-currency">
                        {{ formatNumberWithDecimals(order.total_price) }}
                        {{ getCurrencySymbol(order.currency) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsCardOrder',
    components: {
        SvgIcon,
    },
    props: {
        order: { type: [Object], required: true },
        showDetailsOrder: { type: [Boolean], required: true },
    },
    methods: {
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order', this.order);
        },
        getBadgeClass,
        getCurrencySymbol,
        formatNumberWithDecimals,
    },
};
</script>
