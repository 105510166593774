<template>
    <div class="card-details-customer">
        <div class="card-details-location">
            <!-- <div class="card-details-header">
                <div>
                    <img class="card-details-platform-icon" :src="getCommunicationChannelPic" />
                </div>
                <span class="md-title">{{ room.roomName }}</span>
            </div> -->

            <div class="card-details-container">
                <div class="card-details-body">
                    <div class="card-details-entry">
                        <div class="card-details-row card-customer-information-row">
                            <span class="notes-title">Customer information</span>

                            <div class="card-details-row">
                                <svg-icon
                                    class="card-details-row-icon card-icon-height satisfaction-icon"
                                    :class="getIconColor(userSatisfaction)"
                                    :name="getSatisfactionSVGCode(userSatisfaction)"
                                />
                            </div>
                        </div>
                        <div class="card-details-row-info"></div>
                    </div>

                    <div class="card-details-entry card-customer-information-row" v-if="room.roomName">
                        <div class="card-details-row">
                            <svg-icon class="card-details-row-icon card-icon-height" name="person" />
                            <div class="card-details-row-info">{{ userName }}</div>
                        </div>
                    </div>

                    <div class="card-details-entry card-customer-information-row" v-if="userEmail">
                        <div class="card-details-row">
                            <svg-icon class="card-details-row-icon card-icon-height" name="email" />
                            <div class="card-details-row-info">{{ userEmail }}</div>
                        </div>
                        <div class="card-details-row">
                            <span class="md-caption copied-caption" v-if="copiedFields.email">Email copied</span>
                            <div
                                class="card-details-row-icon card-icon-height copy-icon"
                                v-html="svgIcon"
                                @click="copyCodeToClipboard(userEmail, 'email')"
                            ></div>
                        </div>
                    </div>

                    <div class="card-details-entry card-customer-information-row">
                        <div class="card-details-row">
                            <svg-icon class="card-details-row-icon card-icon-height" name="phone" />
                            <div class="card-details-row-info">{{ userPhone }}</div>
                        </div>
                        <div class="card-details-row">
                            <span class="md-caption copied-caption" v-if="copiedFields.phone">Phone copied</span>
                            <div
                                class="card-details-row-icon card-icon-height copy-icon"
                                v-html="svgIcon"
                                @click="copyCodeToClipboard(userPhone, 'phone')"
                            ></div>
                        </div>
                    </div>

                    <div class="card-details-entry">
                        <div class="card-details-row">
                            <svg-icon class="card-details-row-icon card-icon-height" name="location" />
                            <div class="card-details-row-info">{{ userCountry }}</div>
                            <!-- <div class="card-details-row-title">Location:</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { countryCodes } from './countryCodes.js';
import { timezones } from './timezones.js';

export default {
    name: 'CustomerGeneralInfo',
    components: {
        SvgIcon,
    },
    props: {
        room: { type: Object, required: true },
        currentUserId: { type: [String, Number], required: true },
        currentUserName: { type: String, required: true },
        currentUserEmail: { type: String, required: false, default: undefined },
        currentUserPhone: { type: String, required: false, default: undefined },
        currentUserSatisfaction: { type: [String, undefined], required: true },
    },
    data() {
        return {
            currentTime: null,
            svgIcon: '',
            copiedFields: {
                email: false,
                phone: false,
            },
        };
    },
    computed: {
        userName() {
            return this.currentUserName;
        },
        userEmail() {
            return this.currentUserEmail ? this.currentUserEmail : 'Unknown';
        },
        userPhone() {
            return this.currentUserPhone ? this.currentUserPhone : 'Unknown';
        },
        userSatisfaction() {
            return this.currentUserSatisfaction;
        },
        userCountry() {
            if (!this.userPhone || this.userPhone.trim() === '') {
                return 'Unknown';
            }
            const countryCode = this.userPhone.substring(0, 3); // Adjust as needed
            return countryCodes[countryCode] || 'Unknown';
        },
        localTime() {
            if (!this.userCountry || this.userCountry === 'Unknown') {
                return 'Unavailable';
            }
            if (this.currentTime) {
                const timezone = this.getTimezoneByCountry(this.userCountry);
                const options = {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                    timeZone: timezone,
                };
                return new Intl.DateTimeFormat('en-US', options).format(this.currentTime);
            }
            return 'Loading...';
        },
        getCommunicationChannelPic() {
            let picURL = null;

            switch (this.room.channel_type) {
                case 'WHATSAPP':
                    picURL =
                        'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2FLogo-whatsapp.png?alt=media&token=94a44ade-fdad-4f57-87ad-ad4dd45a1daf';
                    break;
                default:
                    break;
            }
            return picURL;
        },
    },
    mounted() {
        this.updateTime();
        this.timer = setInterval(this.updateTime, 1000); // Update time every second
        this.svgIcon = this.getCopyIcon();
    },
    beforeDestroy() {
        clearInterval(this.timer); // Clear the timer
    },
    methods: {
        getSatisfactionSVGCode(satisfaction) {
            if (satisfaction === 'NOT ANALYZED') return 'NOT_ANALYZED';
            return satisfaction;
        },
        getIconColor(satisfaction) {
            switch (satisfaction) {
                case 'POSITIVE':
                    return 'icon-green';
                case 'NEGATIVE':
                    return 'icon-red';
                default:
                    return 'icon-basic';
            }
        },
        getTimezoneByCountry(country) {
            return timezones[country] || 'UTC';
        },
        updateTime() {
            this.currentTime = new Date(); // Update the current time
        },
        getCopyIcon() {
            return `
<svg xmlns="http://www.w3.org/2000/svg" height="17px" viewBox="0 -960 960 960" width="24px"><path d="M360-240q-33 0-56.5-23.5T280-320v-480q0-33 23.5-56.5T360-880h360q33 0 56.5 23.5T800-800v480q0 33-23.5 56.5T720-240H360Zm0-80h360v-480H360v480ZM200-80q-33 0-56.5-23.5T120-160v-560h80v560h440v80H200Zm160-240v-480 480Z"/></svg>`;
        },
        copyCodeToClipboard(text, field) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    this.copiedFields[field] = true;
                    setTimeout(() => {
                        this.copiedFields[field] = false;
                    }, 1000);
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err);
                });
        },
    },
};
</script>
