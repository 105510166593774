<template>
    <div class="summary-container">
        <div class="summary-container-content">
            <span class="md-body-2 card-details-entry">Order summary</span>
            <div class="summary-product-list details-bottom">
                <table>
                    <tbody>
                        <div v-for="(product, index) in selectedOrder.products" :key="index">
                            <tr class="summary-product">
                                <td class="product__image">
                                    <div class="summary-product-thumbnail">
                                        <div class="summary-product-thumbnail__wrapper">
                                            <img
                                                v-if="productImageUrls[`${product.product_id}-${product.variant_id}`]"
                                                :alt="product.title"
                                                class="product-thumbnail__image"
                                                :src="productImageUrls[`${product.product_id}-${product.variant_id}`]"
                                            />

                                            <md-progress-spinner
                                                v-else
                                                class="md-black thumbnail-image-loader product-thumbnail__image"
                                                :md-diameter="15"
                                                :md-stroke="2"
                                                md-mode="indeterminate"
                                            ></md-progress-spinner>
                                        </div>
                                        <span class="summary-product-thumbnail__quantity" aria-hidden="true">{{
                                            product.quantity
                                        }}</span>
                                    </div>
                                </td>
                                <th class="summary-product__description" scope="row">
                                    <span class="product__description__name order-summary__emphasis">{{
                                        product.title
                                    }}</span>
                                    <span class="product__description__variant order-summary__small-text">{{
                                        product.variant_title
                                    }}</span>
                                </th>
                                <td class="product__price">
                                    <span class="order-summary__emphasis skeleton-while-loading"
                                        >{{ formatNumberWithDecimals(product.price) }}&nbsp;{{
                                            getCurrencySymbol(selectedOrder.currency)
                                        }}</span
                                    >
                                </td>
                            </tr>
                        </div>
                    </tbody>
                </table>
            </div>
            <div class="summary-total-lines">
                <div class="summary-row">
                    <div class="summary-subtotal">Subtotal</div>
                    <div class="summary-value">
                        {{ formatNumberWithDecimals(subtotal) }}
                        {{ getCurrencySymbol(selectedOrder.currency) }}
                    </div>
                </div>
                <div class="summary-row">
                    <div class="summary-subtotal">Discount</div>
                    <div class="summary-value">
                        {{ formatNumberWithDecimals(selectedOrder.total_discount) }}&nbsp;{{
                            getCurrencySymbol(selectedOrder.currency)
                        }}
                    </div>
                </div>
                <!-- <div class="summary-row">
          <div class="summary-subtotal">Shipping</div>
          <div class="summary-value">0,00 €</div>
        </div>
        <div class="summary-row">
          <div class="summary-subtotal">Tax</div>
          <div class="summary-value">0,00 €</div>
        </div> -->
                <div class="summary-row">
                    <div class="summary-subtotal summary-bold">Total</div>
                    <div class="summary-value summary-bold">
                        {{ formatNumberWithDecimals(selectedOrder.total_price) }}&nbsp;{{
                            getCurrencySymbol(selectedOrder.currency)
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { formatNumberWithDecimals, getCurrencySymbol } from '@/utils/common.js';
import axios from 'axios';
import { mapActions } from 'vuex';

import conversationsService from '../../../../../../services/conversations/conversationsService.js';

export default {
    name: 'CustomerOrderSummaryEcommerceOrder',
    data() {
        return {
            URL: '',
            productImageUrls: {},
        };
    },
    props: {
        selectedOrder: { type: Object },
    },
    computed: {
        // Calcular el subtotal sumando los precios de los productos
        subtotal() {
            return this.selectedOrder.products.reduce((total, product) => total + product.price * product.quantity, 0);
        },
    },
    methods: {
        getCurrencySymbol,
        formatNumberWithDecimals,
        ...mapActions('user', ['apiCall']),
        async fetchProductImage(product) {
            try {
                const productId = parseInt(product);
                const response = await conversationsService.getProductImage(productId);
                return response.data.images;
            } catch (error) {
                return null;
            }
        },
        findSrcByVariantId(images, variantId) {
            const productWithVariantId = images.find((product) => product.variant_ids.includes(variantId));

            if (productWithVariantId) {
                return productWithVariantId.src;
            } else {
                return images[0].src;
            }
        },
        async loadProductImages() {
            const imageFetchPromises = this.selectedOrder.products.map(async (product) => {
                const key = `${product.product_id}-${product.variant_id}`;
                if (!this.productImageUrls[key]) {
                    const images = await this.fetchProductImage(product.product_id);
                    if (images) {
                        const url = this.findSrcByVariantId(images, product.variant_id);
                        const modifiedUrl = this.modifyImageUrlForThumbnail(url);
                        return { key, modifiedUrl };
                    }
                }
                return null;
            });

            const results = await Promise.all(imageFetchPromises);
            results.forEach((result) => {
                if (result) {
                    this.$set(this.productImageUrls, result.key, result.modifiedUrl);
                }
            });
        },
        modifyImageUrlForThumbnail(url) {
            if (url.includes('?')) {
                return `${url}&width=100`;
            } else {
                return `${url}?width=100`;
            }
        },
    },
    async created() {
        this.URL = process.env.VUE_APP_API_URL;
        await this.loadProductImages();
    },
};
</script>
