// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    sendPasswordResetEmail,
    signOut,
} from 'firebase/auth';
// import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// console.log("process.env", process.env);
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE,
    authDomain: process.env.VUE_APP_AUTHDOMAIND,
    projectId: process.env.VUE_APP_PROJECTID,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPID,
    // measurementId: process.env.VUE_APP_MEASUREMENTID,
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
initializeApp(firebaseConfig);

export default {
    async signInWithGoogle() {
        try {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            return await signInWithPopup(auth, provider);
        } catch (error) {
            throw error;
        }
    },

    async signInWithFacebook() {
        try {
            const provider = new FacebookAuthProvider();
            const auth = getAuth();
            return await signInWithPopup(auth, provider);
        } catch (error) {
            throw error;
        }
    },

    async SignOut() {
        try {
            const auth = getAuth();
            return await signOut(auth);
        } catch (error) {
            console.error('Error en el servicio de firebae: ', error);
            throw error;
        }
    },

    async sendPasswordResetEmail(email) {
        try {
            const auth = getAuth();
            return await sendPasswordResetEmail(auth, email);
        } catch (error) {
            throw error;
        }
    },
};
