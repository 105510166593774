<template>
    <div class="details-container">
        <div class="card-details-entry">
            <span class="md-body-2">Booking summary</span>
        </div>

        <div class="card-details-entry">
            <div class="card-details-row">
                <svg-icon class="card-details-row-icon card-icon-height" name="shedule" />
                <div class="card-details-row-title md-body-2">Date:</div>
                <div class="card-details-booking-value">{{ friendlyBookingDate }}</div>
            </div>

            <div class="card-details-row" v-if="selectedAsset.amount_guests">
                <svg-icon class="card-details-row-icon card-icon-height" name="people" />
                <div class="card-details-row-title md-body-2">Guests:</div>
                <div class="card-details-booking-value">{{ selectedAsset.amount_guests }}</div>
            </div>

            <div class="card-details-row" v-if="selectedAsset.phone">
                <svg-icon class="card-details-row-icon card-icon-height" name="children" />
                <div class="card-details-row-title md-body-2">Children:</div>
                <div class="card-details-booking-value">{{ selectedAsset.amount_children }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import moment from 'moment';

export default {
    name: 'CustomerOrderSummaryHostelryBooking',
    components: {
        SvgIcon,
    },
    props: {
        selectedAsset: { type: Object },
    },
    computed: {
        friendlyBookingDate() {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(this.selectedAsset.booking_date); // convert to milliseconds
            return date.toLocaleDateString('en-US', options); // adjust 'en-US' as per your need
        },
    },
};
</script>
