<script>
import MySkills from '@/components/Skills/MySkills.vue';

export default {
    name: 'SkillsMySkillsTab',
    components: { MySkills: MySkills },
};
</script>

<template>
    <MySkills />
</template>
