<template>
    <div>
        <AuthLayout>
            <Login />
        </AuthLayout>
    </div>
</template>
<script>
import AuthLayout from '../../layouts/LoginOrSignupLayout.vue';
import Login from '../../components/Auth/Login/Login.vue';

export default {
    name: 'login',
    components: { AuthLayout, Login },
    data() {
        return {
            firstname: null,
            email: null,
            password: null,
        };
    },
};
</script>
