<script>
import DynamicCatalogCreateItem from '@/components/Training/Catalog/CatalogCreateItem.vue';
import DynamicCatalogSchemaEditor from '@/components/Training/Catalog/CatalogSchemaEditor.vue';
import Swal from 'sweetalert2';
import CatalogServiceV2 from '@/services/productCatalog/CatalogServiceV2';
import { mapActions } from 'vuex';

export default {
    name: 'CatalogActionBar',
    // components: { DynamicCatalogCreateItemForm },
    emits: ['item-created', 'bulk-delete-completed'],
    methods: {
        ...mapActions('modalStore', ['openModal']),

        openEditSchemaModal() {
            this.openModal({ component: DynamicCatalogSchemaEditor });
        },

        openCreateItemModal() {
            this.openModal({ component: DynamicCatalogCreateItem });
        },

        handleBulkDelete() {
            Swal.fire({
                title: 'Are you sure?',
                text: `This action will remove all the items in your Lixsa catalog. You won't be able to revert this!`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4A25E1',
                confirmButtonText: 'Yes, delete all!',
                buttonsStyling: true,
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    try {
                        await this.bulkDelete();
                    } catch (error) {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    }
                },
                allowOutsideClick: () => !Swal.isLoading(),
            }).then((result) => {
                if (result.isConfirmed) {
                    this.notifyParentBulkDeleteIsCompleted();
                    this.showSuccessToast('All Items deleted');
                }
            });
        },

        async bulkDelete() {
            try {
                await CatalogServiceV2.bulkDeleteItems();
            } catch (error) {
                throw error;
            }
        },

        async startTraining() {
            try {
                await CatalogServiceV2.startTraining();
                this.showSuccessToast('Starting Training');
            } catch (error) {
                this.showErrorToast('Error starting Training');
                console.error('Error starting Training: ', error);
            }
        },

        notifyParentBulkDeleteIsCompleted() {
            this.$emit('bulk-delete-completed');
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<template>
    <div class="dc__action-bar">
        <md-button @click="openEditSchemaModal" class="md-gray md-outline md-sm"> Edit Schema </md-button>
        <md-button @click="startTraining" class="md-gray md-outline md-sm">Train Catalog </md-button>
        <md-button @click="handleBulkDelete" class="md-danger md-outline md-sm">Bulk Delete </md-button>
        <md-button class="md-primary md-sm dc__add-button" @click="openCreateItemModal">
            <div class="md-button-content">
                <md-icon>add</md-icon>
                Add
            </div>
        </md-button>
        <!--    <DynamicCatalogCreateItemForm @item-created="$emit('item-created')"/>-->
    </div>
</template>

<style scoped lang="scss">
.dc__action-bar {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.dc__add-button {
    .md-icon {
        margin-left: -10px;
    }
}
</style>
