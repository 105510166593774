// stripeCustomerDto.js

import stripeSubscriptionDto from './stripeSubscriptionDto';

export default {
    id: '',
    object: '',
    address: null,
    balance: 0,
    created: 0,
    currency: null,
    default_source: null,
    delinquent: false,
    description: null,
    discount: null,
    email: '',
    invoice_prefix: '',
    invoice_settings: {
        custom_fields: null,
        default_payment_method: null,
        footer: null,
        rendering_options: null,
    },
    livemode: false,
    metadata: {
        account_id: '',
    },
    name: null,
    phone: null,
    preferred_locales: [],
    shipping: null,
    subscriptions: {
        object: '',
        data: [stripeSubscriptionDto],
        has_more: false,
        total_count: 0,
        url: '',
    },
    tax_exempt: '',
    test_clock: null,
};
