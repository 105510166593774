<template>
    <div class="custom-tabs">
        <div class="tabs-header">
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                :class="['tab', { active: activeTab === index }]"
                @click="selectTab(index, tab)"
            >
                <span class="md-body-2">{{ tab.label }}</span>
                <i class="badge" v-if="tab.data.badge">{{ tab.data.badge }}</i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabs: {
            type: Array,
            required: true,
        },
        initialActiveTab: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            activeTab: this.initialActiveTab,
        };
    },
    methods: {
        selectTab(index, tab) {
            this.activeTab = index;
            this.$emit('tab-changed', tab);
        },
    },
};
</script>

<style scoped>
.custom-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tabs-header {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 100%;
}
.tab {
    padding: 5px 20px;
    cursor: pointer;
    text-align: center;
    position: relative;
}
.tab.active {
    font-weight: bold;
    border-radius: 12px;
    background-color: #f0f0f0;
}
.tab:hover {
    border-radius: 12px;
    background-color: #f0f0f0;
}
.tabs-content {
    padding: 10px;
    width: 100%;
    text-align: center;
}
.badge {
    margin-left: 5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
}
</style>
