<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div class="table-panel md-layout-item md-size-100">
                <DynamicCatalog />
            </div>
        </div>
    </div>
</template>

<script>
// Components
import { Pagination } from '@/components';
import DynamicCatalog from '@/components/Training/Catalog/Catalog.vue';

export default {
    name: 'CatalogPage',
    components: {
        DynamicCatalog,
        Pagination,
    },
    data() {
        return {
            isLoading: false, //Controls loading spinner.
            isSyncing: false,
            isTraining: false,
            isDeleting: false,
        };
    },
    methods: {
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
    mounted() {},
    watch: {},
};
</script>

<style lang="scss" scoped>
//.md-layout-item {
//  padding-left: 0px;
//  padding-right: 0px;
//}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
    margin-top: 10px;
}

.custom-content-panel {
    //margin-bottom: 40px;
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 1278px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: 959px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}
</style>
