<script>
import DashboardComponent from '@/components/Dashboard/Dashboard.vue';

export default {
    name: 'Dashboard',
    components: {
        DashboardComponent,
    },
};
</script>

<template>
    <DashboardComponent />
</template>
