<template>
    <div>
        <md-dialog :md-active.sync="showDialog" :md-close-on-esc="false" :md-click-outside-to-close="false">
            <md-dialog-title class="md-headline">
                Create Training Scenario
                <span>
                    <md-icon>help_outline</md-icon>
                    <md-tooltip md-direction="bottom">
                        <p class="md-body-1">AI Training Scenario</p>
                        <p class="md-caption">
                            Help improve Lixsa accuracy by detailing where it goes wrong and what the right response
                            should be.
                        </p>
                        <p class="md-caption">
                            - <strong>Context:</strong> Provide the scenario or background relevant to the Lixsa's
                            response.
                        </p>
                        <p class="md-caption">
                            - <strong>Wrong Answer:</strong> Enter the incorrect or inappropriate response given by
                            Lixsa.
                        </p>
                        <p class="md-caption">
                            - <strong>Correct Answer:</strong> Provide the accurate or more suitable answer for the
                            given context.
                        </p>
                    </md-tooltip>
                </span>
            </md-dialog-title>
            <div class="modal-content">
                <p class="md-subheading">Context</p>
                <md-field>
                    <md-textarea md-autogrow type="text" v-model="localContext" required></md-textarea>
                </md-field>

                <p class="md-subheading">Wrong Answer</p>
                <md-field>
                    <md-textarea disabled md-autogrow type="text" v-model="localWrongAnswer" required></md-textarea>
                </md-field>

                <p class="md-subheading">Correct Answer</p>
                <md-field>
                    <md-textarea md-autogrow type="text" v-model="localCorrectAnswer" required></md-textarea>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="createScenario" :disabled="!canSave">
                    <span v-if="!isLoading">Create</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import fineTuningService from '@/services/fineTuning/fineTuningService.js';

export default {
    name: 'GenerateScenarioWizard',
    props: {
        context: {
            type: String,
            default: '',
        },
        correctAnswer: {
            type: String,
            default: '',
        },
        wrongAnswer: {
            type: String,
            default: '',
        },
        message: {
            type: Object,
            default: () => ({}),
        },
        showDialog: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        context: {
            deep: true,
            handler(newValue) {
                this.localContext = newValue;
            },
        },
        correctAnswer: {
            deep: true,
            handler(newValue) {
                this.localCorrectAnswer = newValue;
            },
        },
        wrongAnswer: {
            deep: true,
            handler(newValue) {
                this.localWrongAnswer = newValue;
            },
        },
        message: {
            deep: true,
            handler(newValue) {
                this.localMessage = JSON.parse(JSON.stringify(newValue));
            },
        },
    },
    data: () => ({
        localContext: '',
        localCorrectAnswer: '',
        localWrongAnswer: '',
        localMessage: {},

        isLoading: false,
    }),
    computed: {
        canSave() {
            // Check if either file is not null or url is not empty AND name is not empty
            return this.localContext && this.localCorrectAnswer;
        },
    },
    methods: {
        handleClose() {
            this.cleanData();
            this.notifyParentDialogClosed();
        },

        cleanData() {
            this.localContext = this.localCorrectAnswer = this.localWrongAnswer = '';
            this.localMessage = {};
            this.isLoading = false;
        },

        async createScenario() {
            this.isLoading = true;
            try {
                this.removeExistingScenario();
                const createData = {
                    context: this.localContext,
                    wrong_answer: this.localWrongAnswer === '' ? null : this.localWrongAnswer,
                    good_answer: this.localCorrectAnswer,
                    conversation_id: this.localMessage.conversation,
                    message_id: this.localMessage._id,
                };
                const { response, requestData: scenario } =
                    await fineTuningService.createFineTuningScenario(createData);

                if (response.status === 201) {
                    this.isLoading = false;
                    this.updateMessageValoration();
                    this.updateMessageScenarioId(scenario);
                    this.notifyParentScenarioCreated();
                    this.cleanData();
                    this.showSuccessToast('Scenario created');
                }
            } catch (error) {
                console.error('Error creating scenario: ', error);
                this.showErrorToast('Error creating scenario');
            } finally {
                this.isLoading = false;
            }
        },

        async removeExistingScenario() {
            if (this.localMessage.scenario_id === null) return; // No existing scenario.

            try {
                await fineTuningService.deleteFineTuningScenario(this.localMessage.scenario_id);
            } catch (error) {
                console.error('Error removing existing scenario: ', error);
            }
        },

        updateMessageValoration() {
            this.localMessage.valoration = this.localWrongAnswer === '' ? 'LIKE' : 'DISLIKE';
        },

        updateMessageScenarioId(scenario) {
            this.localMessage.scenario_id = scenario.uuid;
        },

        notifyParentScenarioCreated() {
            this.$emit('ScenarioCreated', this.localMessage);
        },

        notifyParentDialogClosed() {
            this.$emit('DialogClosed');
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
    created() {
        this.localContext = this.context;
        this.localCorrectAnswer = this.correctAnswer;
        this.localWrongAnswer = this.wrongAnswer;
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-dialog .md-dialog-container {
    max-width: 768px;
}

.custom-button {
    color: $white-color !important;

    .custom-button-icon {
        color: $white-color !important;
    }
}

.modal-content {
    background: #fff;
    padding: 0px 30px 60px 30px;
    text-align: center;
    /* max-width: 500px; */
    margin: 0 auto;
    width: 500px;

    @media (max-width: 959px) {
        width: auto;
        padding: 0px;
        margin: 0px 15px;
    }
}

.modal-content p {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}

.md-tooltip {
    border-radius: 12px;
    text-align: left;
}
</style>
