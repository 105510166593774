<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="showDialog">
            <md-dialog-title><strong>Edit Product</strong></md-dialog-title>
            <div class="modal-content">
                <md-field>
                    <label>Name</label>
                    <md-input type="text" v-model="localProduct.name"></md-input>
                </md-field>
                <md-field>
                    <label>Description</label>
                    <md-textarea md-autogrow type="text" v-model="localProduct.description"></md-textarea>
                </md-field>
                <md-field v-for="(attribute, index) in localProduct.metadata" :key="index">
                    <label>{{ attribute.attribute_name }}</label>
                    <md-input :name="attribute.attributeName" v-model="attribute.attribute_value"></md-input>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="handleEdit" :disabled="!canSave">
                    <span v-if="!isLoading">Save</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-button class="md-just-icon md-info md-simple" @click="showDialog = true">
            <i class="material-symbols-outlined">edit</i>
            <md-tooltip md-direction="bottom">Edit product</md-tooltip>
        </md-button>
    </div>
</template>

<script>
import CatalogServiceV2 from '@/services/productCatalog/CatalogServiceV2';

export default {
    name: 'CatalogUpdateItemForm',
    emits: ['item-edited'],
    props: {
        product: {
            type: Object,
            default: () => ({}),
        },
    },
    watch: {
        // Create a local copy avoiding double binding with parent's object.
        product: {
            deep: true,
            handler(newValue) {
                this.localProduct = JSON.parse(JSON.stringify(newValue));
            },
        },
    },
    data: () => ({
        showDialog: false,
        localProduct: {},
        isLoading: false,
    }),
    computed: {
        isEdited() {
            return JSON.stringify(this.localProduct) !== JSON.stringify(this.product);
        },
        canSave() {
            return this.isEdited && this.localProduct.description && this.localProduct.name;
        },
    },
    created() {
        this.localProduct = JSON.parse(JSON.stringify(this.product));
    },
    methods: {
        handleClose() {
            this.localProduct = JSON.parse(JSON.stringify(this.product));
            this.showDialog = false;
            this.isLoading = false;
        },
        async handleEdit() {
            this.isLoading = true;

            try {
                const reqData = {
                    name: this.localProduct.name,
                    description: this.localProduct.description,
                    metadata: [...this.localProduct.metadata],
                };

                const createproductResponse = await CatalogServiceV2.updateItem(this.localProduct.uuid, reqData);
                if (createproductResponse.status === 200) {
                    this.showSuccessToast('Product updated');
                    this.isLoading = false;
                    this.notifyParent();
                    this.showDialog = false;
                }
            } catch (error) {
                console.error('Error updatind product: ' + error);
                this.showErrorToast('Error updatind product');
                this.isLoading = false;
                this.handleClose();
            }
        },
        notifyParent() {
            this.$emit('item-edited');
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/variables';

.md-dialog .md-dialog-container {
    max-width: 768px;
}

.modal-content {
    background: #fff;
    padding: 30px;
    padding-top: 0;
    margin: 0 auto;
    width: 60vw;
    max-height: 70vh; /* Set a maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */

    p {
        margin-bottom: 40px;
    }

    @media (max-width: 599px) {
        width: 100%;
        height: 100%;
        padding: 15px;
        margin: 0;
    }
}

.modal-content h4 {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}

.custom-copy-button {
    margin-left: 16px;
}

.custom-button {
    color: $black-color !important;

    .custom-button-icon {
        color: $black-color !important;
    }
}
</style>
