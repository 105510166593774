<script>
export default {
    name: 'TheLinkExpired',
};
</script>

<template>
    <div class="link-expired">
        <img
            src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Flink-expired.png?alt=media&token=67885332-f1a8-4ba6-821f-9db78324b8d4"
            alt="Link Expired"
            class="link-expired__image"
        />
        <h1 class="md-display-1">This Link Has Expired</h1>
        <p class="md-subheading">
            The invitation link is no longer valid. Please contact the admin of the organization to resend the
            invitation.
        </p>
        <md-button class="md-primary" to="/">Back to Home</md-button>
    </div>
</template>

<style scoped lang="scss">
.link-expired {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

.link-expired__image {
    max-width: 10%;
    margin-bottom: 1rem;
}
</style>
