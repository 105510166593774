<script>
import { isEqual } from 'lodash';

export default {
    name: 'QnAItemCard',
    props: {
        qnaItem: {
            type: Object,
            required: true,
        },
        isExpanded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localQnaItem: {},
        };
    },
    emits: ['toggle-form', 'item-changed', 'item-deleted'],
    watch: {
        qnaItem(newValue) {
            this.localQnaItem = JSON.parse(JSON.stringify(newValue));
        },
    },
    computed: {
        isEdited() {
            return !isEqual(this.qnaItem, this.localQnaItem);
        },

        qnaItemStatusIsTrained() {
            return this.qnaItem.training_status === 'TRAINED';
        },

        qnaItemStatusIsPending() {
            return this.qnaItem.training_status === 'PENDING';
        },

        qnaItemStatusIsError() {
            return this.qnaItem.training_status === 'ERROR';
        },

        qnaItemStatusIsNotTrained() {
            return this.qnaItem.training_status === 'NOT_TRAINED';
        },
    },
    methods: {
        toggle() {
            this.$emit('toggle-form');
        },

        updateItem() {
            this.$emit('item-changed', this.localQnaItem);
        },

        deleteItem() {
            this.$emit('item-deleted', this.localQnaItem);
        },
    },
    beforeMount() {
        this.localQnaItem = JSON.parse(JSON.stringify(this.qnaItem));
    },
};
</script>

<template>
    <div class="qna-item-card">
        <div class="qna-item-card__header" @click="toggle">
            <div class="md-subheading">{{ qnaItem.question }}</div>
            <div class="qna-item-card__header-actions">
                <md-button v-if="isEdited" @click="updateItem" class="md-sm md-primary">Save</md-button>
                <md-button v-if="qnaItemStatusIsPending" @click.stop class="md-just-icon md-simple md-primary">
                    <i class="material-symbols-outlined qna-item-card__sync-icon"> sync </i>
                    <md-tooltip md-direction="bottom">Syncing item</md-tooltip>
                </md-button>
                <md-button
                    v-else-if="qnaItemStatusIsError || qnaItemStatusIsNotTrained"
                    @click.stop
                    class="md-just-icon md-simple md-danger"
                >
                    <i class="material-symbols-outlined"> error </i>
                    <md-tooltip md-direction="bottom">Error syncing item. <strong>Click to retry</strong></md-tooltip>
                </md-button>
                <md-button @click.stop="deleteItem" class="md-just-icon md-danger md-simple">
                    <i class="material-symbols-outlined"> delete </i>
                </md-button>
            </div>
        </div>

        <transition name="slide">
            <div v-if="isExpanded" class="qna-item-card__item-form">
                <md-card>
                    <md-card-content>
                        <md-field>
                            <label>Question</label>
                            <md-textarea md-autogrow v-model="localQnaItem.question"></md-textarea>
                        </md-field>
                    </md-card-content>
                </md-card>
                <md-card>
                    <md-card-content>
                        <md-field>
                            <label>Answer</label>
                            <md-textarea md-autogrow v-model="localQnaItem.answer"></md-textarea>
                        </md-field>
                    </md-card-content>
                </md-card>
            </div>
        </transition>
    </div>
</template>

<style scoped lang="scss">
.qna-item-card {
    border: 1px #dddddd solid;
    margin-bottom: 1rem;
    overflow: hidden;
    background-color: #fafafa;
    border-radius: 12px;

    @media (max-width: 959px) {
        flex-direction: column;
    }
}

.qna-item-card__header {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }

    .qna-item-card__header-actions {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.qna-item-card__item-form {
    padding: 0 1rem;
    overflow: hidden;
}

.qna-item-card__sync-icon {
    animation: spin-animation 0.8s infinite;
    display: inline-block;
}

@keyframes spin-animation {
    0% {
        transform: rotate(359deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.slide-enter-active,
.slide-leave-active {
    transition:
        opacity 0.3s,
        max-height 0.5s ease-in-out -0.2s; /* delay max-height slightly */
    max-height: 1000px; // Adjust to suit the maximum content height
}

.slide-enter,
.slide-leave-to {
    max-height: 0;
    opacity: 0;
}
</style>
