<template>
    <div class="user">
        <div class="photo">
            <img :src="avatar" alt="avatar" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" :aria-expanded="!isClosed" @click.stop="toggleMenu" @click.capture="clicked">
                <span v-if="$route.meta.rtlActive">
                    {{ rtlTitle }}
                    <b class="caret"></b>
                </span>
                <span v-else>
                    {{ title }}
                    <b class="caret"></b>
                </span>
            </a>

            <collapse-transition>
                <div v-show="!isClosed">
                    <ul class="nav custom-list">
                        <slot>
                            <li>
                                <a href="#/profile-settings">
                                    <md-icon>assignment_ind</md-icon>
                                    <p class="sidebar-normal">User Profile</p>
                                </a>
                            </li>
                            <li>
                                <a href="#/account-settings">
                                    <md-icon>business</md-icon>
                                    <p class="sidebar-normal">Account</p>
                                </a>
                            </li>
                            <li>
                                <a href="#/communication-channels">
                                    <md-icon>electrical_services</md-icon>
                                    <p class="sidebar-normal">Communication Channels</p>
                                </a>
                            </li>
                            <li>
                                <a href="#/api-keys">
                                    <md-icon>vpn_key</md-icon>
                                    <p class="sidebar-normal">API Keys</p>
                                </a>
                            </li>
                            <li>
                                <a href="#/members">
                                    <md-icon>people</md-icon>
                                    <p class="sidebar-normal">Members</p>
                                </a>
                            </li>
                        </slot>
                    </ul>
                </div>
            </collapse-transition>
        </div>
    </div>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';

export default {
    components: {
        CollapseTransition,
    },
    props: {
        title: {
            type: String,
            default: 'Plumens.com',
        },
        rtlTitle: {
            type: String,
            default: 'تانيا أندرو',
        },
        avatar: {
            type: String,
            default: './img/faces/avatar.jpg',
        },
    },
    data() {
        return {
            isClosed: true,
        };
    },
    methods: {
        clicked: function (e) {
            e.preventDefault();
        },
        toggleMenu: function () {
            this.isClosed = !this.isClosed;
        },
    },
};
</script>
<style>
.collapsed {
    transition: opacity 1s;
}
.custom-list {
    list-style-type: none;
}
</style>
