export default [
    {
        active: true,
        miniature: 'skill1',
        title: 'Order Management',
        description: 'Managing, tracking, and updating customer orders.',
        price: 'Free',
        category: 'Order Management',
    },
    {
        active: true,
        miniature: 'skill3',
        title: 'Order Processing',
        description: 'Confirming and fulfilling customer orders efficiently.',
        price: 'Free',
        category: 'Order Processing',
    },
    {
        active: true,
        miniature: 'skill6',
        title: 'Order Review',
        description: 'Detailed insights and analysis of individual orders.',
        price: 'Free',
        category: 'Order Review',
    },
    {
        active: true,
        miniature: 'skill11',
        title: 'Customer Support',
        description: 'Enhancing customer service and issue resolution.',
        price: 'Free',
        category: 'Customer Support',
    },
    {
        active: true,
        miniature: 'skill8',
        title: 'Product Management',
        description: 'Managing and organizing product catalogs.',
        price: 'Free',
        category: 'Product Management',
    },
    {
        active: true,
        miniature: 'skill10',
        title: 'Marketing',
        description: 'Handling promotional activities and customer engagement.',
        price: 'Free',
        category: 'Marketing',
    },
    {
        active: true,
        miniature: 'skill7',
        title: 'Sales',
        description: 'Tools and strategies to boost sales and track performance.',
        price: 'Free',
        category: 'Sales',
    },
];
