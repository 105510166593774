// timezones.js
export const timezones = {
    Spain: 'Europe/Madrid',
    'United States': 'America/New_York',
    'United Kingdom': 'Europe/London',
    India: 'Asia/Kolkata',
    Japan: 'Asia/Tokyo',
    Australia: 'Australia/Sydney',
    Brazil: 'America/Sao_Paulo',
    China: 'Asia/Shanghai',
    Russia: 'Europe/Moscow',
    Argentina: 'America/Buenos_Aires',
    Bolivia: 'America/La_Paz',
    Chile: 'America/Santiago',
    Colombia: 'America/Bogota',
    Ecuador: 'America/Guayaquil',
    Guyana: 'America/Guyana',
    Paraguay: 'America/Asuncion',
    Peru: 'America/Lima',
    Suriname: 'America/Paramaribo',
    Uruguay: 'America/Montevideo',
    Venezuela: 'America/Caracas',
    Austria: 'Europe/Vienna',
    Belgium: 'Europe/Brussels',
    Bulgaria: 'Europe/Sofia',
    Cyprus: 'Asia/Nicosia',
    'Czech Republic': 'Europe/Prague',
    Denmark: 'Europe/Copenhagen',
    Estonia: 'Europe/Tallinn',
    Finland: 'Europe/Helsinki',
    France: 'Europe/Paris',
    Germany: 'Europe/Berlin',
    Greece: 'Europe/Athens',
    Hungary: 'Europe/Budapest',
    Ireland: 'Europe/Dublin',
    Italy: 'Europe/Rome',
    Latvia: 'Europe/Riga',
    Lithuania: 'Europe/Vilnius',
    Luxembourg: 'Europe/Luxembourg',
    Malta: 'Europe/Malta',
    Netherlands: 'Europe/Amsterdam',
    Poland: 'Europe/Warsaw',
    Portugal: 'Europe/Lisbon',
    Romania: 'Europe/Bucharest',
    Slovakia: 'Europe/Bratislava',
    Slovenia: 'Europe/Ljubljana',
    Sweden: 'Europe/Stockholm',
    Singapore: 'Asia/Singapore',
    Israel: 'Asia/Jerusalem',
};
