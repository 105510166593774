<script>
import SkillDetails from '@/components/Skills/SkillDetails.vue';

export default {
    name: 'SkillsDetailsPage',
    components: { SkillDetails },
};
</script>

<template>
    <SkillDetails />
</template>
