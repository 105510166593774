<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay"></div>
            <div class="md-layout-item md-size-100">
                <!-- <p class="md-headline">Connect Lixsa with your communication channels</p> -->
                <div class="md-subheading">
                    Seamlessly integrate Lixsa with your existing communication platforms to enhance customer service
                    experiences. By linking, Lixsa effectively manages and responds to customer inquiries across all
                    your channels. For integration details and instructions, click on the desired channel.
                </div>
            </div>
            <div class="custom-tool-bar md-layout-item md-size-100">
                <div class="custom-filters">
                    <md-button
                        class="custom-tab-button"
                        :class="{ 'active-filter': currentFilter === 'all' }"
                        @click="currentFilter = 'all'"
                    >
                        All channels
                    </md-button>
                    <md-button
                        class="custom-tab-button"
                        :class="{ 'active-filter': currentFilter === 'connected' }"
                        @click="currentFilter = 'connected'"
                    >
                        Connected
                    </md-button>
                </div>
                <div class="custom-search-input">
                    <md-field>
                        <md-input
                            type="search"
                            v-model="searchTerm"
                            clearable
                            style="width: 200px"
                            placeholder="Search"
                        ></md-input>
                    </md-field>
                </div>
            </div>
            <div
                v-for="channel in filteredChannels"
                class="custom-layout md-layout-item md-xlarge-size-25 md-size-33 md-small-size-50 md-xsmall-size-100"
            >
                <div @click="goToChannelDetail(channel.name, channel.soon)">
                    <md-card :class="{ disabled: channel.soon }" md-with-hover class="custom-card">
                        <md-card-header>
                            <i :class="getIconColor(channel.channel_type)" class="fa-4x"></i>
                            <div class="md-title">{{ channel.name }}</div>
                            <md-chip v-if="!channel.soon" class="communication-channel-item-card__active-chip">
                                <md-icon class="communication-channel-item-card__active-tag-icon">circle</md-icon>
                                <div>Active</div>
                            </md-chip>
                            <md-chip v-if="channel.soon" class="custom-soon-chip"> coming Soon </md-chip>
                        </md-card-header>

                        <md-card-content>
                            <span class="md-body-1">{{ channel.description }}</span>
                        </md-card-content>
                    </md-card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import communicationChannelsService from '@/services/account/communicationChannelsService.js';

export default {
    name: 'CommunicationChannels',
    data() {
        return {
            communication_channels: [
                {
                    channel_type: 'WHATSAPP',
                    name: 'WhatsApp',
                    description:
                        'Integrate Lixsa with WhatsApp for efficient customer service. Optimize communications and free up valuable resources.',
                    isConnected: false,
                    soon: false,
                },
                {
                    channel_type: 'MESSENGER',
                    name: 'Messenger',
                    description:
                        'Connect Lixsa with Facebook Messenger to enhance customer interactions and capture leads.',
                    isConnected: false,
                    soon: true,
                },
            ],
            currentFilter: 'all',
            searchTerm: '',

            isLoading: false,
        };
    },
    computed: {
        filteredChannels() {
            let filtered = this.communication_channels;

            if (this.currentFilter === 'connected') {
                filtered = filtered.filter((channel) => channel.isConnected);
            }

            if (this.searchTerm.trim() !== '') {
                const term = this.searchTerm.toLowerCase();
                filtered = filtered.filter((channel) => channel.name.toLowerCase().includes(term));
            }

            return filtered;
        },
    },
    methods: {
        getIconColor(type) {
            switch (type) {
                case 'WHATSAPP':
                    return 'whatsapp-icon fab fa-whatsapp-square';
                    break;

                case 'MESSENGER':
                    return 'messenger-icon fab fa-facebook-messenger';
                    break;

                default:
                    break;
            }
        },
        goToChannelDetail(channelName, soon) {
            if (!soon) {
                this.$router.push(`/communication-channels/${channelName.toLowerCase()}`);
            }
        },

        async fetchCommunicationChannels() {
            this.isLoading = true;
            try {
                const response = await communicationChannelsService.fetchCommunicationChannels();
                if (response && response.data) {
                    const user_cc = response.data.content;
                    this.communication_channels.forEach((channel) => {
                        if (user_cc.some((userChannel) => userChannel.type === channel.channel_type)) {
                            channel.isConnected = true;
                        }
                    });
                }
            } catch (error) {
                console.error('Error fetching the communication channels: ' + error);
            } finally {
                this.isLoading = false;
            }
        },
    },
    mounted() {
        this.fetchCommunicationChannels();
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

//.custom-chip {
//  position: absolute;
//  top: 0.75rem;
//  right: 1.25rem;
//  //background-color: $brand-s !important;
//}

.communication-channel-item-card__active-chip {
    position: absolute;
    top: 0.75rem;
    right: 1.25rem;
    display: flex;
    align-items: center;

    .communication-channel-item-card__active-tag-icon {
        color: #5cb85c !important;
        font-size: 12px !important;
        width: 12px !important;
        height: 12px !important;
    }
}

.custom-soon-chip {
    position: absolute;
    top: 0.75rem;
    right: 1.25rem;
    //background-color: $gray-color !important;
}

.custom-card {
    position: relative;
    height: 250px;
    margin-top: 0;
}

.whatsapp-icon {
    color: #25d366;
    margin-bottom: 12px;
}

.messenger-icon {
    color: #006aff;
    margin-bottom: 12px;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.6;
    pointer-events: none;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.active-filter {
    background-color: $brand-primary !important;
    color: white !important;
}

.custom-filters {
    background-color: white;
    width: fit-content;
    height: fit-content;
    margin-bottom: 15px;
    border-radius: 12px;
}

.custom-search-input {
    margin-bottom: 15px;
}

.custom-tab-button {
    margin: 0;
}

.custom-tool-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 46px;
}

@media (max-width: 599px) {
    .custom-filters {
        display: none;
    }
}
</style>
