import store from '@/store';
import axios from 'axios';

const baseURL = `${process.env.VUE_APP_API_URL}`;
// const getAuthHeader = (token) => ({ Authorization: `Bearer ${token}` });
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}, cancelToken = null) => {
    try {
        return await store.dispatch('user/apiCall', async (token) => {
            const config = {
                method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params,
                data,
                ...(cancelToken ? { cancelToken: cancelToken } : {}),
            };
            return await axios(config);
        });
    } catch (error) {
        if (!axios.isCancel(error)) {
            console.error('API request failed', error);
        }
        throw error;
    }
};

const DashboardMetricsService = {
    getTotalConversationsByDate(dateRange = null, fromDate = null, toDate = null) {
        const params = {};
        if (dateRange !== null) params.dateRange = dateRange;
        if (fromDate !== null) params.fromDate = fromDate;
        if (toDate !== null) params.toDate = toDate;

        return apiRequest('get', 'metrics/total-conversations-by-date', null, params);
    },

    getTotalRecurrentCustomersByDate(dateRange = null, fromDate = null, toDate = null) {
        const params = {};
        if (dateRange !== null) params.dateRange = dateRange;
        if (fromDate !== null) params.fromDate = fromDate;
        if (toDate !== null) params.toDate = toDate;

        return apiRequest('get', 'metrics/total-recurrent-customers-by-date', null, params);
    },

    getTotalNewCustomersByDate(dateRange = null, fromDate = null, toDate = null) {
        const params = {};
        if (dateRange !== null) params.dateRange = dateRange;
        if (fromDate !== null) params.fromDate = fromDate;
        if (toDate !== null) params.toDate = toDate;

        return apiRequest('get', 'metrics/total-new-customers-by-date', null, params);
    },

    getTotalMessagesByDate(dateRange = null, fromDate = null, toDate = null) {
        const params = {};
        if (dateRange !== null) params.dateRange = dateRange;
        if (fromDate !== null) params.fromDate = fromDate;
        if (toDate !== null) params.toDate = toDate;

        return apiRequest('get', 'metrics/total-messages-by-date', null, params);
    },

    getTotalImageMessagesByDate(dateRange = null, fromDate = null, toDate = null) {
        const params = {};
        if (dateRange !== null) params.dateRange = dateRange;
        if (fromDate !== null) params.fromDate = fromDate;
        if (toDate !== null) params.toDate = toDate;

        return apiRequest('get', 'metrics/total-image-messages', null, params);
    },

    getTotalAudioMessagesByDate(dateRange = null, fromDate = null, toDate = null) {
        const params = {};
        if (dateRange !== null) params.dateRange = dateRange;
        if (fromDate !== null) params.fromDate = fromDate;
        if (toDate !== null) params.toDate = toDate;

        return apiRequest('get', 'metrics/total-audio-messages', null, params);
    },

    getAvgMessagePerConversationByDate(dateRange = null, fromDate = null, toDate = null) {
        const params = {};
        if (dateRange !== null) params.dateRange = dateRange;
        if (fromDate !== null) params.fromDate = fromDate;
        if (toDate !== null) params.toDate = toDate;

        return apiRequest('get', 'metrics/avg-message-per-conversation', null, params);
    },

    getSatisfactionByDate(dateRange = null, fromDate = null, toDate = null) {
        const params = {};
        if (dateRange !== null) params.dateRange = dateRange;
        if (fromDate !== null) params.fromDate = fromDate;
        if (toDate !== null) params.toDate = toDate;

        return apiRequest('get', 'metrics/satisfaction-by-date', null, params);
    },
};

export default DashboardMetricsService;
