<template>
    <footer class="footer">
        <div class="container">
            <nav>
                <ul>
                    <li>
                        <a href="https://www.walkonlivos.com/"> Company </a>
                    </li>
                    <li>
                        <a href="https://www.lixsa.ai/#section-65c25ec8fcc18"> Blog </a>
                    </li>
                    <li>
                        <a href="https://www.lixsa.ai/terms"> Terms </a>
                    </li>
                    <li>
                        <a href="https://www.lixsa.ai/privacy"> Privacy </a>
                    </li>
                </ul>
            </nav>
            <div class="copyright text-center">
                &copy; {{ new Date().getFullYear() }}
                <a href="https://www.walkonlivos.com/" target="_blank">Walkon Livos S.L</a>
            </div>
        </div>
    </footer>
</template>
<script>
export default {};
</script>
<style></style>
