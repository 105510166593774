<template>
    <Onboard />
</template>
<script>
import Onboard from '../../components/Onboarding/Onboard.vue';

export default {
    name: 'onboard',
    components: { Onboard },
    data() {
        return {
            firstname: null,
            email: null,
            password: null,
        };
    },
};
</script>
