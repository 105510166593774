<template>
    <div class="not-found-page">
        <img
            src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2F404.png?alt=media&token=5cd1b75b-254c-469c-b947-bf55755000f0"
            alt="Confused mascot"
            class="not-found-page__image"
        />
        <h1 class="md-display-1">Oops! Page not found.</h1>
        <p class="md-subheading">We can't seem to find the page you're looking for.</p>
        <md-button class="md-primary" to="/">Back to Home</md-button>
    </div>
</template>

<script>
export default {
    name: 'TheNotFound',
};
</script>

<style scoped lang="scss">
/* Block */
.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
}

/* Element */
.not-found-page__image {
    max-width: 10%;
    margin-bottom: 2rem;
}
</style>
