<script>
export default {
    name: 'Dialog360PopupButton',
    methods: {
        processParams() {
            const params = window.location.search; // retrieve the current URL search parameters

            // Check if there is an opener window
            if (window.opener) {
                window.opener.postMessage(params); // post the parameters to the opener window
                window.close(); // close the current window
            }
        },
        open360DialogPopup() {
            window.removeEventListener('message', this.receiveMessage); // remove any existing message event listeners

            // Window options to be used in opening the new window
            const windowFeatures = 'toolbar=no, menubar=no, width=600, height=900, top=100, left=100';
            const partnerId = process.env.VUE_APP_360DIALOG_PARTNER_ID;
            const redirectUrl = window.location.href; // additional redirect if needed - if you don't want to use your
            // previously set partner redirect

            // Open the new window with the specified URL
            const popupWindow = window.open(
                'https://hub.360dialog.com/dashboard/app/' +
                    partnerId +
                    '/permissions?partner=' +
                    this.$store.state.user.user.account_id +
                    '&redirect_url=' +
                    redirectUrl,
                'integratedOnboardingWindow',
                windowFeatures,
            );

            // Add a message event listener to the current window
            window.addEventListener('message', (event) => this.receiveMessage(event, window.location.origin), false);
        },
        receiveMessage(event, baseUrl) {
            console.log('Message received:', event);

            if (event.origin !== baseUrl || typeof event.data === 'object') {
                console.warn('Received message from unauthorized origin or invalid data:', event.origin, event.data);
                return;
            }
            const { data } = event; // retrieve the data from the event
            const redirectUrl = `${data}`; // create a redirect URL from the data
            window.location.search = redirectUrl; // set the redirect URL as the search parameters of the current URL
        },
    },
    mounted() {
        this.processParams(); // Execute processParams when the component is mounted
    },
};
</script>

<template>
    <div>
        <md-button class="md-primary" @click="open360DialogPopup">Connect Whatsapp Business Account</md-button>
    </div>
</template>

<style scoped lang="scss"></style>
