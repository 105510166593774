import axios from 'axios';

const facebookService = {
    getFacebookAccessToken(code) {
        const url = 'https://graph.facebook.com/v19.0/oauth/access_token';
        const params = {
            client_id: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
            client_secret: process.env.VUE_APP_FACEBOOK_CLIENT_SECRET,
            code: code,
        };

        return axios.get(url, { params });
    },

    extendDurationOfAccessToken(access_token) {
        const url = 'https://graph.facebook.com/v19.0/oauth/access_token';
        const params = {
            grant_type: 'fb_exchange_token',
            client_id: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
            client_secret: process.env.VUE_APP_FACEBOOK_CLIENT_SECRET,
            fb_exchange_token: access_token,
        };

        return axios.get(url, { params });
    },

    registerWhatsApp(messaging_product, pin, token, phone_number_id) {
        const url = `https://graph.facebook.com/v20.0/${phone_number_id}/register`;
        const data = {
            messaging_product: messaging_product,
            pin: pin,
        };
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        };

        return axios.post(url, data, { headers });
    },

    subscribeAppToWebhooks(token, waba_id) {
        const url = `https://graph.facebook.com/v20.0/${waba_id}/subscribed_apps`;
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        return axios.post(url, null, { headers });
    },
};

export default facebookService;
