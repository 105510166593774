import store from '@/store';
import axios from 'axios';

const baseURL = `${process.env.VUE_APP_API_URL}`;
// const getAuthHeader = (token) => ({ Authorization: `Bearer ${token}` });
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}, cancelToken = null) => {
    try {
        return await store.dispatch('user/apiCall', async (token) => {
            const config = {
                method,
                url: `${baseURL}/${url}`,
                headers: getAuthHeader(token),
                params,
                data,
                ...(cancelToken ? { cancelToken: cancelToken } : {}),
            };
            return await axios(config);
        });
    } catch (error) {
        if (!axios.isCancel(error)) {
            console.error('API request failed', error);
        }
        throw error;
    }
};

let cancelToken;

const SkillsService = {
    getSkill(skillId) {
        return apiRequest('get', `marketplace/skills/${skillId}`);
    },

    getSkills(page = 0, size = 20) {
        const params = { page, size };

        return apiRequest('get', 'marketplace/skills', null, params);
    },

    searchSkills(searchTerm = null, category = null, page = 0, size = 20) {
        if (cancelToken) {
            cancelToken.cancel('Cancelled the previous request');
        }

        // Create a new CancelToken
        cancelToken = axios.CancelToken.source();

        const params = {
            page,
            size,
        };

        if (searchTerm) params.search = searchTerm;
        if (category) params.category = category;

        return apiRequest('get', 'marketplace/skills/search', null, params, cancelToken.token).catch((thrown) => {
            if (!axios.isCancel(thrown)) {
                throw thrown;
            }
        });
    },
};

export default SkillsService;
