<script>
import { v4 as uuidv4 } from 'uuid';
import stripeService from '@/services/stripe/stripeService';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'RegisterSocialButtonGoogle',
    emits: ['on-error'],
    props: ['queryParams'],
    computed: {
        ...mapGetters('user', ['isShopifyBillingClient']),
    },
    methods: {
        ...mapActions('user', [
            'actionGetUser',
            'actionCreateAccount',
            'refreshToken',
            'actionGetAccount',
            'actionSignUpWithPopup',
            'logout',
        ]),

        ...mapActions('membership', ['getAccountMembership', 'subscribeToMembershipUpdates']),

        ...mapActions('agentSettings', ['updateAgentSetting']),

        async registerWithGoogle() {
            const myUuid = uuidv4();
            this.error = '';
            this.loading = true;

            try {
                const signupResponse = await this.actionSignUpWithPopup();

                if (!signupResponse) {
                    this.error = 'Authentication failed';
                    return;
                }

                let sourcePlatform = 'STRIPE';
                if (this.queryParams.source) {
                    sourcePlatform =
                        this.queryParams.source.toLowerCase() === 'shopify'
                            ? 'SHOPIFY_BILLING'
                            : this.queryParams.source;
                }

                const accData = {
                    uuid: myUuid,
                    email: signupResponse._tokenResponse.email,
                    user_id: signupResponse._tokenResponse.localId,
                    contact_name: signupResponse._tokenResponse.displayName,
                    source_platform: sourcePlatform,
                };

                await this.actionCreateAccount(accData);
                await this.refreshToken();
                await this.actionGetUser();
                await this.updateAgentSetting();
                const response = await this.actionGetAccount();
                if (response.status === 200 || response.status === 201) {
                    await this.getAccountMembership();
                    if (!this.isShopifyBillingClient) {
                        await stripeService.fetchCustomer();
                    }
                    this.subscribeToMembershipUpdates();
                    await this.$router.push({ name: 'Dashboard', query: this.queryParams });

                    // this.$router.push({ name: "Onboard" });
                    this.showSuccessToast('Sign up complete!');
                }
            } catch (err) {
                // const errorMessage = err || 'An error occurred.';
                // this.error = errorMessage;
                // console.error('Error:', errorMessage);
                this.$emit('on-error', err);
                this.logout();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<template>
    <md-button class="md-outline md-gray register-social-button-google" v-on:click="registerWithGoogle">
        <div class="register-social-button-google__icon"></div>
        <span>Google</span>
    </md-button>
</template>

<style scoped lang="scss">
.register-social-button-google {
    width: 100%;

    &__icon {
        height: 17px;
        width: 17px;
        background-image: url('@/assets/icons/google-logo.svg');
        margin-right: 0.7rem;
    }
}
</style>
