<script>
import rowData from './SubscriptionFeaturesTable.json';
export default {
    name: 'SubscriptionFeaturesTable',
    data() {
        return {
            colDefs: [
                {
                    field: 'features',
                    // pinned: true,
                    headerName: '',
                    tooltipField: 'tooltip',
                    cellStyle: (params) => {
                        if (
                            params.value === 'Integrations' ||
                            params.value === 'Included Lixsa Skills' ||
                            params.value === 'Price And Conversations'
                        ) {
                            return {
                                'font-weight': 'bold',
                                backgroundColor: '#FBFBFB',
                                'font-size': '15px',
                            };
                        }
                        return { 'font-weight': 'bold', color: '#4F29E2' };
                    },
                },
                {
                    field: 'free',
                    cellStyle: (params) => {
                        if (params.value === null) {
                            return {
                                backgroundColor: '#FBFBFB',
                            };
                        } else if (params.value === true) {
                            return {
                                fontSize: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            };
                        }
                        return {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        };
                    },
                    valueFormatter: (params) => {
                        if (params.value === true) {
                            return 'check_circle';
                        } else if (params.value === false) {
                            return '-';
                        }
                        return params.value;
                    },
                    cellClass: (params) => {
                        if (params.value === true) {
                            return ['material-symbols-outlined'];
                        }
                        return '';
                    },
                },
                {
                    field: 'startup',
                    cellStyle: (params) => {
                        if (params.value === null) {
                            return {
                                backgroundColor: '#FBFBFB',
                            };
                        } else if (params.value === true) {
                            return {
                                fontSize: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            };
                        }
                        return {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        };
                    },
                    valueFormatter: (params) => {
                        if (params.value === true) {
                            return 'check_circle';
                        } else if (params.value === false) {
                            return '-';
                        }
                        return params.value;
                    },
                    cellClass: (params) => {
                        if (params.value === true) {
                            return ['material-symbols-outlined'];
                        }
                        return '';
                    },
                },
                {
                    field: 'scaling',
                    cellStyle: (params) => {
                        if (params.value === null) {
                            return {
                                backgroundColor: '#FBFBFB',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            };
                        } else if (params.value === true) {
                            return {
                                fontSize: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            };
                        }
                        return {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        };
                    },
                    valueFormatter: (params) => {
                        if (params.value === true) {
                            return 'check_circle';
                        } else if (params.value === false) {
                            return '-';
                        }
                        return params.value;
                    },
                    cellClass: (params) => {
                        if (params.value === true) {
                            return ['material-symbols-outlined'];
                        }
                        return '';
                    },
                },
                {
                    field: 'pro',
                    cellStyle: (params) => {
                        if (params.value === null) {
                            return {
                                backgroundColor: '#FBFBFB',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            };
                        } else if (params.value === true) {
                            return {
                                fontSize: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            };
                        }
                        return {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        };
                    },
                    valueFormatter: (params) => {
                        if (params.value === true) {
                            return 'check_circle';
                        } else if (params.value === false) {
                            return '-';
                        }
                        return params.value;
                    },
                    cellClass: (params) => {
                        if (params.value === true) {
                            return ['material-symbols-outlined'];
                        }
                        return '';
                    },
                },
            ],

            rowData: rowData,

            defaultColDef: {
                flex: 1,
            },
        };
    },
};
</script>

<template>
    <div class="md-layout-item md-size-100 subscription-features-table">
        <ag-grid-vue
            :rowData="rowData"
            :columnDefs="colDefs"
            style="width: 100%"
            domLayout="autoHeight"
            class="ag-theme-quartz"
            :defaultColDef="defaultColDef"
        >
        </ag-grid-vue>
    </div>
</template>

<style scoped lang="scss">
.ag-theme-quartz {
    --ag-font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    --ag-borders: none;
}

::v-deep .ag-header-cell-label {
    justify-content: center;
    font-size: 15px;
}
</style>
