<script>
import { mapActions } from 'vuex';

export default {
    name: 'ThirdPartySkillInstallerLoader',
    emits: ['shop-synced'],
    data() {
        return {
            checkList: [
                {
                    name: 'businessKnowledge',
                    message: 'Syncing Lixsa with your business',
                    status: 'training',
                },
            ],
        };
    },

    computed: {
        allDone() {
            return this.checkList.every((item) => item.status === 'done');
        },
    },

    watch: {
        checkList: {
            handler() {
                if (!this.allDone) return;
                setTimeout(() => {
                    this.emitShopSynced();
                }, 500);
            },
            deep: true,
        },
    },

    methods: {
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),

        emitShopSynced() {
            this.$emit('shop-synced');
        },

        subscribeToBusinessKnowledgeTopic() {
            try {
                const topic = `/account/${this.$store.state.user.user.account_id}/business-knowledge`;
                this.subscribe({
                    topic,
                    callback: this.handleBusinessKnowledgeEvent,
                });
            } catch (error) {
                console.error('Error subscribing to the channel', error);
            }
        },

        handleBusinessKnowledgeEvent(event) {
            if (!event || !event.data) return;

            if (event.name === 'BusinessKnowledgeCreated') {
                const item = this.checkList.find((item) => item.name === 'businessKnowledge');
                item.status = 'done';
            }
        },
    },

    mounted() {
        this.subscribeToBusinessKnowledgeTopic();
    },

    beforeDestroy() {
        const topic = `/account/${this.$store.state.user.user.account_id}/business-knowledge`;
        this.unsubscribe({
            topic,
            callback: this.handleBusinessKnowledgeEvent,
        });
    },
};
</script>

<template>
    <div class="third-party-skill-installer-loader">
        <div v-for="item in checkList" class="third-party-skill-installer-loader__check-item">
            <md-button v-if="item.status === 'training'" class="md-just-icon md-simple md-primary">
                <i class="material-symbols-outlined third-party-skill-installer-loader__check-item__loading-icon"
                    >sync</i
                >
            </md-button>
            <md-button v-else class="md-just-icon md-simple md-green">
                <i class="material-symbols-outlined third-party-skill-installer-loader__check-item__done-icon"
                    >check_circle</i
                >
            </md-button>
            <div class="third-party-skill-installer-loader__check-item__title md-title">
                {{ item.message }}
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.third-party-skill-installer-loader {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: 1rem;

    &__check-item {
        display: flex;
        align-items: center;
        justify-content: center;

        &__loading-icon {
            animation: spin-animation 0.8s infinite;
            display: inline-block;
        }
    }
}

@keyframes spin-animation {
    0% {
        transform: rotate(359deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
</style>
