<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="showDialog">
            <md-dialog-title><strong>Create new API key</strong></md-dialog-title>
            <div class="modal-content">
                <md-field>
                    <label>Name</label>
                    <md-input type="text" v-model="name"></md-input>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="handleCreate" :disabled="!canSave">
                    <span v-if="!isLoading">Create</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="showApiKey">
            <md-dialog-title><strong>Create new API key</strong></md-dialog-title>

            <div class="modal-content">
                <p class="md-body-1">
                    It's crucial to store this confidential key in a secure and reachable place. Due to safety measures,
                    <strong>you cannot retrieve it once more</strong> via your Walkon Livos account. Should you misplace
                    this key, a new one will have to be created.
                </p>
                <md-field>
                    <label>API Key</label>
                    <md-input type="text" v-model="key" readonly></md-input>
                    <md-button class="custom-copy-button md-icon-button md-primary" @click="copyToClipboard">
                        <md-icon>content_copy</md-icon>
                    </md-button>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-primary modal-button" @click="handleClose">Done</md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-button class="md-primary custom-button" @click="showDialog = true">
            <div>
                <md-icon class="custom-button-icon">add</md-icon>
                <span>Create new API key</span>
            </div>
        </md-button>
    </div>
</template>

<script>
import apiKeysService from '@/services/account/apiKeysService.js';
import Swal from 'sweetalert2';

export default {
    name: 'CreateApiKey',
    data: () => ({
        showDialog: false,
        showApiKey: false,
        name: '',
        key: '',
        isLoading: false,
    }),
    computed: {
        canSave() {
            return this.name != '';
        },
    },
    methods: {
        handleClose() {
            this.name = '';
            this.key = '';
            this.showDialog = false;
            this.showApiKey = false;
            this.isLoading = false;
        },
        async handleCreate() {
            this.isLoading = true;

            try {
                const createApiKeyResponse = await apiKeysService.createApiKey(this.name);
                if (createApiKeyResponse.response.status === 201) {
                    this.fetchApiKey(createApiKeyResponse.uuid);
                    this.isLoading = false;
                    this.showDialog = false;
                    this.notifyParent();
                    this.showApiKey = true;
                }
            } catch (error) {
                console.error('Error creating API key: ' + error);
                this.isLoading = false;
                this.handleClose();
                Swal.fire({
                    title: 'Error creating API key',
                    text: 'There was an error creating the API key, try again later.',
                    icon: 'error',
                    confirmButtonColor: '#4caf50',
                    confirmButtonText: 'Okay',
                    buttonsStyling: true,
                });
            }
        },
        async fetchApiKey(uuid) {
            try {
                const response = await apiKeysService.fetchApiKey(uuid);
                if (response && response.data) {
                    this.key = response.data.token;
                }
            } catch (error) {
                console.error('Error fetching API key: ' + error);
            }
        },
        notifyParent() {
            this.$emit('ApiCreated');
        },
        async copyToClipboard() {
            try {
                await navigator.clipboard.writeText(this.key);
                this.showSuccessToast('API key copied');
            } catch (error) {
                console.error('Error copying API key: ' + error);
            }
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-dialog .md-dialog-container {
    max-width: 768px;
}

.modal-content {
    background: #fff;
    padding: 30px;
    padding-top: 0px;
    // text-align: center;
    margin: 0 auto;
    width: 500px;

    p {
        margin-bottom: 40px;
    }
}

.modal-content h4 {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}
.custom-copy-button {
    margin-left: 16px;
}
.custom-button {
    color: $white-color !important;

    .custom-button-icon {
        color: $white-color !important;
    }
}
</style>
