import axios from 'axios';
import store from '@/store';

const baseURL = process.env.VUE_APP_API_URL;
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/${url}`,
                headers: getAuthHeader(token),
                params: { account: store.state.user.user.account_id, ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const accountService = {
    updateEcommerceSettings(updateData) {
        return apiRequest('post', `accounts/${store.state.user.user.account_id}/update-ecommerce-settings`, updateData);
    },

    fetchAccount(account_id) {
        return apiRequest('get', `accounts/${account_id}`);
    },

    fetchAccountMembership() {
        return apiRequest(
            'get',
            `accounts/${store.state.user.user.account_id}/membership/${store.state.user.user.account_id}`,
        );
    },

    getAccountMembershipByExternalBillingId(externalBillingId) {
        const url = `accounts/${store.state.user.user.account_id}/customer-id/${externalBillingId}`;
        return apiRequest('get', `${url}`);
    },
};

export default accountService;
