<script>
import { mapGetters } from 'vuex';
import stripeService from '@/services/stripe/stripeService';

export default {
    name: 'BillingSectionStripe',
    data() {
        return {
            isCreatingCustomerPortal: false,
            isCreatingPackCheckoutLink: false,
            isCreatingCancelPlanLink: false,
            isCreatingUpdatePlanLink: false,
        };
    },
    computed: {
        ...mapGetters('membership', [
            'consumedConversations',
            'totalConversations',
            'membershipPlanName',
            'isInTrialPeriod',
            'cancelAtPeriodEnd',
            'subscriptionEndDate',
            'subscriptionTrialEndDate',
            'cancelAt',
        ]),
        conversationsPercentage() {
            const percentage = (this.consumedConversations * 100) / this.totalConversations;
            return percentage > 0 ? Number(percentage.toFixed(1)) : Number(percentage);
        },
    },
    methods: {
        formatDate(timestamp) {
            const options = { month: 'long', day: 'numeric' };
            const date = new Date(timestamp * 1000);
            return date.toLocaleDateString('en-US', options);
        },
        async openPackCheckoutLink() {
            this.isCreatingPackCheckoutLink = true;
            try {
                const availablePack = await stripeService.getAvailablePack();
                const packPaymentLink = await stripeService.getPackPaymentLink(availablePack);
                window.open(packPaymentLink, '_blank');
            } catch (error) {
                console.error('Error creating payment link: ' + error);
            } finally {
                this.isCreatingPackCheckoutLink = false;
            }
        },
        async openCustomerPortal() {
            this.isCreatingCustomerPortal = true;
            try {
                const customerPortalURL = await stripeService.createCustomerPortal();
                window.open(customerPortalURL, '_blank');
            } catch (error) {
                console.error('Error creating customer portal: ' + error);
            } finally {
                this.isCreatingCustomerPortal = false;
            }
        },
        async openCancelPlanPortal() {
            this.isCreatingCancelPlanLink = true;
            try {
                const customerPortalURL = await stripeService.createCancelLink();
                window.open(customerPortalURL, '_blank');
            } catch (error) {
                console.error('Error creating cancel plan portal: ' + error);
            } finally {
                this.isCreatingCancelPlanLink = false;
            }
        },
        async openUpdatePlanPortal() {
            this.isCreatingUpdatePlanLink = true;
            try {
                const customerPortalURL = await stripeService.createUpdatePlanLink();
                window.open(customerPortalURL, '_blank');
            } catch (error) {
                console.error('Error creating update plan portal: ' + error);
            } finally {
                this.isCreatingUpdatePlanLink = false;
            }
        },
        capitalizeFirstLetter(str) {
            if (typeof str !== 'string' || str.length === 0) {
                return '';
            }
            str = str.toLowerCase();
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
    },
};
</script>

<template>
    <div class="billing-section-stripe">
        <md-card>
            <md-card-header>
                <div class="md-title bold">
                    <i class="material-symbols-outlined"> package_2 </i>
                    Plan {{ capitalizeFirstLetter(membershipPlanName) }}
                </div>
                <p v-if="!isInTrialPeriod && !cancelAtPeriodEnd" class="md-caption">
                    Renews on {{ formatDate(subscriptionEndDate) }}
                </p>
                <p v-else-if="!cancelAtPeriodEnd" class="md-caption">
                    Your trial ends on {{ formatDate(subscriptionTrialEndDate) }}
                </p>
                <p v-if="cancelAtPeriodEnd" class="md-caption billing-section-stripe__cancel-message">
                    Your plan will cancel at {{ formatDate(cancelAt) }}.
                    <strong> Click on billing to renew the plan </strong>
                </p>
            </md-card-header>

            <md-card-content>
                <div class="usage-section">
                    <div class="usage-header">
                        <h3 class="md-subheading bold">
                            <i class="material-symbols-outlined"> chat_bubble </i>
                            Conversation Usage
                        </h3>
                        <span class="usage-count">{{ consumedConversations }} / {{ totalConversations }}</span>
                    </div>
                    <md-progress-bar
                        class="billing-section-stripe__progress-bar md-primary"
                        md-mode="determinate"
                        :md-value="conversationsPercentage"
                    ></md-progress-bar>
                    <p class="md-caption">
                        You have used {{ conversationsPercentage.toFixed(1) }}% of your available conversations
                    </p>
                </div>

                <div v-if="conversationsPercentage > 80" class="custom-alert">
                    <i class="material-symbols-outlined"> warning </i>
                    <div>
                        <h4 class="md-subheading">Running low on conversations</h4>
                        <p class="md-body-1">Consider upgrading your plan or purchasing additional conversations.</p>
                    </div>
                </div>

                <div class="action-buttons">
                    <md-button class="md-outline md-primary" @click="openUpdatePlanPortal">
                        <md-progress-spinner
                            v-if="isCreatingUpdatePlanLink"
                            :md-diameter="12"
                            :md-stroke="2"
                            class="md-primary"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                        <i v-else class="material-symbols-outlined"> package_2 </i>
                        Update Plan
                    </md-button>
                    <md-button class="md-outline md-primary" @click="openPackCheckoutLink">
                        <md-progress-spinner
                            v-if="isCreatingPackCheckoutLink"
                            :md-diameter="12"
                            :md-stroke="2"
                            class="md-primary"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                        <i v-else class="material-symbols-outlined"> chat_bubble </i>
                        Buy More Conversations
                    </md-button>
                </div>
            </md-card-content>

            <md-card-actions>
                <md-button v-if="!cancelAtPeriodEnd" @click="openCancelPlanPortal" class="md-danger md-simple">
                    <md-progress-spinner
                        v-if="isCreatingCancelPlanLink"
                        :md-diameter="12"
                        :md-stroke="2"
                        class="md-danger"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                    <span>Cancel Subscription</span>
                </md-button>
                <md-button class="md-primary md-raised" @click="openCustomerPortal">
                    <md-progress-spinner
                        v-if="isCreatingCustomerPortal"
                        :md-diameter="12"
                        :md-stroke="2"
                        class="md-white"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                    <i v-else class="material-symbols-outlined"> credit_card </i>
                    Manage Billing
                </md-button>
            </md-card-actions>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.billing-section-stripe {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .md-card-header {
        padding: 16px;

        .md-title {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .md-card-content {
        padding: 16px;
    }

    .usage-section {
        margin-bottom: 24px;
    }

    .usage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .md-subheading {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    .billing-section-stripe__progress-bar {
        height: 8px;
        border-radius: 4px;
        margin-bottom: 8px;
    }

    .custom-alert {
        background-color: #fef3c7;
        border-left: 4px solid #f59e0b;
        padding: 16px;
        display: flex;
        align-items: flex-start;
        gap: 12px;
        margin-bottom: 24px;
        border-radius: 8px;

        i {
            color: #f59e0b;
        }

        h4 {
            margin-bottom: 4px;
        }
    }

    .action-buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        margin-bottom: 24px;
    }

    .md-card-actions {
        padding: 16px;
        border-top: 1px solid #e5e7eb;
        display: flex;
        justify-content: space-between;
    }

    .billing-section-stripe__cancel-message {
        color: #f44336;
    }

    .bold {
        font-weight: 600;
    }

    @media (max-width: 640px) {
        .action-buttons {
            grid-template-columns: 1fr;
        }

        .md-card-actions {
            flex-direction: column-reverse;
            gap: 16px;

            .md-button {
                width: 100%;
            }
        }
    }

    .md-progress-spinner {
        margin-right: 1rem;
    }
}
</style>
