<script>
export default {
    name: 'DiscoverySkillCard',
    props: {
        skill: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        cutStringTo39Chars(str) {
            if (str === null || str === undefined) {
                return '';
            }

            // Ensure the string is at least 39 characters long and check if the 39th character is a dot
            if (str.length >= 39 && str[38] === '.') {
                return str.slice(0, 39);
            } else {
                return str.slice(0, 39) + '...';
            }
        },

        goToSkillDetails(skillId) {
            this.$router.push(`/skills/details/${skillId}`);
        },
    },
};
</script>

<template>
    <div @click="goToSkillDetails(skill.uuid)">
        <md-card class="discovery-skill-card__card">
            <md-card-content>
                <div class="discovery-skill-card__card-content">
                    <div class="discovery-skill-card__image-container">
                        <img v-if="skill.image && skill.image !== ''" :src="skill.image" alt="Skill Image" />
                        <img
                            v-else
                            src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Ffoto%20perfil%20usuario.png?alt=media&token=5470816a-d159-4958-aae2-5837c747339c"
                            alt="Lixsa default image"
                        />
                    </div>
                    <div class="discovery-skill-card__skill-information">
                        <div class="discovery-skill-card__name-and-tags">
                            <span class="md-body-2">
                                {{ skill.name }}
                            </span>
                            <span v-if="active">
                                <md-chip class="discovery-skill-card__active-chip">
                                    <md-icon class="discovery-skill-card__active-tag-icon">circle</md-icon>
                                    <div>Active</div>
                                </md-chip>
                            </span>
                        </div>
                        <div class="md-caption">
                            {{ cutStringTo39Chars(skill.description) }}
                        </div>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<style scoped lang="scss">
.discovery-skill-card__card {
    margin: 0;
    cursor: pointer;

    transition: background-color 0.3s ease; // Smooth transition for background color change

    &:hover {
        background-color: #f2f2f2; // Light grey background on hover
    }

    &:active {
        background-color: #d6d6d6; // Darker grey background on click
    }
}

.discovery-skill-card__card-content {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.discovery-skill-card__image-container {
    flex: 0 0 50px;
    border-radius: 12px;
    overflow: hidden;

    img {
        width: 50px;
        height: auto;
        /* Keeps the aspect ratio */
    }
}

.discovery-skill-card__name-and-tags {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.discovery-skill-card__active-chip {
    display: flex;
    align-items: center;
    font-size: 11px;
    padding-left: 0;
    padding-right: 10px;

    .discovery-skill-card__active-tag-icon {
        color: #5cb85c !important;
        font-size: 11px !important;
        width: 11px !important;
        height: 11px !important;
    }
}
</style>
