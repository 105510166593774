import axios from 'axios';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

const baseURL = process.env.VUE_APP_API_URL;
// const getAuthHeader = (token) => ({ Authorization: `Bearer ${token}` });
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const apiKeysService = {
    fetchApiKeys(page = 0, size = 20) {
        return apiRequest('get', `accounts/${store.state.user.user.account_id}/api-keys`, null, { page, size });
    },

    fetchApiKey(ApiKeyId) {
        return apiRequest('get', `accounts/${store.state.user.user.account_id}/api-keys/${ApiKeyId}`);
    },

    async createApiKey(apiKeyName) {
        const requestData = { uuid: uuidv4(), name: apiKeyName };
        try {
            const response = await apiRequest(
                'post',
                `accounts/${store.state.user.user.account_id}/api-keys`,
                requestData,
            );
            return { uuid: requestData.uuid, response };
        } catch (error) {
            throw error;
        }
    },

    deleteApiKey(ApiKeyId) {
        return apiRequest('delete', `accounts/${store.state.user.user.account_id}/api-keys/${ApiKeyId}`);
    },

    editApiKey(apiKeyId, updateData) {
        return apiRequest('post', `accounts/${store.state.user.user.account_id}/api-keys/${apiKeyId}`, updateData);
    },
};

export default apiKeysService;
