<template>
    <div class="vac-customer-container-info">
        <div class="customer-orders-container" v-if="!showDetailsOrder">
            <md-progress-spinner
                v-if="loadingOrders"
                class="md-black loader"
                :md-diameter="80"
                :md-stroke="8"
                md-mode="indeterminate"
            ></md-progress-spinner>

            <div class="customer-orders" v-else-if="!loadingOrders && hasAssets()">
                <div v-for="(orderArray, key) in orders" :key="key">
                    <div class="card-assets-container" v-if="getTotalOrdersCountForKey(key) > 0">
                        <span class="customer-title-card">
                            {{ camelCaseToWords(key) }}
                        </span>
                        <div class="customer-orders-cards-container">
                            <div v-for="order in orderArray" :key="order.id">
                                <div>
                                    <customer-assets-card
                                        :order="order"
                                        :show-details-order="showDetailsOrder"
                                        :assetType="key"
                                        @toggle-details-order="toggleShowDetailsOrder(order, key)"
                                    >
                                    </customer-assets-card>
                                </div>
                            </div>
                        </div>
                        <div v-if="getTotalOrdersCountForKey(key) >= expandedStates[key]">
                            <span class="customer-show-all-assets" @click="expandAssetsByKey(key)"
                                >Show all {{ camelCaseToWords(key) }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showDetailsOrder">
            <customer-assets-details
                :selected-asset="selectedAsset"
                :current-user-id="currentUserId"
                :current-user-name="currentUserName"
                :current-user-email="currentUserEmail"
                :currentUserPhone="currentUserPhone"
                :show-details-order="showDetailsOrder"
                :businessType="businessType"
                @toggle-details-order="toggleShowDetailsOrder(selectedAsset)"
                @toggle-info-customer="toggleShowInfoCustomer"
            ></customer-assets-details>
        </div>
    </div>
</template>
<script>
import { toRefs } from 'vue';

import CustomerAssetsCard from '@/lib/CustomerDetails/CustomerCardBody/CustomerAssets/CustomerAssetsCard/CustomerAssetsCard.vue';
import CustomerAssetsDetails from '@/lib/CustomerDetails/CustomerCardBody/CustomerAssets/CustomerAssetsDetails/CustomerAssetsDetails.vue';

export default {
    name: 'CustomerCardBody',
    components: {
        CustomerAssetsCard,
        CustomerAssetsDetails,
    },
    data() {
        return {
            isFirstLoop: true,
            showInfoCustomer: true, // muestra la información del customer y sus ordenes
            showDetailsOrder: false, // muestra el detalle de una orden
            selectedAsset: null,
            // currentUserPhone: this.currentUserPhone,
            // currentUserEmail: this.currentUserEmail,
            // currentUserId: this.currentUserId,
            businessType: this.$store.state.user.account.business_type,
            expandedStates: {},
        };
    },
    props: {
        currentUserId: { type: [String, Number], required: true },
        currentUserName: { type: String, required: true },
        currentUserEmail: { type: String, required: false, default: undefined },
        currentUserPhone: { type: String, required: false, default: undefined },
        currentUserSatisfaction: { type: String, required: true },
        orders: { type: Object, required: true },
        loadingOrders: { type: Boolean, required: true },
        notes: { type: String },
    },
    computed: {
        getTotalOrdersCountForKey() {
            return (key) => {
                return this.orders[key].length;
            };
        },
    },
    emits: ['get-notes-order', 'toggle-details-order'],
    methods: {
        toggleShowDetailsOrder(order, type) {
            this.$emit('get-notes-order', order);
            this.selectedAsset = order;
            this.selectedAsset.assetType = type;
            // this.showInfoCustomer = !this.showInfoCustomer;
            this.showDetailsOrder = !this.showDetailsOrder;
            this.$emit('toggle-details-order');
        },
        toggleShowInfoCustomer() {
            this.selectedAsset = null;
            // this.showInfoCustomer = true;
            this.showDetailsOrder = false;
        },
        expandAssetsByKey(key) {
            this.$set(this.expandedStates, key, 999);
        },
        hasAssets() {
            if (this.orders) {
                if (Object.keys(this.orders).length === 0) {
                    return false;
                }

                for (const key in this.orders) {
                    if (Array.isArray(this.orders[key]) && this.orders[key].length > 0) {
                        return true;
                    }
                }
            }
            return false;
        },
        camelCaseToWords(text) {
            return text.replace(/([A-Z])/g, ' $1').trim();
        },
        setDefaultAssetsPerKey() {
            Object.keys(this.orders).forEach((key) => {
                this.$set(this.expandedStates, key, 3);
            });
        },
        conversationChanged() {
            this.showInfoCustomer = true;
            this.showDetailsOrder = false;

            this.setDefaultAssetsPerKey();
        },
    },
    watch: {
        currentUserPhone() {
            this.conversationChanged();
        },
        currentUserEmail() {
            this.conversationChanged();
        },
        currentUserId() {
            this.conversationChanged();
        },
    },
    setup(props) {
        const { orders } = toRefs(props);
        return { orders };
    },
    mounted() {
        this.setDefaultAssetsPerKey();
    },
    updated() {
        if (this.isFirstLoop) {
            this.setDefaultAssetsPerKey();
            this.isFirstLoop = false;
        }
    },
};
</script>
