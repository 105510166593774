<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="showDialog">
            <md-dialog-title
                ><strong>Invite member to {{ $store.state.user.account.shop_name }}</strong></md-dialog-title
            >
            <div class="modal-content">
                <md-field>
                    <label>Username</label>
                    <md-input type="text" v-model="username"></md-input>
                </md-field>
                <md-field>
                    <label>Email</label>
                    <md-input type="email" v-model="email"></md-input>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="handleCreate" :disabled="!canSave">
                    <span v-if="!isLoading">Send invitation</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-white"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>

        <md-button class="md-primary custom-button" @click="showDialog = true">
            <div>
                <md-icon class="custom-button-icon">add</md-icon>
                <span>Invite member</span>
            </div>
        </md-button>
    </div>
</template>

<script>
import userService from '@/services/account/userService.js';

export default {
    name: 'InviteMemberWizard',
    data: () => ({
        showDialog: false,
        email: '',
        username: '',
        isLoading: false,
    }),
    computed: {
        canSave() {
            return this.email !== '' && this.username !== '';
        },
    },
    methods: {
        handleClose() {
            this.email = '';
            this.username = '';
            this.showDialog = false;
            this.isLoading = false;
        },
        async handleCreate() {
            this.isLoading = true;

            try {
                const reqData = {
                    email: this.email,
                    username: this.username,
                };
                const createUserResponse = await userService.createUser(reqData);
                if (createUserResponse.response.status === 201) {
                    this.handleClose();
                    this.notifyParent();
                    this.showSuccessToast('User created');
                }
            } catch (error) {
                console.error('Error creating member: ' + error);
                this.showErrorToast('Error creating member');
            } finally {
                this.isLoading = false;
            }
        },
        notifyParent() {
            this.$emit('UserCreated');
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-dialog .md-dialog-container {
    max-width: 768px;
}

.modal-content {
    background: #fff;
    padding: 30px;
    padding-top: 0px;
    // text-align: center;
    margin: 0 auto;
    width: 500px;

    p {
        margin-bottom: 40px;
    }
}

.modal-content h4 {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}

.custom-copy-button {
    margin-left: 16px;
}

.custom-button {
    color: $white-color !important;

    .custom-button-icon {
        color: $white-color !important;
    }
}
</style>
