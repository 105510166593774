<template>
    <div class="card-details-customer">
        <div class="card-details-location">
            <div class="card-details-row">
                <div class="card-details-orders" @click="toggleShowDetailsOrder">
                    <div class="card-details-row-icono">
                        <svg-icon class="card-icon-height" name="description" />
                    </div>
                    <div class="card-details-row-number">
                        <div class="order-title">{{ order.customer_full_name }}</div>
                        <div class="card-details-badges">
                            <span v-if="order.status" :class="['order-badges', `badge-${order.status.toLowerCase()}`]">
                                {{ order.status.toLocaleUpperCase() }}
                            </span>
                            <span v-if="order.external_asset_id" :class="['order-badges', 'badge-neutral']">
                                #{{ order.external_asset_id }}
                            </span>
                        </div>
                    </div>
                    <div class="order-currency">{{ order.amount_guests }} guests</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsCardHostelryBooking',
    components: {
        SvgIcon,
    },
    props: {
        order: { type: [Object], required: true },
        showDetailsOrder: { type: [Boolean], required: true },
    },
    methods: {
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order', this.order);
        },
        getBadgeClass,
        getCurrencySymbol,
        formatNumberWithDecimals,
    },
};
</script>
