<template>
    <md-card>
        <md-card-content>
            <div class="custom-md-card-content">
                <div class="custom-image">
                    <img :src="require(`@/assets/img/${miniature}.png`)" :alt="miniature" />
                </div>

                <div style="flex-grow: 1">
                    <div class="md-subheading">{{ title }}</div>
                    <div class="md-caption">{{ description }}</div>
                </div>
            </div>

            <!-- <div v-if="comingSoon" class="custom-md-card-actions">
        <md-chip class="md-primary">
          Coming soon
        </md-chip>
      </div> -->
            <md-tooltip v-if="comingSoon">Coming Soon</md-tooltip>
        </md-card-content>
    </md-card>
</template>
<script>
export default {
    name: 'VerticalCard',
    props: {
        miniature: {
            type: String,
            default: 'skill1',
        },
        title: {
            type: String,
            default: 'Awesome business',
        },
        description: {
            type: String,
            default: 'Long description about what Lixsa is able to do with this business type',
        },
        comingSoon: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isActive: this.active,
            miniatureURL: this.miniature,
        };
    },
};
</script>
<style lang="scss" scoped>
.custom-md-card-content {
    display: flex;
    align-items: start;
    justify-content: start;
    gap: 20px;
}

.custom-image {
    flex: 0 0 70px;
    border-radius: 12px;
    overflow: hidden;

    img {
        width: 70px;
        height: auto;
        /* Keeps the aspect ratio */
    }
}

.custom-md-card-actions {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
}
</style>
