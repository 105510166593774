import { render, staticRenderFns } from "./MainAppsShopifyConnectButton.vue?vue&type=template&id=acf9b880&scoped=true"
import script from "./MainAppsShopifyConnectButton.vue?vue&type=script&lang=js"
export * from "./MainAppsShopifyConnectButton.vue?vue&type=script&lang=js"
import style0 from "./MainAppsShopifyConnectButton.vue?vue&type=style&index=0&id=acf9b880&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf9b880",
  null
  
)

export default component.exports