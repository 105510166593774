import axios from 'axios';
import store from '@/store';

const baseURL = process.env.VUE_APP_API_URL;
// const getAuthHeader = (token) => ({ Authorization: `Bearer ${token}` });
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params: { account: store.state.user.user.account_id, ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const salesService = {
    fetchSales(page = 0, size = 20) {
        const params = { page, size };
        return apiRequest('get', 'sales', null, params);
    },

    getSale(saleId) {
        return apiRequest('get', `sales/${saleId}`);
    },

    updateSale(saleId, udpateData) {
        return apiRequest('post', `sales/${saleId}`, udpateData);
    },

    updateSaleState(saleID, saleState) {
        const reqBody = {
            account: store.state.user.user.account_id,
            sale_id: saleID,
            sale_state: saleState,
        };

        return apiRequest('post', 'sales/update-state', reqBody);
    },

    deleteSale(saleId) {
        return apiRequest('delete', `sales/${saleId}`);
    },
};

export default salesService;
