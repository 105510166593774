<template>
    <div>
        <md-dialog :md-close-on-esc="false" :md-click-outside-to-close="false" :md-active.sync="showDialog">
            <div class="uesm__body">
                <div class="uesm__header">
                    <div class="md-title">Shopify Quick Setup</div>
                    <div class="md-caption">
                        To finish setting up your Shopify store, we need you to give us the domain of your store and an
                        access token.
                    </div>
                </div>

                <md-field>
                    <label for="name">Shop Domain</label>
                    <md-input name="shopDomain" id="shopDomain" v-model="form.shopDomain" />
                </md-field>

                <md-field>
                    <label for="name">Access Token</label>
                    <md-input name="accessToken" id="accessToken" v-model="form.accessToken" />
                </md-field>

                <div class="uesm__actions">
                    <md-button @click="handleClose">Close</md-button>
                    <md-button
                        v-if="!isLoading"
                        :disabled="!canSave"
                        class="md-primary"
                        @click="updateEcommerceSettings()"
                        >Connect</md-button
                    >
                    <md-button v-else disabled>
                        <md-progress-spinner
                            :md-diameter="20"
                            :md-stroke="3"
                            md-mode="indeterminate"
                        ></md-progress-spinner>
                    </md-button>
                </div>
            </div>
        </md-dialog>
    </div>
</template>
<script>
import accountService from '@/services/account/accountService.js';

export default {
    name: 'updateEcommerceSettingsModal',

    data() {
        return {
            showDialog: false,

            form: {
                shopDomain: null,
                accessToken: null,
            },

            isLoading: false,
        };
    },

    props: {
        dialogActive: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        dialogActive(newVal) {
            this.showDialog = newVal;
        },
    },

    emits: ['close-modal', 'ecommerce-settings-updated'],

    computed: {
        canSave() {
            return this.form.shopDomain && this.form.accessToken;
        },
    },

    methods: {
        handleClose() {
            this.$emit('close-modal');
        },
        async updateEcommerceSettings() {
            this.isLoading = true;
            try {
                const reqData = {
                    shop_domain: this.form.shopDomain,
                    shop_access_token: this.form.accessToken,
                    ecommerce_platform_type: 'SHOPIFY',
                };
                await accountService.updateEcommerceSettings(reqData);
                this.$emit('ecommerce-settings-updated');
            } catch (error) {
                console.error('Error updating ecommerce settings', error);
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.uesm__body {
    width: 50vh;
    padding: 24px 24px 12px 24px;
}

.uesm__actions {
    display: flex;
    justify-content: end;
    margin-top: 30px;
    gap: 15px;
}

.uesm__header {
    margin-bottom: 40px;
}
</style>
