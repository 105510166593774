<template>
    <div>
        <md-dialog :md-active.sync="showDialog">
            <md-dialog-title class="md-headline">Create Scenario</md-dialog-title>
            <div class="modal-content">
                <p class="md-subheading">Context</p>
                <md-field>
                    <md-textarea md-autogrow maxlength="1024" type="text" v-model="context" required></md-textarea>
                </md-field>

                <p class="md-subheading">Correct Answer</p>
                <md-field>
                    <md-textarea
                        md-autogrow
                        maxlength="1024"
                        type="text"
                        v-model="correctAnswer"
                        required
                    ></md-textarea>
                </md-field>
            </div>

            <md-dialog-actions>
                <md-button class="md-white modal-button" @click="handleClose">Close</md-button>
                <md-button class="md-primary modal-button" @click="createScenario" :disabled="!canSave">
                    <span v-if="!isLoading">Create</span>
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-accent"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-button class="md-primary custom-button" @click="showDialog = true">
            <div>
                <span>Create Scenario</span>
            </div>
        </md-button>
    </div>
</template>

<script>
import fineTuningService from '@/services/fineTuning/fineTuningService.js';

export default {
    name: 'CreateScenarioWizard',
    data: () => ({
        showDialog: false,
        context: '',
        correctAnswer: '',

        isLoading: false,
    }),
    computed: {
        canSave() {
            // Check if either file is not null or url is not empty AND name is not empty
            return this.context && this.correctAnswer;
        },
    },
    methods: {
        handleClose() {
            this.context = this.correctAnswer = '';
            this.showDialog = false;
            this.isLoading = false;
        },

        async createScenario() {
            this.isLoading = true;
            try {
                const createData = {
                    context: this.context,
                    wrong_answer: null,
                    good_answer: this.correctAnswer,
                    conversation_id: null,
                    message_id: null,
                };
                const { response } = await fineTuningService.createFineTuningScenario(createData);
                if (response.status === 201) {
                    this.isLoading = false;
                    this.notifyParent();
                    this.handleClose();
                    this.showSuccessToast('Scenario created');
                }
            } catch (error) {
                console.error('Error creating scenario: ', error);
                this.showErrorToast('Error creating scenario');
            } finally {
                this.isLoading = false;
            }
        },

        notifyParent() {
            this.$emit('scenarioCreated');
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-dialog .md-dialog-container {
    max-width: 768px;
}

.custom-button {
    color: $white-color !important;

    .custom-button-icon {
        color: $white-color !important;
    }
}

.modal-content {
    background: #fff;
    padding: 0px 30px 60px 30px;
    text-align: center;
    /* max-width: 500px; */
    margin: 0 auto;
    width: 500px;

    @media (max-width: 959px) {
        width: auto;
    }
}

.modal-content p {
    text-align: left;
}

.modal-button {
    text-transform: none !important;
    height: 40px !important;
}
</style>
