<script>
import { StatsCard, AnimatedNumber } from '@/components';

export default {
    name: 'dashboardStatsCard',
    components: {
        StatsCard,
        AnimatedNumber,
    },
    props: {
        // icon: {
        //   type: String,
        //   default: "sell",
        // },
        title: {
            type: String,
            default: 'Title',
        },
        quantity: {
            type: String,
            default: null,
        },
        quantityInPercentage: {
            type: String,
            default: null,
        },
        tooltip: {
            type: String,
            default: '',
        },
        // percentage: {
        //   type: String,
        //   default: "0",
        // },
    },
    computed: {
        statQuantity() {
            if (this.quantity) {
                return this.quantity;
            } else if (this.quantityInPercentage) {
                return this.quantityInPercentage;
            }
        },
    },
};
</script>

<template>
    <!--  <div>-->
    <!--    <md-card>-->
    <!--      <md-card-content>-->
    <!--        <div class="md-body-1">-->
    <!--          {{ title }}-->
    <!--        </div>-->
    <!--        <div class="md-display-1">-->
    <!--          <strong>-->
    <!--            <animated-number :value="statQuantity"></animated-number>-->
    <!--          </strong>-->
    <!--        </div>-->
    <!--      </md-card-content>-->
    <!--    </md-card>-->
    <!--  </div>-->

    <stats-card>
        <template slot="header">
            <!--        <div class="card-icon">-->
            <!--          <md-icon>{{ icon }}</md-icon>-->
            <!--        </div>-->
            <p class="category">{{ title }}</p>
            <div v-if="quantity" class="title md-display-1">
                <strong>
                    <animated-number :value="quantity"></animated-number>
                </strong>
            </div>
            <div v-else-if="quantityInPercentage" class="title md-display-1">
                <strong> <animated-number :value="quantityInPercentage"></animated-number>% </strong>
            </div>
        </template>

        <template slot="footer">
            <div class="stats">
                {{ tooltip }}
            </div>
        </template>
    </stats-card>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';
.md-card-stats {
    width: 100%;
    padding-top: 15px;
    margin-top: 0px;
    .md-card-header {
        .category:not([class*='text-']) {
            color: $gray-light;
            font-size: 20px;
        }

        &.md-card-header-icon,
        &.md-card-header-text {
            i {
                font-size: 30px !important;
            }
        }

        // .card-icon {
        //   background: #2e2872;
        // }
    }

    // .stats-percentage{
    //   color: #2e2872;
    // }
}
</style>
