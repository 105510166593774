<script>
export default {
    name: 'SubscriptionPricingCard',
    props: {
        title: {
            type: String,
            required: true,
        },
        pricePerMonth: {
            type: Number,
            required: true,
        },
        features: {
            type: Array,
            required: true,
        },
        isPopular: {
            type: Boolean,
            default: false,
        },
        isCurrentPlan: {
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        startTrial() {
            this.$emit('start-trial');
        },
    },
};
</script>

<template>
    <md-card class="pricing-card" :class="{ popular: isPopular }">
        <md-card-header>
            <div class="md-title">{{ title }}</div>
            <div class="price">€{{ pricePerMonth }}<span class="per-month">/ month</span></div>
            <div v-if="isPopular" class="popular-badge">Most Popular</div>
        </md-card-header>

        <md-card-content>
            <md-button
                class="md-primary md-block"
                :disabled="isCurrentPlan || isLoading"
                @click="isCurrentPlan ? null : startTrial()"
            >
                <md-progress-spinner
                    v-if="isLoading"
                    :md-diameter="12"
                    :md-stroke="2"
                    class="md-white"
                    md-mode="indeterminate"
                ></md-progress-spinner>
                <span v-else>{{ isCurrentPlan ? 'Current' : 'Start trial' }}</span>
            </md-button>

            <ul class="feature-list">
                <li v-for="(feature, index) in features" :key="index" class="md-body-1">
                    <i class="material-symbols-outlined check-icon">check_circle</i>
                    {{ feature }}
                </li>
            </ul>
        </md-card-content>
    </md-card>
</template>

<style lang="scss" scoped>
.pricing-card {
    max-width: 300px;
    margin: 16px;
    border: 1px solid #e0e0e0;

    .md-title {
        font-weight: bold;
        margin-bottom: 16px;
    }

    .price {
        font-size: 36px;
        font-weight: bold;
        color: #4f29e2;
        margin-bottom: 16px;

        .per-month {
            font-size: 16px;
            font-weight: normal;
        }
    }

    .feature-list {
        list-style-type: none;
        padding: 0;

        li {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
        }

        .check-icon {
            margin-right: 8px;
            color: #4f29e2;
            font-size: 18px;
        }
    }

    &.popular {
        border: 2px solid #4f29e2;
        box-shadow: 0 4px 8px rgba(79, 41, 226, 0.2);
    }

    .popular-badge {
        position: absolute;
        top: -12px;
        right: -12px;
        background-color: #4f29e2;
        color: white;
        padding: 4px 8px;
        border-radius: 12px;
        font-size: 12px;
        font-weight: bold;
    }

    .md-button {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 36px; // Ensure consistent height when loading

        .md-progress-spinner {
            margin-right: 8px;
        }
    }
}
</style>
