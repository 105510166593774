<script>
import DiscoverySkillCard from '@/components/Skills/DiscoverySkillCard.vue';
import SkillsService from '@/services/skills/SkillsService';
import { mapGetters } from 'vuex';

export default {
    name: 'MainAppsShopifyRecommendedSkills',
    components: {
        DiscoverySkillCard,
    },
    data() {
        return {
            skills: [],
            loading: true,
            error: null,
        };
    },
    computed: {
        ...mapGetters('agentSettings', ['getSkills']),
    },
    methods: {
        async fetchSkills() {
            try {
                const response = await SkillsService.getSkills();
                this.skills = response.data.content.filter(
                    (skill) =>
                        skill.public === true && skill.tags.includes('shopify') && skill.internal_name !== 'Shopify',
                );
                this.loading = false;
            } catch (error) {
                console.error('Error fetching skills:', error);
                this.error = 'Failed to load skills. Please try again later.';
                this.loading = false;
            }
        },
        isSkillEnabled(skill) {
            return this.getSkills.some((enabledSkill) => enabledSkill.skill_id === skill.uuid);
        },
    },
    mounted() {
        this.fetchSkills();
    },
};
</script>

<template>
    <div class="recommended-skills md-layout">
        <div v-if="loading" class="recommended-skills__loading">Loading...</div>
        <div v-else-if="error" class="recommended-skills__error">{{ error }}</div>
        <div
            v-else
            v-for="skill in skills"
            class="md-layout-item md-size-25 md-large-size-33 md-medium-size-50 md-small-size-100 recommended-skills__container"
        >
            <DiscoverySkillCard :key="skill.uuid" :active="isSkillEnabled(skill)" :skill="skill" />
        </div>
    </div>
</template>

<style scoped>
.recommended-skills {
    width: 100%;
}

.recommended-skills__container {
    padding: 16px 16px 0 0;
}

.recommended-skills__loading,
.recommended-skills__error {
    text-align: center;
    padding: 20px;
}
</style>
