<script>
import TheNotFound from '@/components/TheNotFound.vue';

export default {
    name: 'NotFound',
    components: {
        TheNotFound,
    },
};
</script>

<template>
    <div>
        <TheNotFound></TheNotFound>
    </div>
</template>

<style scoped lang="scss"></style>
