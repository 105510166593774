<template>
    <div class="md-layout text-center">
        <div class="custom-forgot-panel md-layout md-layout-item md-size-70 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
                <div class="forgot-password__title-section">
                    <md-button @click="$router.back()" class="md-simple md-gray md-just-icon">
                        <i class="material-symbols-outlined"> arrow_back_ios </i>
                    </md-button>
                    <p class="md-display-1 forgot-password__text--left">
                        <strong> Forgot password? </strong>
                    </p>
                </div>
                <p class="md-subheading forgot-password__text--left">
                    Enter the email address you used when you joined and we'll send you instructions to reset your
                    password.
                </p>
            </div>

            <div class="custom-forgot-form md-layout-item md-size-100">
                <md-field>
                    <label>Email</label>
                    <md-input v-model="email" type="email"></md-input>
                </md-field>
                <div slot="errorField" class="md-caption text-danger" v-if="error">
                    {{ error }}
                </div>
            </div>

            <div class="md-layout-item md-size-100">
                <md-button :disabled="!isFormValid" class="md-primary md-block" v-on:click="handleReset">
                    <md-progress-spinner
                        v-if="loading"
                        class="md-white"
                        :md-diameter="12"
                        :md-stroke="2"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                    <span class="md-body-2">Send Reset Instructions</span>
                </md-button>
            </div>
        </div>
    </div>
</template>
<script>
import Firebase from '@/Firebase.js';

export default {
    name: 'forgotPassword',
    data() {
        return {
            email: null,
            error: '',
            loading: false,
            inputs: [
                {
                    icon: 'email',
                    name: 'Email...',
                    nameAttr: 'email',
                    type: 'email',
                    value: null,
                },
            ],
        };
    },
    computed: {
        isFormValid() {
            return this.email && this.isValidEmail(this.email);
        },
    },
    methods: {
        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        async handleReset() {
            this.error = '';
            this.loading = true;
            const { email } = this;

            if (!email) {
                this.error = 'All fields are required';
                return;
            }

            try {
                Firebase.sendPasswordResetEmail(email).then(() => {
                    this.showSuccessToast('Email sent');
                    this.$router.push({ name: 'Login' });
                });
            } catch (err) {
                this.error = 'An error occurred.';
                console.error(err);
            } finally {
                this.loading = false;
            }
        },
    },
    mounted() {
        // if (!this.$store.getters.getters['user/ifAuthenticated']) {
        //   this.$router.push({ name: "Dashboard" });
        // }
    },
};
</script>

<style lang="scss" scoped>
.social-button {
    color: #fff !important;
}

.custom-forgot-panel {
    padding-left: 0px;
    padding-right: 0px;

    // @media (max-width: 959px) {
    //   padding-left: 15px;
    //   padding-right: 15px;
    // }

    .custom-forgot-form {
        margin-top: 15px;
        margin-bottom: 30px;
    }
}

.custom-button {
    margin-right: 10px;
    /* Adds 10px space to the right of each button */
}

.custom-button:last-child {
    margin-right: 0;
    /* Removes the margin from the right side of the last button */
}

.forgot-password__text--left {
    text-align: left;
}

.md-progress-spinner {
    margin-right: 1rem;
}

.forgot-password__title-section {
    display: flex;
    align-items: center;
    justify-content: start;
}
</style>
