<script>
import Particles from './Particles.json';

export default {
    name: 'ParticlesBanner',
    data() {
        return {
            particles: Particles,
        };
    },
};
</script>

<template>
    <div>
        <vue-particles id="tsparticles" :options="particles" />
    </div>
</template>

<style scoped lang="scss"></style>
