<template>
    <div class="custom-main-panel">
        <div class="md-layout custom-content-panel">
            <div class="ps__layout-item--margin-bottom md-layout-item md-size-100">
                <p class="md-subheading">Setup the notifications configuration</p>
            </div>
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-content>
                        <p class="md-subheading settings-title">Conversation notifications</p>
                        <div class="notification-switches">
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title">Turn on all conversation notifications</span>
                                    <span class="switch-text">This turn on all the conversation notifications</span>
                                </div>
                                <md-switch
                                    v-model="allConversationNotificationActive"
                                    class="md-primary"
                                    @change="toggleAllCategoryTypes($event, 'conversation')"
                                >
                                </md-switch>
                            </div>
                            <md-divider></md-divider>
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title">
                                        Conversation negative sentiment detected</span
                                    >
                                    <span class="switch-text"
                                        >Notify when a conversation changes to a negative sentiment</span
                                    >
                                </div>
                                <md-checkbox
                                    v-model="activeNotifications.conversation.conversationSentimentChanged"
                                    class="md-primary"
                                >
                                </md-checkbox>
                            </div>
                            <md-divider></md-divider>
                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title"> New conversation received</span>
                                    <span class="switch-text">Notify when a new conversation is received</span>
                                </div>
                                <md-checkbox
                                    v-model="activeNotifications.conversation.conversationWindowCreated"
                                    class="md-primary"
                                >
                                </md-checkbox>
                            </div>
                            <md-divider></md-divider>

                            <div class="switch-container">
                                <div class="switch-info-container">
                                    <span class="switch-text switch-title"> Conversation updated</span>
                                    <span class="switch-text">Notify when a conversation is updated</span>
                                </div>
                                <md-checkbox
                                    v-model="activeNotifications.conversation.conversationWindowUpdated"
                                    class="md-primary"
                                >
                                </md-checkbox>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '../../../../services/account/userService';
import { mapActions, mapGetters } from 'vuex';
import updateEcommerceSettingsModal from '@/components/AccountSettings/updateEcommerceSettingsModal.vue';

export default {
    name: 'NotificationsSettings',
    components: {
        updateEcommerceSettingsModal,
    },
    data() {
        return {
            activeNotifications: {
                conversation: {
                    conversationSentimentChanged: false,
                    conversationWindowCreated: false,
                    conversationWindowUpdated: false,
                },
            },
            pushNotificationIds: [],
        };
    },
    computed: {
        ...mapGetters('user', {
            canAccessUser: 'canAccess',
            user: 'user',
        }),
        allConversationNotificationActive: {
            get() {
                return Object.values(this.activeNotifications.conversation).every((value) => value);
            },
            set(value) {
                Object.keys(this.activeNotifications.conversation).forEach((key) => {
                    this.activeNotifications.conversation[key] = value;
                });
            },
        },
    },
    watch: {
        activeNotifications: {
            handler: 'updateUserSettings',
            deep: true,
        },
    },
    methods: {
        ...mapActions('user', ['actionUpdateAccount', 'actionGetUser', 'updateNotificationsConfig']),
        getNotificationSettings() {
            const { activeNotifications, pushNotificationIds } = this.user.notifications_config;

            this.pushNotificationIds = [...pushNotificationIds];

            if (activeNotifications) {
                this.activeNotifications = JSON.parse(JSON.stringify(activeNotifications));
            }
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
        toggleAllCategoryTypes(event, category) {
            this.allConversationNotificationActive = event;
        },

        async updateUserSettings() {
            const updateUserSettingsConfig = {
                notificationsConfig: {
                    pushNotificationIds: this.pushNotificationIds,
                    activeNotifications: this.activeNotifications,
                },
            };

            await userService.editUser(this.user.uuid, updateUserSettingsConfig);

            this.updateNotificationsConfig({
                pushNotificationIds: [...this.pushNotificationIds],
                activeNotifications: JSON.parse(JSON.stringify(this.activeNotifications)),
            });
        },
    },
    setup() {
        this.getNotificationSettings();
    },
    mounted() {
        this.getNotificationSettings();
    },
};
</script>

<style lang="scss" scoped>
.settings-title {
    margin-top: 5px !important;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    p {
        margin-top: 24px;
    }

    .md-card {
        margin-top: 0px;
    }
}

.notification-switches {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.switch-title {
    font-weight: bold;
}

.switch-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.switch-info-container {
    display: flex;
    flex-direction: column;
}

.switch-text {
    margin-left: 10px;
    color: #000;
}

.child-notification-type {
    margin-left: 10px;
}
</style>
