// stripeService.js
import axios from 'axios';
import store from '@/store'; // Update the path to match your project structure

// Configure Axios specifically for Stripe API interactions
const stripeApi = axios.create({
    baseURL: 'https://api.stripe.com/v1',
    headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRIPE_APIKEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

const stripeService = {
    async fetchCustomer() {
        const stripe_customer_id = store.getters['membership/membership'].customer_id;
        if (!stripe_customer_id) {
            // Handle the case where the customer ID is not available
            console.error('No customer ID available');
            return;
        }

        try {
            const params = new URLSearchParams();
            params.append('expand[]', 'subscriptions');

            // Make the API call to Stripe
            const response = await stripeApi.get(`/customers/${stripe_customer_id}`, {
                params: params,
            });
            const stripeCustomer = response.data;

            // Commit the response data to Vuex store
            store.commit('membership/SET_STRIPE_CUSTOMER_STATE', stripeCustomer);

            // Return the fetched customer data
            return stripeCustomer;
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.warn('Customer not found in Stripe');
            } else {
                throw error;
            }
        }
    },

    async fetchCheckoutSession(sessionId) {
        if (!sessionId) {
            console.error('No sessionId available');
            return;
        }

        try {
            const response = await stripeApi.get(`/checkout/sessions/${sessionId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching Checkout Session:', error);
            throw error;
        }
    },

    async fetchProduct(productId) {
        if (!productId) {
            throw new Error('No product ID provided');
        }

        try {
            // Make the API call to Stripe to fetch the product
            const response = await stripeApi.get(`/products/${productId}`);
            const product = response.data;

            // Return the fetched product data
            return product;
        } catch (error) {
            // Log and rethrow the error for the calling component to handle
            console.error('Error fetching Stripe product:', error);
            throw error;
        }
    },

    async createCustomerPortal() {
        const stripe_customer_id = store.getters['membership/membership'].customer_id;
        if (!stripe_customer_id) {
            throw new Error('No customer ID available');
        }

        try {
            const params = new URLSearchParams();
            params.append('customer', stripe_customer_id);
            // Append any additional parameters if needed

            // Make the API call to Stripe
            const response = await stripeApi.post('/billing_portal/sessions', params);

            // Handle the response, e.g., return the session URL
            return response.data.url;
        } catch (error) {
            console.error('Error creating Stripe Customer Portal session:', error);
            throw error;
        }
    },

    async createCancelLink() {
        const stripe_customer_id = store.getters['membership/membership'].customer_id;
        if (!stripe_customer_id) {
            throw new Error('No customer ID available');
        }

        try {
            const params = new URLSearchParams();
            params.append('customer', stripe_customer_id);
            params.append('flow_data[type]', 'subscription_cancel');
            params.append(
                'flow_data[subscription_cancel][subscription]',
                store.getters['membership/stripeCustomer'].subscriptions.data[0].id,
            );
            // Append any additional parameters if needed

            // Make the API call to Stripe
            const response = await stripeApi.post('/billing_portal/sessions', params);

            // Handle the response, e.g., return the session URL
            return response.data.url;
        } catch (error) {
            console.error('Error creating Stripe Customer Portal session:', error);
            throw error;
        }
    },

    async createUpdatePlanLink() {
        const stripe_customer_id = store.getters['membership/membership'].customer_id;
        if (!stripe_customer_id) {
            throw new Error('No customer ID available');
        }

        try {
            const params = new URLSearchParams();
            params.append('customer', stripe_customer_id);
            params.append('flow_data[type]', 'subscription_update');
            params.append(
                'flow_data[subscription_update][subscription]',
                store.getters['membership/stripeCustomer'].subscriptions.data[0].id,
            );
            // Append any additional parameters if needed

            // Make the API call to Stripe
            const response = await stripeApi.post('/billing_portal/sessions', params);

            // Handle the response, e.g., return the session URL
            return response.data.url;
        } catch (error) {
            console.error('Error creating Stripe Customer Portal session:', error);
            throw error;
        }
    },

    async getAvailablePack() {
        try {
            // Retrieve the PlanId from the Vuex store getter
            const planIdToFind = store.getters['membership/findProductId'];

            // Set the query parameters
            const params = new URLSearchParams();
            params.append('active', 'true');

            // Make the API call to Stripe to fetch all products with active set to true
            const response = await stripeApi.get('/products', { params: params });
            const products = response.data.data;

            // Find the product with the matching PlanId in metadata
            const matchingProduct = products.find((product) => product.metadata.plan_id === planIdToFind);

            // Check if a matching product was found and return the default_price
            if (matchingProduct) {
                return matchingProduct.default_price; // Return the default_price
            } else {
                return null; // Return null or appropriate default value if no match is found
            }
        } catch (error) {
            // Log and rethrow the error for the calling component to handle
            console.error('Error getting available pack:', error);
            throw error;
        }
    },

    async getPackPaymentLink(priceId, quantity = 1) {
        try {
            const params = new URLSearchParams();
            params.append('mode', 'payment');
            params.append('payment_method_types[]', 'card');
            params.append('customer', store.getters['membership/membership'].customer_id);
            params.append('line_items[0][price]', priceId);
            params.append('line_items[0][quantity]', quantity);

            let currentDomain = window.location.hostname;
            let successUrl = 'https://' + currentDomain + '/#/settings/billing';
            params.append('success_url', successUrl);

            // Create a new Stripe Checkout Session for the payment
            const response = await axios.post('https://api.stripe.com/v1/checkout/sessions', params, {
                headers: {
                    Authorization: `Bearer ${process.env.VUE_APP_STRIPE_APIKEY}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            // Return the URL to redirect to for payment
            return response.data.url;
        } catch (error) {
            console.error('Error creating Stripe Checkout session:', error);
            throw error;
        }
    },

    createCustomerSesion() {
        const params = new URLSearchParams();
        params.append('customer', store.getters['membership/membership'].customer_id);
        params.append('components[pricing_table][enabled]', 'true');

        return stripeApi.post('https://api.stripe.com/v1/customer_sessions', params);
    },
};

export default stripeService;
