<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div class="custom-tool-bar md-layout-item md-size-100"></div>
            <!-- <div class="md-layout-item md-size-100">
        <salesWidgetBar/>
      </div> -->
            <div class="md-layout-item md-size-100">
                <md-card class="s__custom-card">
                    <md-card-content>
                        <div v-if="!isEmpty">
                            <md-table
                                :value="queriedData"
                                class="paginated-table"
                                :md-sort.sync="currentSort"
                                :md-sort-order.sync="currentSortOrder"
                            >
                                <md-table-toolbar>
                                    <md-field>
                                        <label for="pages">Per page</label>
                                        <md-select v-model="pagination.perPage" name="pages">
                                            <md-option
                                                v-for="item in pagination.perPageOptions"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                                {{ item }}
                                            </md-option>
                                        </md-select>
                                    </md-field>

                                    <md-field>
                                        <md-input
                                            type="search"
                                            class="mb-3"
                                            clearable
                                            style="width: 200px"
                                            placeholder="Search customers"
                                            v-model="searchQuery"
                                        >
                                        </md-input>
                                    </md-field>
                                </md-table-toolbar>

                                <md-table-row slot="md-table-row" slot-scope="{ item }">
                                    <md-table-cell md-label="Customer name">
                                        <p class="md-body-1">{{ customerFullName(item) }}</p>
                                    </md-table-cell>
                                    <md-table-cell md-label="Customer email">
                                        <p class="md-body-1">{{ item.customer ? item.customer.email : 'Guest' }}</p>
                                    </md-table-cell>
                                    <md-table-cell md-label="Customer Phone">
                                        <p class="md-body-1">{{ item.customer ? customerphone(item) : 'None' }}</p>
                                    </md-table-cell>
                                    <md-table-cell md-label="Creation" md-sort-by="date_created">
                                        <p class="md-body-1">{{ formatDate(item.date_created) }}</p>
                                    </md-table-cell>
                                    <md-table-cell md-label="Status" md-sort-by="sale_state">
                                        <md-field class="custom-role-field">
                                            <md-select
                                                :disabled="item.email === $store.state.user.user.email"
                                                v-model="item.sale_state"
                                                name="state"
                                                id="state"
                                                @md-selected="changeState(item)"
                                            >
                                                <md-option v-for="state in customerStates" :value="state">{{
                                                    state
                                                }}</md-option>
                                            </md-select>
                                        </md-field>
                                    </md-table-cell>
                                    <md-table-cell class="custom-icons" md-label="">
                                        <md-button
                                            class="md-just-icon md-info md-simple"
                                            @click.ctrl.exact="onCtrlClick(item)"
                                            @click.middle.exact="onCtrlClick(item)"
                                            @click.meta.exact="onCtrlClick(item)"
                                            @click.left.exact="goToChat(item)"
                                        >
                                            <md-icon>question_answer</md-icon>
                                            <md-tooltip md-direction="top">See conversation</md-tooltip>
                                        </md-button>
                                        <md-button
                                            :disabled="!canAccessUser(['ADMIN'])"
                                            class="md-just-icon md-danger md-simple"
                                            @click.native="handleDelete(item)"
                                        >
                                            <md-icon>delete</md-icon>
                                            <md-tooltip md-direction="top">Delete</md-tooltip>
                                        </md-button>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table>
                        </div>

                        <div v-else>
                            <md-empty-state
                                md-label="No Sales Found"
                                md-description="Don't let your sales table stay empty. Leverage the power of Lixsa to attract and engage customers effortlessly."
                            >
                            </md-empty-state>
                        </div>
                    </md-card-content>
                    <md-card-actions v-if="!isEmpty" md-alignment="space-between">
                        <div class="">
                            <p class="card-category">
                                Showing {{ from + 1 }} to {{ to }} of {{ totalEntries }} entries
                            </p>
                        </div>
                        <pagination
                            class="pagination-no-border pagination-success"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="totalEntries"
                        >
                        </pagination>
                    </md-card-actions>
                </md-card>
            </div>
        </div>
    </div>
</template>
<script>
import { Pagination } from '@/components';
// import salesWidgetBar from '@/components/Sales/salesWidgetBar'

import salesService from '@/services/account/salesService.js';
import Swal from 'sweetalert2';

// Third party
import Fuse from 'fuse.js';
import { mapGetters } from 'vuex';

export default {
    name: 'Sales',
    components: {
        Pagination,
        // salesWidgetBar,
    },
    data() {
        return {
            currentSort: 'date_created',
            currentSortOrder: 'desc',
            searchQuery: '',
            propsToSearch: ['customer.email', 'customer.first_name', 'customer.last_name'],
            tableData: [], // Empty as data will be fetched from the API
            searchedData: [],
            fuseSearch: null,
            totalEntries: 0,
            totalPages: 0,
            numberOfElements: 1, // Actual number of elements per page that came from request.
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [10, 20, 40, 80, 100, 200, 400, 800, 1000],
                total: 0,
            },

            customerStates: [
                'STARTED_SALE',
                'CLOSED_SALE',
                'CONFIRMED_SALE',
                'REJECTED_SALE',
                'SHIPPING_ADDRESS_UPDATED',
                'PRODUCTS_UPDATED',
                'CUSTOMER_UPDATED',
                'CONFIRMED_PRODUCTS',
            ],

            isLoading: false, //Controls loading spinner.
            isEmpty: true,
        };
    },
    computed: {
        ...mapGetters({
            canAccessUser: 'user/canAccess',
        }),
        queriedData() {
            let result = this.tableData;
            if (this.searchQuery != '' && this.searchedData.length > 0) {
                result = this.searchedData;
            }

            return result.slice(0, this.numberOfElements);
        },
        to() {
            return Math.min(this.pagination.currentPage * this.pagination.perPage, this.pagination.total);
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
    },
    methods: {
        customerphone(sale) {
            if (
                sale.customer === null ||
                sale.customer.default_address == null ||
                sale.customer.default_address.phone == null
            ) {
                return 'None';
            }

            return sale.customer.default_address.phone;
        },
        dateCreated(sale) {
            if (sale.date_created == null) {
                return 'None';
            }

            return this.formatDate(sale.date_created);
        },
        customerFullName(sale) {
            if (sale === null || sale.customer === null) {
                return 'Guest';
            }

            return sale.customer.first_name + ' ' + sale.customer.last_name;
        },
        formatDate(timestamp) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(timestamp * 1000); // convert to milliseconds
            return date.toLocaleDateString('en-US', options); // adjust 'en-US' as per your need
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
        goToChat(sale) {
            this.$router.push({ name: 'Customer Support', query: { search: sale.conversation } });
        },
        onCtrlClick(sale) {
            const conversationPath = this.$router.resolve({
                name: 'Customer Support',
                query: { search: sale.conversation },
            });
            window.open(conversationPath.href, '_blank');
        },
        async fetchTableData() {
            try {
                this.isLoading = true;
                const response = await salesService.fetchSales(
                    this.pagination.currentPage - 1,
                    this.pagination.perPage,
                );
                if (response.status === 200 && response.data) {
                    this.tableData = response.data.content;
                    this.totalEntries = response.data.totalElements;
                    this.totalPages = response.data.totalPages;
                    this.numberOfElements = response.data.numberOfElements;
                    this.pagination.total = response.data.totalElements;
                    this.isEmpty = response.data.empty;
                    // Re-initialize fuse with the new data
                    this.fuseSearch = new Fuse(this.tableData, {
                        keys: this.propsToSearch,
                        threshold: 0.3,
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async changeState(sale) {
            try {
                salesService.updateSaleState(sale.uuid, sale.sale_state);
                this.showSuccessToast('Sale status changed');
            } catch (error) {
                console.error("Error changing sale's sate: ", error);
                this.showErrorToast("Error changing sale's sate");
            }
        },
        handleDelete(sale) {
            if (!this.canAccessUser(['ADMIN'])) {
                this.showErrorToast("You don't have permission to perform this action");
                return;
            }
            Swal.fire({
                title: 'Are you sure?',
                text: `You won't be able to revert this!`,
                type: 'warning',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4caf50',
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteRow(sale);
                    this.showSuccessToast('Sale deleted');
                }
            });
        },
        deleteRow(sale) {
            let indexToDelete = this.tableData.findIndex((tableRow) => tableRow.id === sale.id);
            if (indexToDelete >= 0) {
                this.tableData.splice(indexToDelete, 1);
                salesService
                    .deleteSale(sale.uuid)
                    .then(() => {
                        this.fetchTableData();
                    })
                    .catch((error) => {
                        console.error('An error occurred while deleting the sale:', error);
                    });
            }
        },
    },
    mounted() {
        // Initial data fetch
        this.fetchTableData();
    },
    watch: {
        searchQuery(value) {
            let result = this.tableData;
            if (value !== '') {
                const searchResults = this.fuseSearch.search(this.searchQuery);
                result = searchResults.map((res) => res.item);
            }
            this.searchedData = result;
        },

        'pagination.currentPage': function () {
            // Fetch new page data when the current page changes
            this.fetchTableData();
        },
        'pagination.perPage': function () {
            // Fetch new data when the per page changes
            this.fetchTableData();
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.s__custom-card {
    margin-top: 0px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.custom-tool-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom-icons {
    padding-right: 35px !important;

    .md-button {
        margin-right: 6px;
    }
}
</style>
