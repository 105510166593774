<script>
import { mapActions } from 'vuex';
import CodeSnippet from '@/components/codeSnippet.vue';

export default {
    name: 'MessageMetadataInspector',
    props: ['message'],
    components: { CodeSnippet },
    data() {
        return {
            localMessage: this.message,
        };
    },
    watch: {
        message: {
            handler(newValue) {
                this.localMessage = newValue;
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),
    },
};
</script>

<template>
    <div class="message-metadata-inspector">
        <div class="message-metadata-inspector__close_bar">
            <md-button @click="closeModal()" class="md-just-icon"><md-icon>close</md-icon></md-button>
        </div>
        <md-dialog-content>
            <p class="md-title">Message Metadata Inspector</p>
            <code-snippet :code="localMessage" language="language-json" />
        </md-dialog-content>
    </div>
</template>

<style lang="scss" scoped>
.message-metadata-inspector {
    max-width: 800px;
    max-height: 800px;
    overflow: auto;
    &__close_bar {
        text-align: end;
    }
}
</style>
