<template>
    <CustomerAssetsCardEcommerceOrder
        v-if="order && assetType === 'Orders'"
        :order="order"
        :show-details-order="showDetailsOrder"
        @toggle-details-order="toggleShowDetailsOrder(selectedAsset)"
    ></CustomerAssetsCardEcommerceOrder>

    <CustomerAssetsCardEcommerceDraftOrder
        v-else-if="order && assetType === 'DraftOrders'"
        :order="order"
        :show-details-order="showDetailsOrder"
        @toggle-details-order="toggleShowDetailsOrder(selectedAsset)"
    ></CustomerAssetsCardEcommerceDraftOrder>

    <CustomerAssetsCardHostelryBooking
        v-else-if="order && assetType === 'Bookings'"
        :order="order"
        :show-details-order="showDetailsOrder"
        @toggle-details-order="toggleShowDetailsOrder(selectedAsset)"
    ></CustomerAssetsCardHostelryBooking>
</template>
<script>
import CustomerAssetsCardEcommerceOrder from './CustomerAssetsCardEcommerceOrder/CustomerAssetsCardEcommerceOrder.vue';
import CustomerAssetsCardEcommerceDraftOrder from './CustomerAssetsCardEcommerceDraftOrder/CustomerAssetsCardEcommerceDraftOrder.vue';
import CustomerAssetsCardHostelryBooking from './CustomerAssetsCardHostelryBooking/CustomerAssetsCardHostelryBooking.vue';
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsCard',
    components: {
        CustomerAssetsCardEcommerceOrder,
        CustomerAssetsCardEcommerceDraftOrder,
        CustomerAssetsCardHostelryBooking,
        SvgIcon,
    },
    props: {
        order: { type: [Object], required: true },
        showDetailsOrder: { type: [Boolean], required: true },
        assetType: { type: [String], required: true },
    },
    methods: {
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order', this.order);
        },
        getBadgeClass,
        getCurrencySymbol,
        formatNumberWithDecimals,
    },
};
</script>
