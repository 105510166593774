import { render, staticRenderFns } from "./cancelSubscriptionShopify.vue?vue&type=template&id=545d7196&scoped=true"
import script from "./cancelSubscriptionShopify.vue?vue&type=script&lang=js"
export * from "./cancelSubscriptionShopify.vue?vue&type=script&lang=js"
import style0 from "./cancelSubscriptionShopify.vue?vue&type=style&index=0&id=545d7196&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545d7196",
  null
  
)

export default component.exports