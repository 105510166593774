// fineTunningService.js

import axios from 'axios';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

const baseURL = `${process.env.VUE_APP_API_URL}`;
// const getAuthHeader = (token) => ({ Authorization: `Bearer ${token}` });
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const fineTuningService = {
    fetchFineTuningScenarios(page = 0, size = 20) {
        const params = { page, size, sort: 'dateCreated,desc' };
        return apiRequest('get', 'fine-tuning-scenarios', null, params);
    },

    createFineTuningScenario(createData) {
        const requestData = { uuid: uuidv4(), account_id: store.state.user.user.account_id, ...createData };
        return apiRequest('post', 'fine-tuning-scenarios', requestData).then((response) => ({ response, requestData }));
    },

    getFineTuningScenario(fineTuningScenaryId) {
        return apiRequest('get', `fine-tuning-scenarios/${fineTuningScenaryId}`);
    },

    updateFineTuningScenario(fineTuningScenaryId, updateData) {
        return apiRequest('post', `fine-tuning-scenarios/${fineTuningScenaryId}`, updateData);
    },

    deleteFineTuningScenario(fineTuningScenaryId) {
        return apiRequest('delete', `fine-tuning-scenarios/${fineTuningScenaryId}`);
    },
};

export default fineTuningService;
