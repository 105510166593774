import Vue from 'vue';
import ChatWindow from './ChatWindow.vue';

export const VueAdvancedChat = Vue.component('vue-advanced-chat', ChatWindow);
const PACKAGE_NAME = 'vue-advanced-chat';

export function register() {
    if (!customElements.get(PACKAGE_NAME)) {
        customElements.define(PACKAGE_NAME, VueAdvancedChat);
    }
}
