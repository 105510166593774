import axios from 'axios';
import store from '@/store';

const baseURL = `${process.env.VUE_APP_API_URL}`;
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const shopifyService = {
    getShopThemes() {
        return apiRequest('get', 'shopify-themes');
    },

    isChatBubbleInstalled(themeId) {
        return apiRequest('get', `shopify-themes/${themeId}/chat-bubble`);
    },
};

export default shopifyService;
