<script>
import { mapGetters } from 'vuex';
import CatalogServiceV2 from '@/services/productCatalog/CatalogServiceV2';
import businessKnowledgeService from '@/services/account/businessKnowledgesService';

export default {
    name: 'SyncStatusCard',
    data() {
        return {
            catalogLastUpdated: null,
            catalogItemCount: 0,
            businessKnowledgeLastUpdated: null,
            businessKnowledgeItemCount: 0,
        };
    },
    computed: {
        ...mapGetters('agentSettings', ['getSkillByInternalName']),
        catalogSkillEnabled() {
            return this.getSkillByInternalName('catalog_tool') !== null;
        },
    },
    mounted() {
        this.fetchSyncStatus();
    },
    methods: {
        async fetchSyncStatus() {
            const fetchWithFallback = async (promise) => {
                try {
                    const response = await promise;
                    return response.data;
                } catch (error) {
                    console.error('Error fetching data:', error);
                    return null;
                }
            };

            this.catalogLastUpdated = await fetchWithFallback(CatalogServiceV2.getLastTrainedDate());
            this.catalogItemCount = await fetchWithFallback(CatalogServiceV2.getItemsCount());
            this.businessKnowledgeLastUpdated = await fetchWithFallback(
                businessKnowledgeService.getLastUpdatedTimestamp(),
            );
            this.businessKnowledgeItemCount = await fetchWithFallback(
                businessKnowledgeService.getBusinessKnowledgesCount(),
            );
        },
        formatDate(timestamp) {
            if (!timestamp) return 'N/A';
            return new Date(timestamp * 1000).toLocaleString();
        },
        formatCount(count) {
            return count === null || count === 0 ? 'Unknown' : count;
        },
    },
};
</script>

<template>
    <md-card class="sync-status-card">
        <md-card-header class="sync-status-card__header">
            <div class="sync-status-card__title-container">
                <div class="sync-status-card__title md-title"><strong>Shop Items Status</strong></div>
                <md-button class="md-primary md-simple md-sm md-just-icon" @click="fetchSyncStatus">
                    <i class="material-symbols-outlined"> sync </i>
                </md-button>
            </div>
        </md-card-header>
        <md-card-content class="sync-status-card__content">
            <div v-if="catalogSkillEnabled" class="sync-status-card__item">
                <div class="sync-status-card__item-header">
                    <div class="sync-status-card__item-title">Catalog</div>
                    <router-link to="/catalog" custom v-slot="{ navigate }">
                        <a
                            @click="navigate"
                            @keypress.enter="navigate"
                            role="link"
                            class="sync-status-card__item-link md-body-1"
                            >View</a
                        >
                    </router-link>
                </div>
                <div class="sync-status-card__item-info">
                    Last Updated:
                    <span class="sync-status-card__item-info--highlight">{{ formatDate(catalogLastUpdated) }}</span>
                </div>
                <div class="sync-status-card__item-info">
                    Items Synced:
                    <span class="sync-status-card__item-info--highlight">{{ formatCount(catalogItemCount) }}</span>
                </div>
            </div>
            <div v-else class="sync-status-card__item sync-status-card__item--disabled">
                <div class="sync-status-card__item-title">Catalog</div>
                <p class="sync-status-card__message md-body-1">
                    You need to enable the catalog skill to view this information.
                </p>
                <md-button
                    class="md-primary md-raised md-sm"
                    @click="$router.push('/skills/details/796fca14-e437-404c-94bd-7bb97cca320c')"
                >
                    Enable Catalog Skill
                </md-button>
            </div>
            <div class="sync-status-card__item">
                <div class="sync-status-card__item-header">
                    <div class="sync-status-card__item-title">Business Knowledge</div>
                    <router-link to="/training/business-knowledge" custom v-slot="{ navigate }">
                        <a
                            @click="navigate"
                            @keypress.enter="navigate"
                            role="link"
                            class="sync-status-card__item-link md-body-1"
                            >View</a
                        >
                    </router-link>
                </div>
                <div class="sync-status-card__item-info">
                    Last Updated:
                    <span class="sync-status-card__item-info--highlight">{{
                        formatDate(businessKnowledgeLastUpdated)
                    }}</span>
                </div>
                <div class="sync-status-card__item-info">
                    Items Synced:
                    <span class="sync-status-card__item-info--highlight">{{
                        formatCount(businessKnowledgeItemCount)
                    }}</span>
                </div>
            </div>
        </md-card-content>
    </md-card>
</template>

<style scoped>
.sync-status-card {
    margin-bottom: 20px;
    border-radius: 8px;
}

.sync-status-card__header {
    padding: 16px 16px 0 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.sync-status-card__content {
    padding: 20px;
}

.sync-status-card__item {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
}

.sync-status-card__item:last-child {
    margin-bottom: 0;
}

.sync-status-card__item-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #444;
}

.sync-status-card__item-info {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 5px;
}

.sync-status-card__item-info--highlight {
    font-weight: 500;
    color: #2c3e50;
}

.sync-status-card__title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.sync-status-card__item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.sync-status-card__item-link {
    cursor: pointer;
    font-size: 0.9rem;
    margin-bottom: 10px;
    color: #1976d2;
    text-decoration: none;
}

.sync-status-card__item-link:hover {
    text-decoration: underline;
}

.sync-status-card__item--disabled {
    background-color: #f5f5f5;
    border: 1px dashed #ccc;
}

.sync-status-card__message {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 15px;
}
</style>
