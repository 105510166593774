<template>
    <div v-if="selectedAsset">
        <div class="details-bar" @click="toggleShowInfoCustomer">
            <svg-icon name="arrowBackAlt" />
            <span class="details-title">Back to customer details</span>
        </div>

        <div class="booking-details-header-row">
            <span class="md-subheading"
                ><strong>Booking #{{ selectedAsset.external_asset_id }}</strong></span
            >
            <span
                :class="['order-badges', `badge-${selectedAsset.status.toLowerCase()}`]"
                class="booking-details-badge"
            >
                {{ selectedAsset.status.toLocaleUpperCase() }}
            </span>
        </div>

        <md-divider class="section-divider"></md-divider>

        <CustomerAssetsContactInformation
            :selectedAsset="selectedAsset"
            :currentUserName="currentUserName"
            :currentUserEmail="currentUserEmail"
            :currentUserPhone="currentUserPhone"
        ></CustomerAssetsContactInformation>

        <md-divider class="section-divider"></md-divider>

        <customer-assets-summary :selected-asset="selectedAsset"></customer-assets-summary>

        <md-divider class="section-divider"></md-divider>

        <div class="card-details-body">
            <div class="details-badges"></div>
            <div v-if="selectedAsset.date_created" class="card-details-entry">
                <div class="md-body-2">Created at</div>

                <div class="details-badges">
                    <span class="details-created-at">{{ friendlyCreatedAt }}</span>
                </div>
            </div>

            <div v-if="selectedAsset.cancellation_date" class="card-details-entry">
                <div class="md-body-2">Canceled at</div>

                <div class="details-badges">
                    <span class="details-created-at">{{ friendlyCanceledAt }}</span>
                </div>
            </div>

            <div class="details-badges" v-if="selectedAsset.comments">
                <div class="md-body-2">Comments</div>
                <div class="card-notes-box">
                    <div class="card-notes">
                        <div class="notes-info">
                            <span>{{ selectedAsset.comments }}</span>
                        </div>
                    </div>
                    <div class="order-currency"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CustomerAssetsContactInformation from '@/lib/CustomerDetails/CustomerCardBody/CustomerAssets/CustomerAssetsDetails/CustomerAssetsContactInformation/CustomerAssetsContactInformation.vue';
import CustomerAssetsSummary from '@/lib/CustomerDetails/CustomerCardBody/CustomerAssets/CustomerAssetsSummary/CustomerAssetsSummary.vue';
import SvgIcon from '@/components/Chat/SvgIcon/SvgIcon.vue';
import { getCurrencySymbol, formatNumberWithDecimals, getBadgeClass } from '@/utils/common.js';

export default {
    name: 'CustomerAssetsDetailsHostelryBooking',
    components: {
        CustomerAssetsContactInformation,
        CustomerAssetsSummary,
        SvgIcon,
    },
    props: {
        selectedAsset: { type: [Object, Array] },
        currentUserId: { type: [String, Number], required: true },
        currentUserName: { type: String, required: false, default: undefined },
        currentUserEmail: { type: String, required: false, default: undefined },
        currentUserPhone: { type: String, required: false, default: undefined },
        showDetailsOrder: { type: [Boolean], required: true },
    },
    methods: {
        getCurrencySymbol,
        formatNumberWithDecimals,
        getBadgeClass,
        toggleShowDetailsOrder() {
            this.$emit('toggle-details-order');
        },
        toggleShowInfoCustomer() {
            this.$emit('toggle-info-customer');
        },
        formatDate(timestamp) {
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            const date = new Date(timestamp * 1000); // convert to milliseconds
            return date.toLocaleDateString('en-US', options); // adjust 'en-US' as per your need
        },
    },
    computed: {
        friendlyCreatedAt() {
            const formatedDate = this.formatDate(new Date(this.selectedAsset.date_created * 1000));
            return formatedDate;
        },
        friendlyCanceledAt() {
            const formatedDate = this.formatDate(new Date(this.selectedAsset.cancellation_date * 1000));
            return formatedDate;
        },
    },
};
</script>
