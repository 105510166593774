<template>
    <div class="custom-main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout custom-content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div v-if="canSave" class="md-layout-item md-size-100">
                <div class="custom-actions">
                    <md-button
                        v-if="!isEditing"
                        :disabled="!canSave"
                        @click="updateProfile()"
                        class="md-raised md-primary"
                        >Save</md-button
                    >
                    <md-progress-spinner
                        v-else
                        :md-diameter="20"
                        :md-stroke="3"
                        class="md-white"
                        md-mode="indeterminate"
                    ></md-progress-spinner>
                </div>
            </div>
            <div class="ps__layout-item--margin-bottom md-layout-item md-size-100">
                <p class="md-subheading">Profile Information</p>
            </div>
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-content>
                        <div class="md-layout">
                            <div class="md-layout-item md-size-100">
                                <md-field class="custom-field">
                                    <label>Username</label>
                                    <md-input v-model="userData.username" type="text"></md-input>
                                    <span class="md-helper-text">The username associated with this profile</span>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-100">
                                <md-field class="custom-field">
                                    <label>Email</label>
                                    <md-input disabled v-model="userData.email" type="text"></md-input>
                                    <span class="md-helper-text">The email associated with this profile</span>
                                </md-field>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>
            </div>
            <div class="ps__layout-item--margin-bottom md-layout-item md-size-100">
                <p class="md-subheading">Password Management</p>
            </div>
            <div class="md-layout-item md-size-100">
                <md-card>
                    <md-card-content>
                        <p style="margin-top: 0px" class="md-body-1">
                            For security, click below to receive an email link to change your password.
                        </p>
                        <md-button @click="passwordResetEmail" class="md-primary">Change password</md-button>
                    </md-card-content>
                </md-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Firebase from '@/Firebase.js';
import userService from '@/services/account/userService';

export default {
    name: 'ProfileSettings',
    data() {
        return {
            buttonDisabled: true,
            defaultUserData: {},
            userData: {},

            isLoading: false,
            isEditing: false,
        };
    },
    computed: {
        isEdited() {
            return this.userData.username !== this.defaultUserData.username;
        },
        canSave() {
            return this.isEdited && this.userData.username;
        },
    },
    methods: {
        ...mapActions('user', [
            'actionUpdateUser', // Action from the 'user' module
        ]),
        async fetchUserData() {
            this.isLoading = true;
            try {
                const accData = await userService.fetchUser(this.$store.state.user.user.uuid);
                if (accData) {
                    this.userData = { ...accData.data };
                    this.defaultUserData = { ...accData.data };
                }
            } catch (err) {
                const errorMessage = err.response || 'An error occurred.';
                console.error('Error:', errorMessage);
            } finally {
                this.isLoading = false;
            }
        },
        async updateProfile() {
            this.isEditing = true;
            try {
                const req = {
                    user_id: this.userData.uuid,
                    username: this.userData.username,
                };
                const response = await this.actionUpdateUser(req);
                if ((response && response.status === 200) || response.status === 201) {
                    this.showSuccessToast('User profile updated');
                    this.fetchUserData();
                }
            } catch (error) {
                const errorMessage = err.response || 'An error occurred.';
                console.error('Error:', errorMessage);
                this.showErrorToast('User updating profile');
            } finally {
                this.isEditing = false;
            }
        },
        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },
        showErrorToast(msg) {
            this.$toasted.error(msg, {
                position: 'bottom-center',
                icon: 'error',
                duration: 3000,
            });
        },
        passwordResetEmail() {
            try {
                const currentUserEmail = this.$store.state.user.user.email;
                Firebase.sendPasswordResetEmail(currentUserEmail);
                this.showSuccessToast('Email Sent. Check your inbox');
            } catch (error) {
                this.showErrorToast('Error sendig pasword reset email: '.error);
            }
        },
    },
    mounted() {
        this.fetchUserData(); // Call the method when the component is mounted
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(238, 238, 238, 0.5);
    z-index: 10;
    cursor: not-allowed;
}

.custom-main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.custom-content-panel {
    margin-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 1278px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 959px) {
        padding-left: 0px;
        padding-right: 0px;
    }

    p {
        margin-top: 24px;
    }

    .md-card {
        margin-top: 0px;
    }

    .md-card .md-card-content .md-field {
        margin-bottom: 30px;
    }

    .custom-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

.custom-field {
    margin-top: 20px;
    margin-bottom: 20px;
}

.ps__layout-item--margin-bottom {
    margin-bottom: 15px;
}
</style>
