<script>
import MySkillsItemCard from '@/components/Skills/MySkillsItemCard.vue';
import AgentSettingsService from '@/services/account/agentSettingsService';
import SkillsService from '@/services/skills/SkillsService';

export default {
    name: 'SkillsMySkills',
    components: { MySkillsItemCard },
    data() {
        return {
            accountSkills: [],
            isLoading: true,
            specialCategories: ['main app', 'channel'],
        };
    },
    computed: {
        skillsList() {
            if (this.accountSkills.length === 0) return [];
            return this.accountSkills.filter(
                (item) => !item.categories.some((category) => this.specialCategories.includes(category)),
            );
        },

        channelsList() {
            if (this.accountSkills.length === 0) return [];
            return this.accountSkills.filter((item) => item.categories.includes('channel'));
        },

        mainAppsList() {
            if (this.accountSkills.length === 0) return [];
            return this.accountSkills.filter((item) => item.categories.includes('main app'));
        },

        installedSkillsCounter() {
            return this.skillsList.length;
        },

        installedChannelsCounter() {
            return this.channelsList.length;
        },

        installedMainAppsCounter() {
            return this.mainAppsList.length;
        },
    },
    methods: {
        async getAccountSkills() {
            this.isLoading = true;
            try {
                const response = await AgentSettingsService.getAgentSettings();
                if (response.data.skills) {
                    this.getSkillsInformation([...response.data.skills]);
                }
            } catch (error) {
                console.error('Error fetching account skills: ', error);
            } finally {
                this.isLoading = false;
            }
        },

        async getSkillsInformation(agentSettingsSkills) {
            for (const agentSkill of agentSettingsSkills) {
                const getSkillResponse = await SkillsService.getSkill(agentSkill.skill_id);
                this.accountSkills.push(getSkillResponse.data);
            }
        },
    },
    mounted() {
        this.getAccountSkills();
    },
};
</script>

<template>
    <div>
        <md-card>
            <md-card-header>
                <div class="md-subheading">Main Apps ({{ installedMainAppsCounter }})</div>
            </md-card-header>
            <md-card-content>
                <md-progress-spinner v-if="isLoading" class="md-primary" md-mode="indeterminate"></md-progress-spinner>
                <MySkillsItemCard
                    v-else-if="mainAppsList.length > 0"
                    v-for="mainApp in mainAppsList"
                    :skill="mainApp"
                />
                <div v-else>
                    <md-empty-state
                        md-label="No main apps found"
                        md-description="Go to discovery section and enable some main apps"
                    >
                    </md-empty-state>
                </div>
            </md-card-content>
        </md-card>

        <md-card>
            <md-card-header>
                <div class="md-subheading">Channels ({{ installedChannelsCounter }})</div>
            </md-card-header>
            <md-card-content>
                <md-progress-spinner v-if="isLoading" class="md-primary" md-mode="indeterminate"></md-progress-spinner>
                <MySkillsItemCard
                    v-else-if="channelsList.length > 0"
                    v-for="channel in channelsList"
                    :skill="channel"
                />
                <div v-else>
                    <md-empty-state
                        md-label="No channels found"
                        md-description="Go to discovery section and enable some channels"
                    >
                    </md-empty-state>
                </div>
            </md-card-content>
        </md-card>

        <md-card>
            <md-card-header>
                <div class="md-subheading">Skills ({{ installedSkillsCounter }})</div>
            </md-card-header>
            <md-card-content>
                <md-progress-spinner v-if="isLoading" class="md-primary" md-mode="indeterminate"></md-progress-spinner>
                <MySkillsItemCard v-else-if="skillsList.length > 0" v-for="skill in skillsList" :skill="skill" />
                <div v-else>
                    <md-empty-state
                        md-label="No skills found"
                        md-description="Go to discovery section and enable some skills"
                    >
                    </md-empty-state>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<style scoped lang="scss"></style>
