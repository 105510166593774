<template>
    <div class="hot-topic-card">
        <div class="hot-topic-tags">
            <md-chip class="hot-topic-main-tag">
                <i class="fas fa-fire"></i>
                <span> Hot Topic </span>
            </md-chip>
            <md-chip class="hot-topic-category-tag">
                <span> Feedback </span>
            </md-chip>
        </div>
        <div class="hot-topic-body">
            <p class="md-title">Addressing Unanswered Queries for Better Sales</p>
            <p class="md-body">
                Last week we experienced considerable activity. We recommend checking the topics Lixsa could not address
                due to missing information. By identifying these gaps, we can enhance customer satisfaction and boost
                sales, as there are inquiries about products that are not currently available. It's crucial to update
                our system to ensure it reflects our inventory accurately and provides timely responses to maintain
                customer engagement and trust.
            </p>
        </div>
    </div>
</template>
<script>
export default {};
</script>
