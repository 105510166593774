<template>
    <div>
        <AuthLayout>
            <ForgotPassword />
        </AuthLayout>
    </div>
</template>
<script>
import AuthLayout from '../../layouts/LoginOrSignupLayout.vue';
import ForgotPassword from '../../components/Auth/ForgotPassword.vue';

export default {
    name: 'forgotPassword',
    components: { AuthLayout, ForgotPassword },
    data() {
        return {
            firstname: null,
            email: null,
            password: null,
        };
    },
};
</script>
