<script>
import Dialog360PopupButton from '@/components/Skills/Channels/Dialog360PopupButton.vue';
import ChannelsConnectModal from '@/components/CommunicationChannels/channelsConnectModal.vue';
import ChannelsFacebookLoginButton from '@/components/Skills/Channels/ChannelsFacebookLoginButton.vue';
import MainAppsShopifyConnectButton from '@/components/Skills/MainApps/MainAppsShopifyConnectButton.vue';

export default {
    name: 'SkillDetailsInfoCard',
    components: {
        ChannelsFacebookLoginButton,
        MainAppsShopifyConnectButton,
        ChannelsConnectModal,
        Dialog360PopupButton,
    },
    props: {
        skill: {
            type: Object,
            required: true,
        },
        isEnable: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['skill-enabled', 'skill-disabled'],
    computed: {
        isWhatsapp360DialogSkill() {
            return this.skill.uuid === '8c0f230d-cca0-4335-a359-d3fd4ab284cc';
        },

        isWhatsappVanillaSkill() {
            return this.skill.uuid === 'ac2d634e-4333-4b66-8bda-daed62cb4028';
        },
        isShopifySkill() {
            return this.skill.uuid === 'd74f8439-bda9-420a-8b20-cbb80e5f7510';
        },
    },
    methods: {
        enableSkill() {
            this.$emit('skill-enabled');
        },

        disableSkill() {
            this.$emit('skill-disabled');
        },
    },
};
</script>

<template>
    <md-card>
        <md-card-content>
            <div class="skills-details-info-card">
                <div class="skills-details-info-card__skill-info">
                    <div class="skills-details-info-card__skill-logo">
                        <img v-if="skill.image && skill.image !== ''" :src="skill.image" alt="Skill Image" />
                        <img
                            v-else
                            src="https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Ffoto%20perfil%20usuario.png?alt=media&token=5470816a-d159-4958-aae2-5837c747339c"
                            alt="Lixsa default image"
                        />
                    </div>
                    <div class="skills-details-info-card__skill-description">
                        <div class="md-display-1">
                            <strong> {{ skill.name }} </strong>
                        </div>
                        <div class="md-body-1">{{ skill.description }}</div>
                        <div class="skills-details-info-card__skill-categories">
                            <!--              <md-chip class="skills-details-info-card__Active"></md-chip>-->
                            <md-chip v-for="category in skill.categories">{{ category }}</md-chip>
                        </div>
                    </div>
                </div>
                <div>
                    <!-- <Dialog360PopupButton v-if="isWhatsapp360DialogSkill && !isEnable"></Dialog360PopupButton> -->
                    <channels-facebook-login-button
                        @account-connected="enableSkill"
                        v-if="isWhatsapp360DialogSkill && !isEnable"
                    ></channels-facebook-login-button>

                    <channels-connect-modal
                        v-else-if="isWhatsappVanillaSkill && !isEnable"
                        @account-connected="enableSkill"
                    ></channels-connect-modal>

                    <main-apps-shopify-connect-button
                        @account-connected="enableSkill"
                        v-else-if="isShopifySkill && !isEnable"
                    ></main-apps-shopify-connect-button>

                    <md-button @click="enableSkill" v-else-if="!isEnable" class="md-primary">Enable Skill</md-button>

                    <md-button @click="disableSkill" class="md-gray md-outline" v-else>Disable Skill</md-button>
                </div>
            </div>
        </md-card-content>
    </md-card>
</template>

<style scoped lang="scss">
.skills-details-info-card {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 17px;

    @media (max-width: 959px) {
        flex-direction: column;
        gap: 1.5rem;
    }

    &__skill-info {
        display: flex;
        align-items: start;
        justify-content: start;
        gap: 1.5rem;

        @media (max-width: 959px) {
            flex-direction: column;
        }
    }

    &__skill-logo {
        flex: 0 0 80px;
        border-radius: 12px;
        overflow: hidden;

        img {
            width: 80px;
            height: auto;
        }
    }

    &__skill-description {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__skill-categories {
        display: flex;
        align-items: start;
        justify-content: start;

        @media (max-width: 959px) {
            display: none;
        }
    }
}
</style>
