export default {
    id: '',
    object: '',
    application: null,
    application_fee_percent: null,
    automatic_tax: {
        enabled: false,
        liability: null,
    },
    billing_cycle_anchor: 0,
    billing_thresholds: null,
    cancel_at: null,
    cancel_at_period_end: false,
    canceled_at: null,
    cancellation_details: {
        comment: null,
        feedback: null,
        reason: null,
    },
    collection_method: '',
    created: 0,
    currency: '',
    current_period_end: 0,
    current_period_start: 0,
    customer: '',
    days_until_due: null,
    default_payment_method: null,
    default_source: null,
    default_tax_rates: [],
    description: null,
    discount: null,
    discounts: null,
    ended_at: null,
    invoice_settings: {
        issuer: {
            type: '',
        },
    },
    items: {
        object: '',
        data: [
            {
                id: '',
                object: '',
                billing_thresholds: null,
                created: 0,
                metadata: {},
                plan: {
                    id: '',
                    object: '',
                    active: false,
                    aggregate_usage: null,
                    amount: 0,
                    amount_decimal: '',
                    billing_scheme: '',
                    created: 0,
                    currency: '',
                    discounts: null,
                    interval: '',
                    interval_count: 0,
                    livemode: false,
                    metadata: {},
                    nickname: null,
                    product: '',
                    tiers_mode: null,
                    transform_usage: null,
                    trial_period_days: null,
                    usage_type: '',
                },
                price: {
                    id: '',
                    object: '',
                    active: false,
                    billing_scheme: '',
                    created: 0,
                    currency: '',
                    custom_unit_amount: null,
                    livemode: false,
                    lookup_key: null,
                    metadata: {},
                    nickname: null,
                    product: '',
                    recurring: {
                        aggregate_usage: null,
                        interval: '',
                        interval_count: 0,
                        trial_period_days: null,
                        usage_type: '',
                    },
                    tax_behavior: '',
                    tiers_mode: null,
                    transform_quantity: null,
                    type: '',
                    unit_amount: 0,
                    unit_amount_decimal: '',
                },
                quantity: 0,
                subscription: '',
                tax_rates: [],
            },
        ],
        has_more: false,
        total_count: 0,
        url: '',
    },
    latest_invoice: '',
    livemode: false,
    metadata: {},
    next_pending_invoice_item_invoice: null,
    on_behalf_of: null,
    pause_collection: null,
    payment_settings: {
        payment_method_options: null,
        payment_method_types: null,
        save_default_payment_method: '',
    },
    pending_invoice_item_interval: null,
    pending_setup_intent: null,
    pending_update: null,
    schedule: null,
    start_date: 0,
    status: '',
    test_clock: null,
    transfer_data: null,
    trial_end: null,
    trial_settings: {
        end_behavior: {
            missing_payment_method: '',
        },
    },
    trial_start: null,
};
