// CatalogServiceV2.js

import axios from 'axios';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

const baseURL = `${process.env.VUE_APP_API_URL}`;
// const getAuthHeader = (token) => ({ Authorization: `Bearer ${token}` });
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}, cancelToken = null) => {
    try {
        return await store.dispatch('user/apiCall', async (token) => {
            const config = {
                method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params,
                data,
                ...(cancelToken ? { cancelToken: cancelToken } : {}),
            };
            return await axios(config);
        });
    } catch (error) {
        if (!axios.isCancel(error)) {
            console.error('API request failed', error);
        }
        throw error;
    }
};

let cancelToken;

const CatalogServiceV2 = {
    fetchCatalog(page = 0, size = 20) {
        const params = { page, size };
        return apiRequest('get', `catalogs/${store.state.user.user.account_id}/items`, null, params);
    },

    fetchCatalogSchema() {
        return apiRequest('get', `catalogs/${store.state.user.user.account_id}/schema`);
    },

    getCatalog() {
        return apiRequest('get', `catalogs/${store.state.user.user.account_id}`);
    },

    updateCatalogSchema(updatedSchema) {
        return apiRequest('post', `catalogs/${store.state.user.user.account_id}`, updatedSchema);
    },

    createItem(item) {
        item.item_id = uuidv4();

        return apiRequest('post', `catalogs/${store.state.user.user.account_id}/items`, item);
    },

    deleteItem(itemId) {
        return apiRequest('delete', `catalogs/${store.state.user.user.account_id}/items/${itemId}`);
    },

    bulkDeleteItems() {
        return apiRequest('delete', `catalogs/${store.state.user.user.account_id}/items`);
    },

    updateItem(itemId, updateData) {
        return apiRequest('post', `catalogs/${store.state.user.user.account_id}/items/${itemId}`, updateData);
    },

    searchItems(page = 0, size = 20, searchTerm) {
        if (cancelToken) {
            cancelToken.cancel('Cancelled the previous request');
        }

        // Create a new CancelToken
        cancelToken = axios.CancelToken.source();

        const params = {
            search: searchTerm,
            page,
            size,
        };

        return apiRequest(
            'get',
            `catalogs/${store.state.user.user.account_id}/search`,
            null,
            params,
            cancelToken.token,
        ).catch((thrown) => {
            if (!axios.isCancel(thrown)) {
                throw thrown;
            }
        });
    },

    startTraining() {
        return apiRequest('post', `catalogs/${store.state.user.user.account_id}/start-training`);
    },

    getStorageURL(contentType) {
        // const accountId = store.state.user.user.account_id;
        const uuid = uuidv4();
        const fileName = `${uuid}`;

        return apiRequest('get', 'catalogs/media/upload-url', null, { contentType, fileName });

        // const storageURL = response.data;
        // return { storageURL, uuid };
    },

    getLastTrainedDate() {
        return apiRequest('get', `catalogs/${store.state.user.user.account_id}/items/last-trained`);
    },

    getItemsCount() {
        return apiRequest('get', `catalogs/${store.state.user.user.account_id}/items/count`);
    },
};

export default CatalogServiceV2;
