<template>
    <md-toolbar
        md-elevation="0"
        class="md-transparent"
        :class="{
            'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
        }"
    >
        <div class="md-toolbar-row">
            <div class="md-toolbar-section-start">
                <div class="md-title">
                    <strong> {{ $route.name }} </strong>
                </div>
            </div>
            <div class="md-toolbar-section-end">
                <!--                <div-->
                <!--                    v-if="noSub"-->
                <!--                    @click="goToPage('/settings/subscription')"-->
                <!--                    class="custom-guest-warning-mobile"-->
                <!--                    :class="{ toggled: $sidebar.showSidebar }"-->
                <!--                >-->
                <!--                    <md-icon>visibility</md-icon>-->
                <!--                    <p class="md-caption"><strong>Guest Mode</strong></p>-->
                <!--                    <md-tooltip md-direction="bottom">-->
                <!--                        <p class="md-body-1">Choose a subscription plan</p>-->
                <!--                        <p class="md-caption">-->
                <!--                            As a guest, you may browse our tools, but full agent functionality requires a subscription.-->
                <!--                            <strong>Click the button and explore the full potential of Lixsa.</strong>-->
                <!--                        </p>-->
                <!--                    </md-tooltip>-->
                <!--                </div>-->

                <!--                <div-->
                <!--                    @click="toggleCopilot"-->
                <!--                    v-if="isConversationsRoute"-->
                <!--                    class="custom-copilot-icon-mobile"-->
                <!--                    :class="{ activated: isCopilotEnabled }"-->
                <!--                >-->
                <!--                    <md-icon>assistant</md-icon>-->
                <!--                    &lt;!&ndash; <p class="md-body-1">Copilot</p> &ndash;&gt;-->
                <!--                </div>-->

                <md-button
                    class="md-just-icon md-round md-simple md-toolbar-toggle"
                    :class="{ toggled: $sidebar.showSidebar }"
                    @click="toggleSidebar"
                >
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </md-button>

                <!--                <div class="md-collapse">-->
                <!--                    <div v-if="noSub" @click="goToPage('/settings/subscription')" class="custom-guest-warning">-->
                <!--                        <md-icon>visibility</md-icon>-->
                <!--                        <p class="md-caption"><strong>Guest Mode</strong></p>-->
                <!--                        <md-tooltip md-direction="left">-->
                <!--                            <p class="md-body-1">Choose a subscription plan</p>-->
                <!--                            <p class="md-caption">-->
                <!--                                As a guest, you may browse our tools, but full agent functionality requires a-->
                <!--                                subscription. <strong>Click the button and explore the full potential of Lixsa.</strong>-->
                <!--                            </p>-->
                <!--                        </md-tooltip>-->
                <!--                    </div>-->

                <!--                    <div-->
                <!--                        @click="toggleCopilot"-->
                <!--                        v-if="isConversationsRoute"-->
                <!--                        class="custom-copilot-icon"-->
                <!--                        :class="{ activated: isCopilotEnabled }"-->
                <!--                    >-->
                <!--                        <md-icon>assistant</md-icon>-->
                <!--                        <md-tooltip v-if="isCopilotEnabled" md-direction="left"-->
                <!--                            >Copilot enabled. Improved spelling and grammar</md-tooltip-->
                <!--                        >-->
                <!--                        <md-tooltip v-else md-direction="left">Copilot disabled</md-tooltip>-->
                <!--                        &lt;!&ndash; <p class="md-body-1">Copilot</p> &ndash;&gt;-->
                <!--                    </div>-->

                <!--                    <div v-if="isConversationsRoute" class="vertical-divider">-->
                <!--                        <div class="vertical-line"></div>-->
                <!--                    </div>-->

                <!--                    <md-menu :mdCloseOnClick="true" md-size="huge" :md-offset-x="-230" :md-offset-y="5">-->
                <!--                        <md-list md-menu-trigger>-->
                <!--                            <md-avatar class="md-avatar-icon custom-md-avatar">-->
                <!--                                <md-ripple v-if="$store.state.user.userProfilePicture"-->
                <!--                                    ><img-->
                <!--                                        :src="$store.state.user.userProfilePicture"-->
                <!--                                        alt="Profile Picture"-->
                <!--                                        referrerpolicy="no-referrer"-->
                <!--                                /></md-ripple>-->
                <!--                                <md-ripple v-else>{{ firstLetterOfUsername }}</md-ripple>-->
                <!--                            </md-avatar>-->
                <!--                        </md-list>-->
                <!--                        <md-menu-content>-->
                <!--                            <div class="author-card">-->
                <!--                                <div class="author-card-info">-->
                <!--                                    <div class="user-info-section">-->
                <!--                                        <md-avatar class="md-avatar-icon custom-avatar">-->
                <!--                                            <img-->
                <!--                                                v-if="$store.state.user.userProfilePicture"-->
                <!--                                                :src="$store.state.user.userProfilePicture"-->
                <!--                                                alt="Profile Picture"-->
                <!--                                                referrerpolicy="no-referrer"-->
                <!--                                            />-->
                <!--                                            <span v-else>{{ firstLetterOfUsername }}</span>-->
                <!--                                        </md-avatar>-->
                <!--                                        <div>-->
                <!--                                            <div class="md-body-2">{{ this.$store.state.user.user.username }}</div>-->
                <!--                                            <div class="md-caption">{{ this.$store.state.user.user.email }}</div>-->
                <!--                                        </div>-->
                <!--                                    </div>-->
                <!--                                    <md-divider></md-divider>-->
                <!--                                    <ul class="menu-items">-->
                <!--                                        <li-->
                <!--                                            @click="toggleCopilot"-->
                <!--                                            class="md-body-1"-->
                <!--                                            :class="{ activated: isCopilotEnabled }"-->
                <!--                                        >-->
                <!--                                            <md-icon class="icon">assistant</md-icon>-->
                <!--                                            <span-->
                <!--                                                >Copilot-->
                <!--                                                <span v-if="isCopilotEnabled">Enabled</span>-->
                <!--                                                <span v-else>Disabled</span>-->
                <!--                                            </span>-->
                <!--                                            <md-icon class="icon">push_pin</md-icon>-->
                <!--                                        </li>-->
                <!--                                    </ul>-->
                <!--                                    <md-divider></md-divider>-->
                <!--                                    <ul class="menu-items">-->
                <!--                                        <li @click="goToPage('/settings/account')" class="md-body-1">-->
                <!--                                            <md-icon class="icon">settings</md-icon>Settings-->
                <!--                                        </li>-->
                <!--                                        <li @click="openHelpCenter" class="md-body-1">-->
                <!--                                            <md-icon class="icon">help_outline</md-icon>-->
                <!--                                            Help Center-->
                <!--                                        </li>-->
                <!--                                    </ul>-->
                <!--                                    <md-divider></md-divider>-->
                <!--                                    <ul class="menu-items">-->
                <!--                                        <li @click="logout" class="md-body-1">-->
                <!--                                            <md-icon class="icon">logout</md-icon>Log out-->
                <!--                                        </li>-->
                <!--                                    </ul>-->
                <!--                                </div>-->
                <!--                            </div>-->
                <!--                        </md-menu-content>-->
                <!--                    </md-menu>-->
                <!--                </div>-->
            </div>
        </div>
    </md-toolbar>
</template>

<script>
export default {
    data() {
        return {};
    },
    computed: {
        firstLetterOfUsername() {
            if (this.$store.state.user.user.username && this.$store.state.user.user.username.length > 0) {
                return this.$store.state.user.user.username.charAt(0).toUpperCase();
            }
            return 'A';
        },
        isCopilotEnabled() {
            return this.$store.getters['user/isCopilotEnabled'];
        },
        isConversationsRoute() {
            return this.$route.path === '/conversations' || this.$route.path === '/training/chat';
        },
    },
    methods: {
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        logout() {
            this.$store
                .dispatch('user/logout')
                .then(() => {
                    this.$router.push({ name: 'Login', query: { logout: 'true' } });
                })
                .catch((error) => {
                    this.error = error.message;
                });
        },
        goToPage(route) {
            if (this.$route.path !== route) {
                this.$router.push(route);
            }
        },
        openHelpCenter() {
            window.open('https://lixsa.ai', '_blank').focus;
        },
        toggleCopilot() {
            if (this.isCopilotEnabled) {
                this.$store.dispatch('user/disableCopilot');
            } else {
                this.$store.dispatch('user/enableCopilot');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.md-toolbar-row {
    margin-bottom: 0;
    //padding: 0 17px 0 30px !important;
    padding-left: 30px !important;

    @media (max-width: 599px) {
        padding: 0;
    }
}

.author-card {
    padding: 8px 16px;
    display: flex;
    align-items: center;

    .author-card-info {
        display: flex;
        flex-flow: column;
        flex: 1;
    }
}

.md-divider {
    margin-top: 5px;
    margin-bottom: 5px;
}

.menu-items {
    list-style: none;
    padding: 0;
}

.menu-items li {
    padding: 10px 0;
    display: flex;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
}

.menu-items li:hover {
    background-color: #f5f5f5;
}

.icon {
    margin: 0px 5px 0px 5px;
    font-size: 16px !important;
}

.custom-guest-warning {
    background-color: rgb(255, 174, 80, 0.8);
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    padding-right: 12px;
    padding-left: 3.5px;
    cursor: pointer;

    .md-icon {
        font-size: 16px !important;
    }
}

.custom-guest-warning-mobile {
    @media (min-width: 959px) {
        display: none;
    }

    background-color: rgb(255, 174, 80, 0.8);
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    padding-right: 12px;
    padding-left: 3.5px;
    cursor: pointer;

    .md-icon {
        font-size: 16px !important;
    }
}

// .md-tooltip {
//   border-radius: 12px;
// }

.custom-copilot-icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
    padding: 10px;
    border-radius: 12px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #f0f0f0;
    }

    i {
        margin: 0;
        color: #828383 !important;
    }

    p {
        margin: 0;
        color: #828383;
    }
}

.custom-copilot-icon-mobile {
    @media (min-width: 599px) {
        display: none;
    }

    display: flex;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
    padding: 10px;
    border-radius: 12px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #f0f0f0;
    }

    i {
        margin: 0 0 0 0;
        color: #828383 !important;
    }

    p {
        margin: 0;
        color: #828383;
    }
}

.activated {
    color: #2196f3;
    i {
        color: #2196f3 !important;
    }

    p {
        color: #2196f3;
    }
}

.vertical-divider {
    display: flex;
    align-items: center;
    margin-right: 10px;
    .vertical-line {
        width: 1px;
        height: 30px;
        background-color: #cccccc;
    }
}

// .custom-md-avatar {
//   width: 35px;
//   min-width: 0;
//   height: 35px;
// }
</style>
