import Vue from 'vue';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

let socket = null;
let stompClient = null;
let heartbeatInterval = null;

const state = () => ({
    subscriptions: {},
    reconnectInterval: 5000, // Reconnection interval in milliseconds
    connected: false,
    reconnectAttempts: 0, // Track reconnection attempts
    connectPromise: null,
});

const getters = {
    isConnected: (state) => state.connected,
};

const mutations = {
    SET_CONNECTED(state, connected) {
        state.connected = connected;
        if (connected) {
            state.reconnectAttempts = 0; // Reset reconnection attempts on successful connection
        }
    },
    ADD_SUBSCRIPTION(state, { topic, callback, subscription }) {
        if (!state.subscriptions[topic]) {
            Vue.set(state.subscriptions, topic, { callbacks: [], subscription: null });
        }
        state.subscriptions[topic].callbacks.push(callback);
        if (subscription) {
            state.subscriptions[topic].subscription = subscription;
        }
    },
    REMOVE_SUBSCRIPTION(state, { topic, callback }) {
        if (state.subscriptions[topic]) {
            state.subscriptions[topic].callbacks = state.subscriptions[topic].callbacks.filter((cb) => cb !== callback);
            if (state.subscriptions[topic].callbacks.length === 0) {
                Vue.delete(state.subscriptions, topic);
            }
        }
    },
    SET_SUBSCRIPTION(state, { topic, subscription }) {
        if (state.subscriptions[topic]) {
            state.subscriptions[topic].subscription = subscription;
        }
    },
    CLEAR_SUBSCRIPTION(state, topic) {
        if (state.subscriptions[topic]) {
            state.subscriptions[topic].subscription = null;
        }
    },
    CLEAR_ALL_SUBSCRIPTIONS(state) {
        state.subscriptions = {};
    },
    SOCKET_MESSAGE(state, { topic, data }) {
        if (state.subscriptions[topic]) {
            state.subscriptions[topic].callbacks.forEach((callback) => callback(data));
        }
    },
    INCREASE_RECONNECT_ATTEMPTS(state, increaseBy) {
        state.reconnectAttempts += increaseBy;
    },
    SET_CONNECT_PROMISE(state, promise) {
        state.connectPromise = promise;
    },
};

const actions = {
    async connectSocket({ commit, dispatch, state }) {
        if (state.connectPromise) {
            return state.connectPromise;
        }

        const connectPromise = dispatch(
            'user/apiCall',
            async (token) => {
                return new Promise((resolve, reject) => {
                    socket = new SockJS(`${process.env.VUE_APP_API_URL}/ws`, {
                        transportOptions: {
                            'xhr-streaming': {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            },
                        },
                    });

                    stompClient = new Client({
                        webSocketFactory: () => socket,
                        reconnectDelay: 1000, // Automatic reconnection with a fixed delay
                    });

                    stompClient.onConnect = () => {
                        commit('SET_CONNECTED', true);

                        // Resubscribe to topics after reconnecting
                        Object.keys(state.subscriptions).forEach((topic) => {
                            dispatch('resubscribe', topic);
                        });

                        // dispatch('startHeartbeat');

                        resolve();
                    };

                    stompClient.onWebSocketClose = () => {
                        dispatch('handleDisconnect');
                        reject(new Error('WebSocket disconnected'));
                    };

                    stompClient.onStompError = (error) => {
                        console.error('STOMP error:', error);
                        dispatch('handleDisconnect');
                        reject(error);
                    };

                    stompClient.activate();
                });
            },
            { root: true },
        );

        commit('SET_CONNECT_PROMISE', connectPromise);

        try {
            await connectPromise;
        } finally {
            commit('SET_CONNECT_PROMISE', null);
        }

        return connectPromise;
    },

    handleDisconnect({ commit, dispatch }) {
        commit('SET_CONNECTED', false);
        // dispatch('stopHeartbeat');
        dispatch('scheduleReconnect');
    },

    startHeartbeat({ dispatch }) {
        dispatch('stopHeartbeat');
        heartbeatInterval = setInterval(() => {
            if (stompClient && stompClient.connected) {
                stompClient.publish({
                    destination: '/app/heartbeat',
                    body: JSON.stringify({ type: 'heartbeat' }),
                });
            }
        }, 30000); // Send heartbeat every 30 seconds
    },

    stopHeartbeat() {
        if (heartbeatInterval) {
            clearInterval(heartbeatInterval);
            heartbeatInterval = null;
        }
    },

    async subscribe({ state, commit, dispatch }, { topic, callback }) {
        commit('ADD_SUBSCRIPTION', { topic, callback });
        if (state.connected && stompClient) {
            await dispatch('resubscribe', topic);
        } else {
            await dispatch('connectSocket');
            await dispatch('resubscribe', topic);
        }
    },

    async resubscribe({ commit }, topic) {
        try {
            const subscription = stompClient.subscribe(topic, (frame) => {
                try {
                    const data = JSON.parse(frame.body);
                    commit('SOCKET_MESSAGE', { topic, data });
                } catch (error) {
                    console.error('Error parsing message:', error);
                }
            });
            commit('SET_SUBSCRIPTION', { topic, subscription });
        } catch (error) {
            console.error('Error resubscribing to topic:', topic, error);
        }
    },

    unsubscribe({ state, commit }, { topic, callback }) {
        commit('REMOVE_SUBSCRIPTION', { topic, callback });
        if (state.connected && stompClient && state.subscriptions[topic]) {
            const subscription = state.subscriptions[topic].subscription;
            if (subscription) {
                subscription.unsubscribe();
                commit('CLEAR_SUBSCRIPTION', topic);
            }
        }
    },

    unsubscribeFromAll({ state, commit }) {
        if (state.connected && stompClient) {
            Object.keys(state.subscriptions).forEach((topic) => {
                const subscription = state.subscriptions[topic].subscription;
                if (subscription) {
                    subscription.unsubscribe();
                }
            });
        }
        commit('CLEAR_ALL_SUBSCRIPTIONS');
    },

    scheduleReconnect({ state, commit, dispatch }) {
        commit('INCREASE_RECONNECT_ATTEMPTS', 1);
        const delay = Math.min(state.reconnectInterval * 2 ** state.reconnectAttempts, 60000); // Exponential backoff, max 1 minute
        setTimeout(() => {
            dispatch('connectSocket');
        }, delay);
    },

    disconnectSocket({ commit, dispatch }) {
        dispatch('unsubscribeFromAll');
        // dispatch('stopHeartbeat');
        if (stompClient) {
            stompClient.deactivate();
        }
        socket = null;
        stompClient = null;
        commit('SET_CONNECTED', false);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
