import axios from 'axios';
import store from '@/store';

const baseURL = process.env.VUE_APP_API_URL;
// const getAuthHeader = (token) => ({ Authorization: `Bearer ${token}` });
const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;

    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/accounts/${store.state.user.user.account_id}/${url}`,
                headers: getAuthHeader(token),
                params: { ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const agentSettingsService = {
    getAgentSettings() {
        return apiRequest('get', `agent-settings/${store.state.user.user.account_id}`);
    },

    editAgentSettings(updateData) {
        return apiRequest('post', `agent-settings/${store.state.user.user.account_id}`, updateData);
    },

    addSkillToAgentSettings(skillData) {
        return apiRequest('post', `agent-settings/${store.state.user.user.account_id}/skills`, skillData);
    },

    removeSkillFromAgentSettings(skillId, skillData) {
        return apiRequest('delete', `agent-settings/${store.state.user.user.account_id}/skills/${skillId}`, skillData);
    },

    modifyAgentSettings(config_values, skillId) {
        const updateData = {
            skill_id: skillId,
            agent_id: store.state.user.user.account_id,
            enabled: true,
            account_id: store.state.user.user.account_id,
            config_values: config_values,
        };

        return apiRequest('post', `agent-settings/${store.state.user.user.account_id}/skills/${skillId}`, updateData);
    },

    enableAgentSettings() {
        const params = {
            account_id: store.state.user.user.account_id,
            agent_settings_id: store.state.user.user.account_id,
            enabled: true,
        };
        return apiRequest('post', `agent-settings/${store.state.user.user.account_id}/enable`, params);
    },

    disableAgentSettings() {
        const params = {
            account_id: store.state.user.user.account_id,
            agent_settings_id: store.state.user.user.account_id,
            enabled: false,
        };
        return apiRequest('post', `agent-settings/${store.state.user.user.account_id}/disable`, params);
    },
};

export default agentSettingsService;
