<script>
import { mapActions } from 'vuex';

export default {
    name: 'TheModalWindow',
    data() {
        return {};
    },
    computed: {
        modalActive: {
            get: function () {
                return this.$store.state.modalStore.modalState.component !== null;
            },
            set: function (newValue) {
                if (!newValue) {
                    this.$store.dispatch('modalStore/closeModal');
                }
            },
        },
    },
    methods: {
        ...mapActions('modalStore', ['closeModal']),
        handleEvent({ eventName, eventPayload }) {
            const onEvent = this.$store.state.modalStore.modalState.onEvent;
            if (typeof onEvent === 'function') {
                onEvent(eventName, eventPayload);
            }
        },
    },
};
</script>

<template>
    <md-dialog :md-active.sync="modalActive">
        <component
            :is="$store.state.modalStore.modalState.component"
            v-bind="$store.state.modalStore.modalState.props"
            @modalEvent="handleEvent"
        ></component>
    </md-dialog>
</template>

<style scoped lang="scss"></style>
