<template>
    <div class="training__main-panel">
        <div>
            <md-progress-bar v-if="isLoading" md-mode="indeterminate"></md-progress-bar>
        </div>
        <div class="md-layout training__content-panel">
            <div v-if="isLoading" class="overlay md-layout-item md-size-100"></div>
            <div v-else class="md-layout-item md-size-100">
                <tabs
                    :tab-name="['Q&A', 'Business Knowledge', 'Agent', 'Training Model', 'Chat']"
                    color-button="simple"
                >
                    <template v-slot:tab-pane-1>
                        <QnATab />
                    </template>
                    <template v-slot:tab-pane-2>
                        <BusinessKnowledge />
                    </template>
                    <template v-slot:tab-pane-3>
                        <Agent />
                    </template>
                    <template v-slot:tab-pane-4>
                        <FineTuning />
                    </template>
                    <template v-slot:tab-pane-5>
                        <ChatTest />
                    </template>
                </tabs>
            </div>
        </div>
    </div>
</template>
<script>
import { Tabs } from '@/components';

import BusinessKnowledge from '@/pages/Dashboard/Training/BusinessKnowledge.vue';
import Catalog from '@/pages/Dashboard/Training/CatalogPage.vue';
import Agent from '@/pages/Dashboard/Training/Agent.vue';
import ChatTest from '@/pages/Dashboard/Training/ChatTest.vue';
import FineTuning from '@/pages/Dashboard/Training/FineTuning.vue';
import QnATab from '@/pages/Dashboard/Training/QnATab.vue';

export default {
    data() {
        return {
            isLoading: false,
        };
    },
    components: {
        QnATab,
        Tabs,
        BusinessKnowledge,
        Catalog,
        Agent,
        ChatTest,
        FineTuning,
    },
};
</script>
<style lang="scss" scoped>
.training__main-panel {
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.training__content-panel {
    padding-left: 15px;
    padding-right: 15px;

    @media (max-width: 1278px) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (max-width: 959px) {
        padding-left: 0px;
        padding-right: 0px;
    }
}
</style>
